import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { D_House } from 'hosman-material';
import {
  Route,
  Navigate,
  useNavigate,
  useLocation,
  useParams
} from 'react-router-dom';
import CustomRoutes from '../../../../../../../shared/utils/customRoutes';

import PageTitle from '../../../../../../components/PageTitle/PageTitle';
import Property from './views/Property';
import Offers from './views/Offers';
import NextSteps from './views/NextSteps';
import Documents from './views/Documents';
import Contacts from './views/Contacts';
import { LoadingLayer, FadeIn, Tabs, Tab, ContactCardMinimize, PropertySelect } from 'hosman-material';

import { getOffer, getShowOffers, resetOffer } from '../../../../../../reducers/BuyerApp/offersSlice';
import { getContacts } from '../../../../../../reducers/BuyerApp/contactsSlice';
import { getDocuments } from '../../../../../../reducers/BuyerApp/documentsSlice';

const OfferShow = () => {
  const { offer, showOffers, indexOffers, requestState } = useSelector((state) => state.buyerOffers);
  const { documents } = useSelector((state) => state.buyerDocuments);
  const [items, setItems] = useState([])
  const [selectedItem, setSelectedItem] = useState({})
  const [propertySelectOptions, setPropertySelectOptions] = useState([]);
  const dispatch  = useDispatch();
  let location    = useLocation();
  let navigate    = useNavigate();
  let { offerId } = useParams();

  useEffect(() => {
    // Catch browser back button to redirect to OffersIndex
    const handleBackButton = () => {
      navigate('/ea');
    };

    window.addEventListener('popstate', handleBackButton);
  }, []);

  useEffect(() => {
    dispatch(getOffer(offerId));

    return function cleanup() {
      dispatch(resetOffer());
    };
  }, [offerId]);

  useEffect(() => {
    if (indexOffers.length < 0 || !offer.id) return;

    setPropertySelectOptions(indexOffers.map((indexOffer) => {
      return {
        id: indexOffer.id,
        saleId: indexOffer.sale.id,
        address: `${indexOffer.sale.property.street_number} ${indexOffer.sale.property.street_name} ${indexOffer.sale.property.zip_code} ${indexOffer.sale.property.city}`,
      };
    }).filter(function (obj) {
      return obj.saleId !== offer.sale.id;
    }));
  }, [indexOffers, offer]);

  useEffect(() => {
    if (!offer.id) return;

    dispatch(getShowOffers({ 'q[sale_id_eq]': offer.sale.id }));
    if (offer.review_status === 'accepted') {
      dispatch(getContacts({ offer_id: offer.id }));
      dispatch(getDocuments(offer.sale.id));
    }
  }, [offer]);

  useEffect(() => {
    if (showOffers.length > 0) {
      let baseItems = [
        { name: 'Le bien', path: 'le-bien' },
        { name: showOffers.length > 1 ? 'Mes offres' : 'Mon offre', path: 'mes-offres', count: showOffers.length || '0' }
      ]

      // Adds nextSteps, document && contact tabs when offer is accepted
      if (offer.review_status === 'accepted') {
        baseItems.splice(2, 0, { name: 'Prochaines étapes', path: 'prochaines-etapes' })
        baseItems.splice(3, 0, { name: 'Documents', path: 'documents', count: documents.length || null }),
        baseItems.splice(4, 0, { name: 'Contacts', path: 'contacts' })
      }

      setItems(baseItems)
    }
  }, [showOffers, documents]);

  useEffect(() => {
    if (items.length > 0) {
      const matchedItem = items.find((item) => location.pathname.includes(item.path));
      setSelectedItem(matchedItem || items[0])
    }
  }, [items]);

  const handleSelect = (item) => {
    setSelectedItem(item);
    navigate(item.path);
  }

  return (
    <div data-testid='OfferShow'>
      <PageTitle icon={D_House} title='Accueil'>
        {offer.id &&
          <>
            {offer.sale.expert &&
              <ContactCardMinimize
                firstName={offer.sale.expert.user.first_name}
                imageSrc={offer.sale.expert?.user?.profile_pic_url}
                mail={offer.sale.expert.user.email}
                phoneNumber={offer.sale.expert.user.phone_number}
              />
            }
            <PropertySelect
              selectedOption={{
                address: `${offer.sale.property.street_number} ${offer.sale.property.street_name} ${offer.sale.property.zip_code} ${offer.sale.property.city}`,
              }}
              options={propertySelectOptions}
              handleChange={(offer) => navigate(`/ea/offres/${offer.id}`)}
            />
          </>
        }
      </PageTitle>

      <LoadingLayer isReady={requestState == 'done'}>
        <FadeIn>
          <div className='mb-5'>
            <Tabs>
              {items.map((item) => {
                return (
                  <Tab
                    key={item.name}
                    item={item}
                    selectedItem={selectedItem}
                    handleSelect={handleSelect}
                  />
                );
              })}
            </Tabs>
          </div>

          <CustomRoutes>
            <Route index element={<Navigate to='le-bien' />} />
            <Route path='/le-bien' element={<Property />} />
            <Route path='/mes-offres' element={<Offers />} />
            <Route path='/prochaines-etapes' element={<NextSteps />} />
            <Route path='/documents' element={<Documents />} />
            <Route path='/contacts' element={<Contacts />} />
          </CustomRoutes>
        </FadeIn>
      </LoadingLayer>
    </div>
  );
}

export default OfferShow;
