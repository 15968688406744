import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { S_Check } from 'hosman-material';
import { handShake, partyingFace } from '../../../../../../shared/assets/emojis';

import Preview from '../../components/Preview';
import OfferCard from './components/OfferCard'
import NoOffersCard from './components/NoOffersCard'
import { Emoji, LoadingLayer, FadeIn } from 'hosman-material';

const Offers = () => {
  const { sale } = useSelector((state) => state.sellerSales);
  const { offers, acceptedOffer, requestState } = useSelector((state) => state.sellerOffers);
  const [offerIdInAcceptance, setOfferIdInAcceptance] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  if (!sale.mandateSigned) return <Preview page='offers' sale={sale} />

  useEffect(() => {
    if (!acceptedOffer && searchParams.get('accepted')) {
      toast.success(
        <p>L'offre est en cours d'acceptation</p>
      , { icon: S_Check, duration: 5000 })
    }

    setOfferIdInAcceptance(searchParams.get('offerId'))
  }, []);

  useEffect(() => {
    if (acceptedOffer && searchParams.get('accepted')) {
      setOfferIdInAcceptance(null)
      searchParams.delete('accepted');
      searchParams.delete('offerId');
      setSearchParams(searchParams);
    }
  }, []);

  return (
    <div data-testid='Offers' className='md:mt-20'>
      {!acceptedOffer &&
        <p className='h2 mb-8 text-ih-indigo text-center'>Vos <span className='text-ih-blue'>offres d'achat</span> <span className='ml-2'><Emoji emojiSrc={handShake} width='w-7' /></span></p>
      }

      {acceptedOffer &&
        <p className='h2 mb-8 text-ih-indigo text-center' data-testid='Congratulation'>
          Félicitation, une offre est <span className='text-ih-green'>acceptée</span> <span className='ml-2'><Emoji emojiSrc={partyingFace} width='w-7' /></span>
        </p>
      }

      <LoadingLayer isReady={requestState == 'done'}>
        <FadeIn>
          {offers.length < 1 && <NoOffersCard />}

          {offers.map((offer) => (
            <div key={offer.id}>
              <OfferCard offer={offer} offerIdInAcceptance={Number(offerIdInAcceptance)} />
            </div>
          ))}
        </FadeIn>
      </LoadingLayer>
    </div>
  )
}

export default Offers;
