import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { S_Check } from 'hosman-material';

import { Modal, Box, Button, Label, InfoIcon } from 'hosman-material';

const ConfirmationModal = ({ offer, isOpen, closeModal }) => {
  return (
    <div data-testid='ConfirmationModal' className='text-ih-neutral'>
      <Modal isOpen={isOpen} closeModal={closeModal} width='w-full md:w-2/3'>
        <div className='flex flex-col gap-6 items-center p-6 md:w-96 mx-auto'>
          <Box>
            <FontAwesomeIcon icon={S_Check} size='xl' className='text-ih-green' />
          </Box>
          <p className='h3 text-center'>Vous êtes sur le point d'accepter l'offre de {offer.owner.first_name} {offer.owner.last_name}</p>
          <Label color='bg-ih-indigo'>{offer.amount.toLocaleString()} €</Label>

          <div className='flex gap-3'>
            <InfoIcon />
            <p className='text-ih-indigo'>La signature se fait à l'étape suivante</p>
          </div>

          <div className='flex gap-3'>
            <div className='w-auto'>
              <Button label='Annuler' type='tertiary' onClick={closeModal} />
            </div>
            <Link to={`signature`}>
              <Button label="Accepter l'offre" type='green' />
            </Link>
          </div>
        </div>
      </Modal>
    </div>
  )
}

// PropTypes
ConfirmationModal.propTypes = {
  offer: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func
}

export default ConfirmationModal;
