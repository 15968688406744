import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { validateStep } from '../Validators';

import NavigationButtons from '../../components/NavigationButtons'
import AddressInput from './components/AddressInput';
import { FadeIn } from 'hosman-material';
import { updateStep, updateEstimationData, updateErrors } from '../../../../../../reducers/_Pages/Public/estimationFunnelSlice';

const Address = () => {
  const { estimationData, estimationData: { placeData: { formatted_address, lat }}, errors} = useSelector((state) => state.estimationFunnel);
  const [triggerValidation, setTriggerValidation] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get('formatted_address')) return;

    const newEstimationData = {
      ...estimationData,
      placeData: {
        formatted_address: searchParams.get('formatted_address') || null,
        street_number:     searchParams.get('street_number') || null,
        street_name:       searchParams.get('street_name') || null,
        zip_code:          searchParams.get('zip_code') || null,
        city:              searchParams.get('city') || null,
        country_code:      searchParams.get('country_code') || null,
        lat:               searchParams.get('lat') || null,
        lng:               searchParams.get('lng') || null
      }
    }

    dispatch(updateEstimationData(newEstimationData))

    const contract = validateStep({ stepNumber: 1, data: newEstimationData });
    navigate(location.pathname, { replace: true });
    if (contract.valid) {
      dispatch(updateStep({ stepNumber: 2 }))
      return
    }

    setTriggerValidation(true)
  }, []);

  useEffect(() => {
    if (!triggerValidation) return

    const contract = validateStep({ stepNumber: 1, data: estimationData });
    if (contract.valid) {
      dispatch(updateStep({ stepNumber: 2 }))
    } else {
      toast.error('Le numéro de rue est requis', { id: 'address-validation-fail', duration: 3000 })
      dispatch(updateErrors(contract.errors))
    }
  }, [triggerValidation]);

  const handleChange = (addressObj) => {
    dispatch(updateErrors({}))
    setTriggerValidation(false)
    dispatch(updateEstimationData({ ...estimationData, placeData: addressObj }))

    if (!addressObj.lat) return;

    setTriggerValidation(true)
  }

  return (
    <div className='h-80' data-testid='Address'>
      <FadeIn>
        <div className='flex flex-col gap-7'>
          <p className='h2 text-ih-indigo'>Quelle est l'adresse du bien à estimer ?</p>

          <div className='flex flex-col gap-4 w-full'>
            <AddressInput
              name='formatted_address'
              label='Adresse du bien à estimer'
              onChange={handleChange}
              value={formatted_address}
              placeholder="12 rue Saint Denis, 75011 Paris"
              listPlacement='bottom-auto relative'
              errors={errors}
            />
          </div>

          <NavigationButtons classes='hidden md:block my-5' />
        </div>
      </FadeIn>
    </div>
  )
}

export default Address;
