import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { S_CalendarDays } from 'hosman-material';

import Preview from '../../components/Preview';
import { VisitReportCard } from './components/VisitReportCard';
import { NoVisitReportCard } from './components/NoVisitReportCard';
import { Label, Button, LoadingLayer, FadeIn } from 'hosman-material';

const VisitReports = () => {
  const { sale } = useSelector((state) => state.sellerSales);
  const { visitsWithReport, requestState } = useSelector((state) => state.sellerVisits);
  let { saleId } = useParams();

  if (!sale.mandateSigned) return <Preview page='visitReports' sale={sale} />

  return (
    <div data-testid='VisitReports'>
      <div className='flex items-center justify-between mb-8'>
        <div className='flex items-center gap-3 mx-auto md:mx-0'>
          <p className='h3 text-ih-neutral'>Comptes-rendus de visites</p>
          {visitsWithReport.length > 0 &&
            <Label color='bg-ih-blue' roundedFull>{String(visitsWithReport.length).padStart(2, '0')}</Label>
          }
        </div>
        {visitsWithReport.length > 0 &&
          <div className='hidden md:block'>
            <Link to={`/ev/ventes/${saleId}/planning`}>
              <Button label='Voir le planning de visite' small icon={S_CalendarDays} />
            </Link>
          </div>
        }
      </div>

      <LoadingLayer isReady={requestState == 'done'}>
        <FadeIn>
          {visitsWithReport.length < 1 && <NoVisitReportCard saleId={saleId} />}

          <div className='grid grid-cols-1 md:grid-cols-2 gap-8 md:mx-8'>
            {visitsWithReport.map((visit) => {
              return (
                <div key={visit.id}>
                  <VisitReportCard visit={visit} />
                </div>
              );
            })}
          </div>
        </FadeIn>
      </LoadingLayer>

      {visitsWithReport.length > 0 &&
        <div className='md:hidden mt-6'>
          <Button label='Voir le planning de visite' icon={S_CalendarDays} />
        </div>
      }
    </div>
  );
}

export default VisitReports;
