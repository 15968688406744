import React from 'react';
import PropTypes from 'prop-types';
import { longDateTime } from '../../../../../../../shared/utils/formattedDates';
import { InterestLabel } from './InterestLabel';
import { ProsAndConsCard } from "./ProsAndConsCard";

export const VisitReportCard = ({ visit }) => {
  return (
    <div className='border border-ih-purple-50 rounded-3xl' data-testid='VisitReportCard'>
      <div className='bg-ih-indigo p-5 rounded-3xl flex items-center justify-between text-ih-purple-5'>
        <div>
          <p className='h4'>{visit.user.first_name} {visit.user.last_name}</p>
          <p className='text-xs'>{longDateTime({date: visit.start_time, capitalized: true})}</p>
        </div>
        <InterestLabel interest={visit.visit_report.interest} />
      </div>

      <div className='p-7 text-ih-neutral'>
        <p className='mb-3 font-semibold'>Contexte d'achat</p>
        <p className='whitespace-pre-line'>{visit.visit_report.context}</p>

        {visit.visit_report.next_steps &&
          <>
            <p className='mt-5 mb-3 font-semibold'>Prochaines étapes</p>
            <p className='whitespace-pre-line'>{visit.visit_report.next_steps}</p>
          </>
        }

        {visit.visit_report.questions_seller &&
          <>
            <p className='mt-5 mb-3 font-semibold'>Questions au vendeur</p>
            <p className='whitespace-pre-line'>{visit.visit_report.questions_seller}</p>
          </>
        }
      </div>
      { <ProsAndConsCard visit={visit} /> }
    </div>
  );
};

// PropTypes
VisitReportCard.propTypes = {
  visit: PropTypes.object.isRequired
};
