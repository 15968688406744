import client from '../../client';

const OFFERS = {
  getOffers(params) {
    return client.get('/me/buyers/offers', params);
  },

  getOffer(offerId) {
    return client.get(`/me/buyers/offers/${offerId}`);
  },
}

export default OFFERS;
