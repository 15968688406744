import React from 'react';
import { useSelector } from 'react-redux';

import SaleFolder from '../../../../../../../components/Sale/SaleFolder';

const Documents = () => {
  const { documents } = useSelector((state) => state.buyerDocuments);

  return <SaleFolder documents={documents} />
};

export default Documents;
