import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Box } from 'hosman-material';
import { InfoIcon } from 'hosman-material';
const classNames = require('classnames');

const SaleKeyNumbersCard = ({ thresholdWarning, icon, title, subtitle, warningText, numbersColor, }) => {
  let warningClassesCard = classNames(
    'border rounded-xl flex items-center justify-start gap-2 py-1 px-2 bg-ih-indigo-5 border-ih-indigo-10',
    { 'bg-ih-red-10 border-ih-red-40': thresholdWarning },
    { 'bg-ih-indigo-5 border-ih-indigo-10': !thresholdWarning },
  );

  return(
    <Card>
      <div className='flex flex-col items-center justify-center gap-2 mx-auto p-7 w-72 h-full' data-testid='SaleKeyNumbersCard'>
        <div className='mx-auto'>
          <Box bgColor={thresholdWarning ? 'bg-ih-red-10' : 'bg-ih-purple-10'}>
            <FontAwesomeIcon icon={icon} size='lg' className={thresholdWarning ? 'text-ih-red' : 'text-ih-indigo'} />
          </Box>
        </div>

        <p className='text-sm font-medium text-ih-neutral'>{title}</p>

        <p className={`h2 ${numbersColor}`} data-testid='SaleKeyNumbersTitle'>{subtitle}</p>

        {warningText &&
          <div className={warningClassesCard} data-testid='SaleKeyNumbersWarningCard'>
            <InfoIcon iconColor={thresholdWarning ? 'text-ih-red' : 'text-ih-indigo'} bgColor={thresholdWarning ? 'bg-ih-red-40' : 'bg-ih-indigo-40'} />

            <p className='text-xs font-medium text-ih-neutral' data-testid='SaleKeyNumbersWarning'>{warningText}</p>
          </div>
        }
      </div>
    </Card>
  )
};

SaleKeyNumbersCard.propTypes = {
  thresholdWarning: PropTypes.bool,
  icon: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  warningText: PropTypes.string,
  numbersColor: PropTypes.string
}

export default SaleKeyNumbersCard;
