import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import { D_CalendarStar } from 'hosman-material';
import { toast } from 'react-hot-toast';

import UNAVAILABILITIES from '../../api/ressources/SellerApp/unavailabilities';

import { eventDate } from '../../../shared/utils/formattedDates';
import { ga4Event } from '../../../shared/utils/ga4Event';

export const getUnavailabilities = createAsyncThunk(
  '/unavailabilities',
  async (propertyId) => {
    const response = await UNAVAILABILITIES.getUnavailabilities(propertyId)
    return response.data
  }
);

export const updateUnavailabilities = createAsyncThunk(
  '/update_unavailabilities',
  async ({ id, slot, slots, type }) => {
    const response = await UNAVAILABILITIES.updateUnavailabilities(id, slots)
    return { slot: slot, unavailabilities: slots, type: type }
  }
);

const initialState = {
  unavailabilities: [],
  unavailabilitiesRequestState: '',
};

export const unavailabilitiesSlice = createSlice({
  name: 'unavailabilities',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getUnavailabilities.pending, (state) => {
      state.unavailabilities = initialState.unavailabilities;
      state.unavailabilitiesRequestState = 'pending'
    })
    builder.addCase(getUnavailabilities.fulfilled, (state, { payload }) => {
      state.unavailabilities = formatEvents(payload);
      state.unavailabilitiesRequestState = 'done'
    })
    builder.addCase(updateUnavailabilities.fulfilled, (state, { payload }) => {
      state.unavailabilities = payload.unavailabilities
      if (payload.type === 'edit') {
        toast.error(
          <div className='flex flex-col'>
            <p>Créneau d'indisponibilité ajouté</p>
            <p className='text-ih-indigo font-semibold'>{eventDate(payload.slot.start, payload.slot.end)}</p>
          </div>
        , { icon: D_CalendarStar, duration: 5000 })
      }
      if (payload.type === 'delete') {
        toast.success(
          <div className='flex flex-col'>
            <p>Créneau à nouveau disponible</p>
            <p className='text-ih-indigo font semi-bold'>{eventDate(payload.slot.start, payload.slot.end)}</p>
          </div>
        , { icon: D_CalendarStar, duration: 5000 })
      }
    })
  },
})

const formatEvents = (payload) => {
  return payload.map((slot) => {
    return { id: uuidv4(), start: slot.start, end: slot.end, isWeeklyRecurring: slot.isWeeklyRecurring || false }
  });
};

export default unavailabilitiesSlice.reducer;
