import React from 'react';
import PropTypes from 'prop-types';

import Error from './Error';

const TextInput = ({ refProp, name, label, type = 'text', value = '', onChange, placeholder, paddings = '', disabled = false, errors = {} }) => {
  return (
    <div className='w-full'>
      {label &&
        <div className='font-semibold text-ih-indigo mb-3'>
          <label htmlFor={name}>{label}</label>
        </div>
      }

      <input
        ref={refProp}
        id={name}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder || label}
        disabled={disabled}
        className={`${paddings} block w-full h-14 text-ih-indigo rounded-2xl border-ih-purple-40 hover:border-ih-purple-50 p-3 focus:ring-0 focus:border-ih-blue-80 disabled:cursor-not-allowed disabled:bg-ih-neutral-5 disabled:text-ih-indigo-50 placeholder-ih-purple-30 ${errors[name] ? 'border-ih-red-60' : ''}`}
      />

      <Error name={name} label={label} errors={errors} />
    </div>
  );
};

export default TextInput;

TextInput.propTypes = {
  refProp: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  paddings: PropTypes.string
}
