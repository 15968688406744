import React from 'react';

import { Label, Emoji } from 'hosman-material';
import { camera,partyingFace, clappingHands } from '../../../shared/assets/emojis';

const SaleStateLabel = ({ sale }) => {
  const labelInfos = () => {
    if (sale.on_break) {
      return { color: 'bg-ih-orange', label: 'En pause' };
    } else {
      switch (sale.state) {
        case 'awaiting_apointment':
        case 'assigned_lead':
        case 'unreached_lead':
        case 'contacted_lead':
        case 'contacted_prospect':
        case 'awaiting_visit':
          if (sale.last_estimation_meeting_id) {
            return { color: 'bg-ih-blue', label: "Rendez-vous d'estimation" };
          } else {
            return { color: 'bg-ih-blue', label: 'Estimation en ligne' };
          }
        case 'awaiting_term':
          return { color: 'bg-ih-blue', label: 'Estimation disponible' };
        case 'awaiting_term_signature':
          return { color: 'bg-ih-blue', label: 'Onboarding en cours' };
        case 'awaiting_publication':
          if (sale.timeline?.last_photo_meeting_at && new Date(sale.timeline.last_photo_meeting_at).getTime() > new Date().getTime()) {
            return { color: 'bg-ih-green', label: 'Rendez-vous photo planifié', emojy: camera };
          } else {
            return { color: 'bg-ih-blue', label: 'Bientôt commercialisé' };
          }
        case 'awaiting_offer':
          return { color: 'bg-ih-blue', label: 'Annonce publiée' };
        case 'awaiting_sale_agreement':
          return { color: 'bg-ih-green', label: 'Offre acceptée', emojy: partyingFace };
        case 'awaiting_notarial_deed':
          return { color: 'bg-ih-green', label: 'Promesse de vente signée', emojy: clappingHands };
        case 'sold':
          return { color: 'bg-ih-green', label: 'Vendu', emojy: clappingHands };
        default:
          return { color: 'bg-ih-blue', label: 'En attente du RDV expert' };
      }
    }
  }

  const payload = labelInfos();

  return (
    <Label color={payload.color}>{payload.label}
      {payload.emojy && <span> <Emoji emojiSrc={payload.emojy} width='w-4' /></span>}
    </Label>
  )
}

export default SaleStateLabel;
