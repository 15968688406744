const gtmScript = () => {
  window.addEventListener('load', function() {
    if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
      (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        if (process.env.NODE_ENV === 'staging') j.src += '&gtm_auth=cysMdMC7XenZFjqTsh2BBw&gtm_preview=env-109&gtm_cookies_win=x'
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-NGQZT4X');
    }
  });
}

export default gtmScript;
