import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Event } from '../../../Event';

export const Events = ({ events, referenceDate, handleEventOpen, handleEventUpdate, handleEventDelete }) => {
  const setGridRow = (start, end) => {
    const diffMinutes = ((end - start) / 60000);
    const span = Math.round(diffMinutes / 15)
    return `${Math.round(((start.getHours()-7) * 4 + 2) + (start.getMinutes() * 0.02))} / span ${span > 3 ? span : 3}`
  }
  const setColStart = (date) => {
    const dateBeginningOfDay = new Date(date).setUTCHours(0,0,0,0)
    const referenceDateBeginningOfDay = new Date(referenceDate).setUTCHours(0,0,0,0)
    const diffDays = Math.floor((dateBeginningOfDay - referenceDateBeginningOfDay) / 86400000);

    switch (diffDays) {
      case 0: return 'col-start-1';
      case 1: return 'col-start-2';
      case 2: return 'col-start-3';
      case 3: return 'col-start-4';
      case 4: return 'col-start-5';
      case 5: return 'col-start-6';
      case 6: return 'col-start-7';
      default: return 'hidden'
    }
  }

  const setZindex = (event) => {
    switch (event.typeSlug) {
      case 'visit':
        return 'z-30';
      default:
        return 'z-20'
    }
  }

  const handleClick = (event) => {
    if (!event.clickable) return;

    if (event.typeSlug === 'visit') {
      handleEventOpen(event);
    } else if (event.typeSlug === 'indispo') {
      handleEventUpdate(event)
    }
  }

  return (
    <>
      {events.map((event) => {
        return (
          <li key={event.id} onClick={() => handleClick(event)} className={`${setColStart(event.start)} relative flex ${setZindex(event)} ${event.clickable ? 'cursor-pointer' : ''}`} style={{ gridRow: setGridRow(event.start, event.end) }}>
            <Event event={event} handleEventUpdate={handleEventUpdate} handleEventDelete={handleEventDelete} />
          </li>
        )
      })}
    </>
  )
}

Events.propTypes = {
  events: PropTypes.array,
  referenceDate: PropTypes.object,
  handleEventOpen: PropTypes.func,
  handleEventUpdate: PropTypes.func,
  handleEventDelete: PropTypes.func
};
