import client from '../../client';

const UNAVAILABILITIES = {
  getUnavailabilities(propertyId) {
    return client.get(`/properties/${propertyId}/unavailabilities`);
  },

  updateUnavailabilities(propertyId, slots) {
    const formattedSlots = slots.map(({id, ...keepAttrs}) => keepAttrs)
    return client.put(`/properties/${propertyId}/unavailabilities`, { unavailabilities: formattedSlots });
  }
}

export default UNAVAILABILITIES;
