import React from 'react';
import { generateLink } from '../../../shared/utils/railsRoutes';
import { ga4Event } from '../../../shared/utils/ga4Event';
import userKind from '../../../shared/utils/userKind';

import { ArticleCard } from 'hosman-material';

const ArticlesList = ({ articles, user }) => {
  const handleGA4Event = (article) => ga4Event('click_blog_article', { user_id: user.id, user_kind: userKind(user), article_name: article.slug })

  return (
    <div className='grid grid-cols-1 md:flex md:justify-center md:flex-wrap md:gap-8' data-testid='articles-list'>
      {articles.map((article) => (
        <div key={article.id}
             className='mb-10 md:mb-0'
             onClick={() => handleGA4Event(article)}>
          <ArticleCard
            href={generateLink(RailsRoutes.blog_article_path(article.slug))}
            imageSrc={article.banner_picture_url}
            labelContent={article.category.title}
            title={article.title}
            titleColor='text-ih-indigo'
            author={article.author.first_name}
            readTime={article.time_to_read.toString()}
            authorProfilePicture={article.author.profile_pic_url}
          />
        </div>
      ))}
    </div>
  )
}

export default ArticlesList;
