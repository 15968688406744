import React from 'react';
import { LoadingCircle } from 'hosman-material'

const LoadingBlock = () => {
  return (
    <div className='flex flex-col gap-3' data-testid='LoadingBlock'>
      <p className='h2 text-ih-indigo'>Votre bien mérite le meilleur accompagnement</p>

      <div className='typo-subtitle text-ih-indigo-60'>
        Dans un instant vous pourrez prendre un rendez-vous téléphonique avec l'expert de votre secteur.
      </div>

      <div className='flex justify-center items-start' >
        <LoadingCircle margin='m-0' size='w-20 h-20'/>
      </div>
    </div>
  )
}

export default LoadingBlock
