import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as dayjs from 'dayjs';
const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

export const SufficientAbailabilityState = ({ referenceDate, unavailabilities }) => {
  const [availableHours, setAvailableHours] = useState();
  const [sufficientAbailabilities, setSufficiantAvailabilities] = useState();

  useEffect(() => {
    setAvailableHours(98)

    const monday = new Date(referenceDate);
    const sunday = new Date(referenceDate);
    monday.setHours(0, 0, 0);
    sunday.setDate(sunday.getDate() + 7);
    sunday.setHours(23, 59, 59);

    const weekUnavalabilities = unavailabilities.filter((slot) => dayjs(slot.start).isBetween(monday, sunday, 'day', '[)'))
    if (weekUnavalabilities.length < 1) return;
    let unavailableHours = 0
    weekUnavalabilities.map((slot) => unavailableHours += dayjs(slot.end).diff(dayjs(slot.start), 'hour'))
    setAvailableHours((state) => state - unavailableHours)
  }, [referenceDate, unavailabilities]);

  useEffect(() => {
    setSufficiantAvailabilities(availableHours >= 50)
  }, [availableHours]);

  return (
    <div className='flex items-center gap-2'>
      <div className={`border px-3 py-1 rounded-xl ${sufficientAbailabilities ? 'bg-ih-green-5 border-ih-green-30' : 'bg-ih-red-5 border-ih-red-20'}`}>
        <p className={`h4 ${sufficientAbailabilities ? 'text-ih-green' : 'text-ih-red'}`}>{availableHours}h</p>
      </div>

      <p className='w-36 text-xs font-medium'>{sufficientAbailabilities ? 'Créneaux suffisants' : 'Créneaux insuffisants'} cette semaine</p>
    </div>
  );
};

// PropTypes
SufficientAbailabilityState.propTypes = {
  referenceDate: PropTypes.object,
  unavailabilities: PropTypes.array
};
