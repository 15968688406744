import React from 'react';
import { useSelector } from 'react-redux';

import EstimationResult from '../../../../../../shared/EstimationResult';

const FunnelEstimation = () => {
  const { sale } = useSelector((state) => state.sellerSales);
  const { user } = useSelector((state) => state.user);

  if (!sale.id) return null;
  
  return (
    <div data-testid='FunnelEstimation'>
      <EstimationResult sale={{ ...sale, owner: user }} />
    </div>
  );
}

export default FunnelEstimation;
