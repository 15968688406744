import React from 'react';
import PropTypes from 'prop-types';

import { BulletPoint } from 'hosman-material';

export const InterestLabel = ({ interest }) => {
  const setPayload = () => {
    switch (interest) {
      case 'uninterested':
        return { color: 'bg-ih-red', label: 'Pas intéressé' };
      case 'maybe_interested':
        return { color: 'bg-ih-orange', label: 'Peut-être intéressé' };
      case 'interested':
        return { color: 'bg-ih-green', label: 'Intéressé' };
      case 'very_interested':
        return { color: 'bg-ih-green', label: 'Très intéressé' };
      case 'offer_submitted':
        return { color: 'bg-ih-green', label: 'Offre remise' };
      case 'not_anymore_found_other':
      case 'not_anymore_not_convinced':
      case 'not_anymore_purchase_delayed':
      case 'not_anymore_never_answered':
      case 'not_anymore_other':
        return { color: 'bg-ih-red', label: 'Plus intéressé' };
      default:
        return { color: 'bg-ih-red', label: 'Pas intéressé' };
    }
  }

  const payload = setPayload();

  return (
    <div className='flex items-center'>
      <BulletPoint color={payload.color} />
      <p className='font-semibold'>{payload.label}</p>
    </div>
  );
};

// PropTypes
InterestLabel.propTypes = {
  interest: PropTypes.string.isRequired
};
