import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { D_SackDollar, D_House, D_HandHoldingDollar, D_MoneyBillTransfer, D_MemoCircleCheck, D_ArrowDownToBracket } from 'hosman-material';

import FinancialPlanCard from './FinancialPlanCard';

const FinancialPlanSection = ({ offer }) => {
  const profile = offer.financial_profile

  const displayFinancialBoxes = () => {
    if (profile.cash_payment) {
      return <FinancialPlanCard icon={D_SackDollar} title='Paiement comptant' content={`${offer.amount.toLocaleString()} €`} />
    } else if (profile.real_estate_sale) {
      if (profile.loan) {
        return (
          <>
            <FinancialPlanCard icon={D_House} title='Vente immobilière' content='Oui' />
            <FinancialPlanCard icon={D_MoneyBillTransfer} title='Emprunt' content='Oui' />
            <FinancialPlanCard icon={D_HandHoldingDollar} title='Apport' content={`${profile.apport.toLocaleString()} €`} />
            <FinancialPlanCard icon={D_MoneyBillTransfer} title='Condition suspensive de prêt' content={profile.loan_condition ? 'Oui' : 'Non'} />
            {promiseOfFinancingBox()}
          </>
        )
      } else {
        return (
          <>
            <FinancialPlanCard icon={D_House} title='Vente immobilière' content='Oui' />
            <FinancialPlanCard icon={D_MoneyBillTransfer} title='Emprunt' content='Non' />
          </>
        )
      }
    } else if (!profile.real_estate_sale && profile.loan) {
      return (
        <>
          <FinancialPlanCard icon={D_HandHoldingDollar} title='Apport' content={`${profile.apport.toLocaleString()} €`} />
          <FinancialPlanCard icon={D_MoneyBillTransfer} title='Condition suspensive de prêt' content={profile.loan_condition ? 'Oui' : 'Non'} />
          {promiseOfFinancingBox()}
        </>
      )
    } else return;
  }

  const promiseOfFinancingBox = () => {
    return (
      <>
        <FinancialPlanCard icon={D_MemoCircleCheck} title='Attestation de faisabilité' content={profile.promise_of_financing ? 'Oui' : 'Non'} />
        {(profile.promise_of_financing && profile.promise_of_financing_doc_url) &&
          <a href={profile.promise_of_financing_doc_url.replace('/upload', `/upload/fl_attachment:attestation-de-faisabilite`)}>
            <FontAwesomeIcon icon={D_ArrowDownToBracket} className='h-4 w-4 mt-1 bg-ih-purple-10 hover:bg-ih-purple-20 p-4 rounded-full' />
          </a>
        }
      </>
    )
  }

  return (
    <>
      <p className='h4 text-ih-blue'>Plan de financement</p>

      <div className='flex flex-col md:flex-row md:flex-wrap gap-10 text-ih-neutral mt-3'>
        {displayFinancialBoxes()}
      </div>
    </>
  )
}

// PropTypes
FinancialPlanSection.propTypes = {
  offer: PropTypes.object.isRequired
}

export default FinancialPlanSection;
