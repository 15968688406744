import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Label, Box, Emoji } from 'hosman-material';

const Action = ({ icon, title, displayed, emoji, important = false, children }) => {
  return (
    <>
      {displayed &&
        <div className='flex flex-col md:flex-row text-center md:text-left items-center justify-between gap-4'>
          <div className='flex flex-col md:flex-row text-center md:text-left items-center gap-2'>
            <Box>
              <FontAwesomeIcon icon={icon} size='lg' className='text-ih-blue' />
            </Box>
            <p className='h4 text-ih-indigo'>{title}</p>
            {emoji && <Emoji emojiSrc={emoji} width='w-5' />}
            {important && <Label color='bg-ih-indigo'>Important</Label>}
          </div>
          {children}
        </div>
      }
    </>
  )
}

export default Action;

Action.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  displayed: PropTypes.bool,
  important: PropTypes.bool,
  children: PropTypes.node
};
