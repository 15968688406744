import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal, Button } from 'hosman-material';
import { S_LocationDot } from 'hosman-material';

import { purgeEstimationData } from '../../../../../../../reducers/_Pages/Public/estimationFunnelSlice';

const RestoreModal = ({ persitedStoreModalOpen, setPersitedStoreModalOpen }) => {
  const { estimationData: { placeData } } = useSelector((state) => state.estimationFunnel);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!placeData.lat) return

    setPersitedStoreModalOpen(true)
  }, []);

  useEffect(() => {
    if (placeData.lat) return

    setPersitedStoreModalOpen(false)
  }, [placeData]);

  const continueFunnel = () => {
    setPersitedStoreModalOpen(false);
    navigate(location.pathname, { replace: true });
  }

  const emptyPersistedStore = () => {
    setPersitedStoreModalOpen(false);
    dispatch(purgeEstimationData());
  }

  return (
    <Modal isOpen={persitedStoreModalOpen} closeModal={() => setPersitedStoreModalOpen(false)} width='w-full md:w-2/3'>
      <div className='flex flex-col gap-10 p-6 text-center text-ih-indigo' data-testid='RestoreModal'>
        <div className='flex flex-col gap-3'>
          <p className='h2'>Une estimation est déjà en cours</p>
          <div className='flex items-center justify-center gap-2 text-ih-blue'>
            <FontAwesomeIcon icon={S_LocationDot} />
            <p>{placeData.formatted_address}</p>
          </div>
        </div>
        <p className='w-2/3 mx-auto'>Souhaitez-vous continuer cette estimation ou estimer un autre bien?</p>

        <div className='flex gap-6 justify-center'>
          <Button label='Nouvelle estimation' type='tertiary' onClick={() => emptyPersistedStore()} />
          <Button label='Continuer' onClick={() => continueFunnel()} />
        </div>
      </div>
    </Modal>
  )
}

export default RestoreModal;
