const allSteps = [
  { number: 1, name: 'Adresse', path: 'adresse', displayNavigation: true },
  { number: 2, name: 'Type de bien', path: 'typologie', displayNavigation: true },
  { number: 3, name: 'Informations principales', path: 'informations', displayNavigation: true },
  { number: 4, name: 'Spécificités du bien', path: 'specificites', displayNavigation: true },
  { number: 5, name: 'Motif', path: 'motif', displayNavigation: true },
  { number: 6, name: 'Contact', path: 'contact', displayNavigation: false },
  { number: 7, name: 'Résultat', path: 'resultat', displayNavigation: false },
]

const matchedStep = allSteps.find((step) => window.location.pathname.includes(step.path));

export const DemoStore = {
  storeCreationTime: new Date(),
  allSteps: allSteps,
  currentStep: matchedStep || allSteps[0],
  placeData: {
    formatted_address: '4 Rue de Rivoli, 75002 Paris, France',
    street_number:     '4',
    street_name:       'Rue de Rivoli',
    zip_code:          '75002',
    city:              'Paris',
    country_code:      'FR',
    lat:               48.8807699,
    lng:               2.3416789
  },
  typology: 'apartment',
  mainInfos: {
    carrez_area:       34,
    living_area:       56,
    land_area:         70,
    floor:             3,
    total_floor:       7,
    room_number:       2,
    bedroom_number:    1,
    construction_time: 'before_1850',
    good_state:        'good'
  },
  extraInfos: {
    elevator: false,
    balcony: false,
    terrace: true,
    cellar: false,
    concierge: false,
    parking: true
  },
  estimationReason: 'seller',
  lead: {
    civility: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    phone_country_code: 'FR'
  }
}
