import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import NavigationButtons from '../../components/NavigationButtons'
import ApartmentInputs from './components/ApartmentInputs'
import HouseInputs from './components/HouseInputs'
import { FadeIn } from 'hosman-material';

import { updateEstimationData, updateErrors } from '../../../../../../reducers/_Pages/Public/estimationFunnelSlice';

const MainInfos = () => {
  const { estimationData, estimationData: { typology, mainInfos }, errors} = useSelector((state) => state.estimationFunnel);
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target
    dispatch(updateEstimationData({
      ...estimationData,
      mainInfos: {
        ...mainInfos,
        [name]: value
      }
    }))

    removeFieldError(name)
  };

  const removeFieldError = (name) => {
    if (!errors[name]) return;

    const newErrors = {...errors}
    delete newErrors[name];
    dispatch(updateErrors(newErrors))
  }

  return (
    <div data-testid='MainInfos'>
      <FadeIn>
        <div className='flex flex-col gap-6'>
          <p className='h2 text-ih-indigo'>Vérifions ensemble les informations de votre bien.</p>

          {typology === 'apartment' && <ApartmentInputs handleInputChange={handleInputChange} />}
          {typology === 'house' && <HouseInputs handleInputChange={handleInputChange} />}

          <NavigationButtons classes='hidden md:block my-5' />
        </div>
      </FadeIn>
    </div>
  )
}

export default MainInfos;
