import React from 'react';
import PropTypes from 'prop-types';

import { Label, Emoji } from 'hosman-material';
import { crossedFingers, eyes, partyingFace, disappointedFace } from '../../../shared/assets/emojis';

const StateLabel = ({ offer }) => {
  const labelInfos = () => {
    switch (offer.review_status) {
      case null:
        return { color: 'bg-ih-orange', label: 'Offre non finalisée', emojy: eyes };
      case 'pending':
      case 'ongoing':
      case 'partly_accepted':
      case 'unsent_for_review':
        return { color: 'bg-ih-blue', label: 'Offre remise', emojy: crossedFingers };
      case 'accepted':
        return { color: 'bg-ih-green', label: 'Offre acceptée', emojy: partyingFace };
      case 'refused':
        return { color: 'bg-ih-red', label: 'Offre refusée', emojy: disappointedFace };
      case 'dropped':
        return { color: 'bg-ih-red', label: 'Offre abandonnée', emojy: disappointedFace };
    }
  }

  const payload = labelInfos();

  return (
    <Label color={payload.color}>{payload.label} <Emoji emojiSrc={payload.emojy} width='w-4' /></Label>
  )
}

export default StateLabel;

// PropTypes
StateLabel.propTypes = {
  offer: PropTypes.object.isRequired
};
