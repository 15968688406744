import client from '../../client';

const PHOTO_MEETINGS = {
  getPhotoMeetings(saleId) {
    return client.get(`/sales/${saleId}/photo_meetings`);
  },

  getSlots(saleId) {
    return client.get(`/sales/${saleId}/photo_meetings/slots`);
  },

  createPhotoMeeting(saleId, params) {
    return client.post(`/sales/${saleId}/photo_meetings`, { photo_meeting: params });
  },
}

export default PHOTO_MEETINGS;
