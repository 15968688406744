import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DesktopMenu from './DesktopMenu';
import MobileBanner from './MobileBanner';
import MobileMenu from './MobileMenu';

const classNames = require('classnames');

const Sidebar = ({
  navigation,
  selectedNav,
  handleSelect,
  clientSwitch,
  children
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleColor = (item) => {
    return classNames(
      { 'bg-white text-ih-blue': item.name === selectedNav?.name},
      { 'text-ih-blue': item.icon.iconName === 'message-heart'},
      { 'text-ih-indigo': item.name !== selectedNav?.name && item.icon.iconName !== 'message-heart'},
      { 'transition-color duration-200 hover:bg-white': item.name !== selectedNav?.name && !item.disabled }
    );
  };

  const handleClick = (nav) => {
    handleSelect(nav);
    setSidebarOpen(false);
  };

  return (
    <>
      <MobileMenu
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        handleClick={handleClick}
        handleColor={handleColor}
        navigation={navigation}
        clientSwitch={clientSwitch}
      />

      <DesktopMenu
        clientSwitch={clientSwitch}
        navigation={navigation}
        selectedNav={selectedNav}
        handleClick={handleClick}
        handleColor={handleColor}
      />


      <div className='h-full lg:pl-80 flex flex-col flex-1'>
        <MobileBanner
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />

        <main className='ih-container'>
          {children}
        </main>
      </div>
    </>
  );
};

export default Sidebar;

// PropTypes
Sidebar.propTypes = {
  children: PropTypes.node,
  navigation: PropTypes.array,
  selectedNav: PropTypes.object,
  handleSelect: PropTypes.func,
  clientSwitch: PropTypes.func
};
