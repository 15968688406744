import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Box } from 'hosman-material';

const FinancialPlanCard = ({ icon, title, content }) => {
  return (
    <div className='flex items-center gap-3'>
      <Box bgColor='bg-ih-purple-5'>
        <FontAwesomeIcon icon={icon} className='text-ih-indigo' />
      </Box>
      <div>
        <p className='font-semibold'>{title}</p>
        <p>{content}</p>
      </div>
    </div>
  )
}

// PropTypes
FinancialPlanCard.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

export default FinancialPlanCard;
