import React from 'react';
import { useSelector } from 'react-redux';

import { FadeIn, ContactCard } from 'hosman-material';

const Contacts = () => {
  const { contacts } = useSelector((state) => state.buyerContacts);

  return (
    <FadeIn>
      <div className='w-full mt-10'>
        <div className='grid grid-cols-1 md:flex md:flex-wrap md:gap-4'>
          {contacts.map((contact) => {
            return (
              <div key={contact.id}>
                <div className='w-72 rounded-3xl mx-auto mb-5'>
                  <ContactCard
                    avatar={contact.profile_pic_url}
                    email={contact.email}
                    name={`${contact.first_name} ${contact.last_name}`}
                    phone={contact.phone_number}
                    title={contact.type}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </FadeIn>
  )
}

export default Contacts;
