import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { D_Book } from 'hosman-material';
import { generateLink } from '../../../../../shared/utils/railsRoutes';

import PageTitle from '../../../../components/PageTitle/PageTitle';
import ArticlesList from '../../../../components/Blog/ArticlesList';
import GuidesList from './components/GuidesList';
import { Button, LoadingLayer, FadeIn } from 'hosman-material';

import { getArticles } from '../../../../reducers/App/articlesSlice';

const Ressources = () => {
  const { articles } = useSelector((state) => state.articles);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getArticles({ per_page: 3, 'q[category_slug_eq]': 'guide-acheteur' }));
  }, []);

  return (
    <div data-testid='Ressources'>
      <PageTitle icon={D_Book} title='Ressources' />

      <LoadingLayer isReady={articles.length > 0}>
        <FadeIn>
          <div className='md:w-1/2 h2 text-center text-ih-indigo mx-auto mb-8 leading-10'>
            Envie d'investir ? Tous nos conseils pour
            <span className='text-ih-blue'> préparer votre projet </span>
            d'achat
          </div>

          <div className='text-center'>
            <a href={generateLink(RailsRoutes.blog_articles_path())}>
              <Button label='Découvrir tous nos conseils' />
            </a>
          </div>

          <div className='mt-8 mb-16'>
            <ArticlesList articles={articles} user={user} />
          </div>

          <div className='md:w-1/2 h2 text-center text-ih-indigo mx-auto'>
            Bénéficiez de notre expertise pour
            <span className='text-ih-blue'> votre projet immobilier </span>
          </div>

          <div className='mt-8 mb-16'>
            <GuidesList />
          </div>
        </FadeIn>
      </LoadingLayer>
    </div>
  );
}

export default Ressources;
