import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import React from 'react';
import { toast } from 'react-hot-toast';
import { longDateTime } from '../../../shared/utils/formattedDates';

import VALUATIONS from '../../api/ressources/SellerApp/valuations';
import BUYER_ALERTS from '../../api/ressources/SellerApp/buyerAlerts';
import PROPERTIES from '../../../shared/apiPublic/ressources/properties';
import ESTIMATION_MEETINGS from '../../api/ressources/SellerApp/estimationMeetings';

export const getValuation = createAsyncThunk(
  '/valuation#show',
  async (valuationId) => {
    const response = await VALUATIONS.getValuation(valuationId)
    return response.data
  }
);

export const updateValuation = createAsyncThunk(
  '/valuation#update',
  async ({ valuationId, params }) => {
    await VALUATIONS.updateValuation(valuationId, params)
    return params
  }
);

export const getSectorPropertiesCoordinates = createAsyncThunk(
  '/sectorPropertiesCoordinates#index',
  async (params) => {
    const response = await PROPERTIES.getPropertiesCoordinates(params)
    return response.data
  }
);

export const getBuyerAlerts = createAsyncThunk(
  '/buyerAlerts#index',
  async ({ saleUniqueHash, price }) => {
    const response = await BUYER_ALERTS.getBuyerAlerts(saleUniqueHash, price)
    return response.data
  }
);

export const getSoldProperties = createAsyncThunk(
  '/soldProperties#index',
  async (params) => {
    const response = await PROPERTIES.getProperties(params)
    return response.data
  }
);

export const getEstimationMeeting = createAsyncThunk(
  '/estimationMeeting#show',
  async (estimationMeetingId) => {
    const response = await ESTIMATION_MEETINGS.getEstimationMeeting(estimationMeetingId)
    return response.data
  }
);

export const getAvailabaleSlots = createAsyncThunk(
  '/meetingSlots#index',
  async () => {
    const response = await ESTIMATION_MEETINGS.getAvailabaleSlots()
    return response.data
  }
);

export const createEstimationMeeting = createAsyncThunk(
  '/expertestimationMeeting#create',
  async ({ saleId, slot }) => {
    const response = await ESTIMATION_MEETINGS.createEstimationMeeting(saleId, slot)
    return response.data
  }
);

const initialState = {
  valuation: {},
  sectorPropertiesCoordinates: {
    requestState: '',
    sold: 0,
    mandateSigned: 0
  },
  buyerAlerts: { count: 0 },
  soldProperties: [],
  estimationMeeting: {},
  availabaleSlots: []
};

export const onlineEstimationsSlice = createSlice({
  name: 'onlineEstimation',
  initialState,
  reducers: {
    resetOnlineEstimationReducer: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getValuation.fulfilled, (state, { payload }) => {
      state.valuation = payload
    })
    builder.addCase(updateValuation.fulfilled, (state, { payload }) => {
      state.valuation = {...state.valuation, ...payload}
    })
    builder.addCase(getSectorPropertiesCoordinates.fulfilled, (state, { payload }) => {
      state.sectorPropertiesCoordinates = {
        requestState: 'done',
        sold: payload.filter(sale => sale.state === 'sold'),
        mandateSigned: payload.filter(sale => sale.state === 'awaiting_offer')
      }
    })
    builder.addCase(getBuyerAlerts.fulfilled, (state, { payload }) => {
      state.buyerAlerts = payload
    })
    builder.addCase(getSoldProperties.fulfilled, (state, { payload }) => {
      state.soldProperties = payload.results
    })
    builder.addCase(getEstimationMeeting.fulfilled, (state, { payload }) => {
      state.estimationMeeting = payload
    })
    builder.addCase(getAvailabaleSlots.fulfilled, (state, { payload }) => {
      state.availabaleSlots = payload
    })
    builder.addCase(createEstimationMeeting.fulfilled, (state, { payload }) => {
      state.estimationMeeting = payload
      toast.success(
        <div className='flex flex-col'>
          <p>Demande de rendez-vous envoyée</p>
          <p className='text-ih-indigo font semi-bold'>{longDateTime({ date: payload.appointment, capitalized: true })}</p>
        </div>
      , { duration: 5000 })
    })
  },
})

export const { resetOnlineEstimationReducer } = onlineEstimationsSlice.actions;

export default onlineEstimationsSlice.reducer;
