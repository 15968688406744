import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { smilingFace } from '../../../../../shared/assets/emojis';

import ConfirmationModal from './components/ConfirmationModal';
import DesktopSidebar from './components/DesktopSidebar';
import OfferSummaryCard from './components/OfferSummaryCard';
import FinancialPlanSection from './components/FinancialPlanSection';

import { FadeIn, Avatar, Emoji, Button } from 'hosman-material';

const OfferSummary = ({ offer, setStepInfos }) => {
  const [confirmationModal, setConfirmationModal] = useState(false);

  if (!offer.id) return null;

  const offerIsOngoing = offer.review_status == 'ongoing' && offer.sale.state == 'awaiting_offer'
  const offerIsAccepted = offer.review_status == 'accepted'

  useEffect(() => {
    if (offer.review_status !== 'ongoing') return;

    setStepInfos({ title: "Récapitulatif de l'offre", step: 1, total: 2 })
  }, []);

  return (
    <div data-testid='OfferSummary'>
      <FadeIn>
        <div className='md:grid grid-cols-6'>
          <DesktopSidebar offer={offer} />

          <div className='ih-container md:px-20 md:pt-10 col-span-4'>
            {offer.sale.expert &&
              <div className='flex gap-3'>
                <div className='w-max'>
                  <Avatar border imageSrc={offer.sale.expert?.user?.profile_pic_url} />
                </div>
                <div>
                  <div className='flex gap-2'>
                    <p className='h4 text-ih-neutral'>{offer.sale.expert.user.first_name}</p>
                    <p className='text-ih-blue'>{offer.sale.expert.user.phone_number.replace(/.{2}/g, '$& ')}</p>
                  </div>
                  <p className='subtitle text-ih-neutral-50'>
                    Retrouvez ci-dessous le récapitulatif des informations relatives à votre bien et à l'offre proposée par {offer.owner.first_name} {offer.owner.last_name[0]}.
                    <Emoji emojiSrc={smilingFace} width='w-4' />
                  </p>
                </div>
              </div>
            }

            <OfferSummaryCard offer={offer} />

            <div className='flex flex-col gap-5 my-10'>
              <p className='h4 text-ih-blue'>Informations supplémentaires</p>
              <p className='text-ih-indigo whitespace-pre-line'>{offer.comments}</p>
            </div>

            <FinancialPlanSection offer={offer} />

            <div className='flex flex-col md:flex-row gap-4 mt-10'>
              {offerIsOngoing &&
                <Button label='Accepter cette offre' onClick={() => setConfirmationModal(true)} />
              }
              {offerIsAccepted &&
                <a href={offer.signed_document.document_url} target='_blank' >
                  <Button label="Visualiser l'offre signée" />
                </a>
              }
              <Link to={`/ev/ventes/${offer.sale.id}/offres`}>
                <Button label='Revenir aux offres reçues' type={offerIsOngoing || offerIsAccepted ? 'tertiary' : 'primary'} />
              </Link>
            </div>
          </div>
        </div>
      </FadeIn>

      <ConfirmationModal isOpen={confirmationModal} closeModal={() => setConfirmationModal(false)} offer={offer} />
    </div>
  )
}

// PropTypes
OfferSummary.propTypes = {
  offer: PropTypes.object.isRequired,
  setStepInfos: PropTypes.func.isRequired
};

export default OfferSummary;
