import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generateLink } from '../../../../../../shared/utils/railsRoutes';

import ArticlesList from '../../../../../components/Blog/ArticlesList'
import { LoadingLayer, FadeIn, Button } from 'hosman-material';
import Partner from '../../../../../components/Marketing/components/Partner';

import { getArticles } from '../../../../../reducers/App/articlesSlice';

const Advices = () => {
  const { user } = useSelector((state) => state.user);
  const { articles: { articles }, sellerSales: { sale } } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getArticles({ per_page: 3, 'q[category_slug_eq]': 'conseils-de-vente' }));
  }, []);

  const partnersInfo = () => {
    switch (sale.state) {
      case 'awaiting_publication':
        return [{ slug: 'paperas', preTitle: 'Besoin de diagnostics?' }];
      case 'awaiting_offer':
        return [
          { slug: 'pretto', preTitle: "Besoin d'un crédit immobilier?" },
          { slug: 'Mon Chasseur Immo', preTitle: 'Vous cherchez le bien de vos rêves?' },
          { slug: 'hemea', preTitle: 'Vous souhaitez réaliser des travaux?' },
        ];
      case 'awaiting_sale_agreement':
        return [{ slug: 'paperas', preTitle: 'Vous souhaitez commander un Pré-Etat-Daté?' }];
      case 'awaiting_notarial_deed':
        return [
          { slug: 'papernest', preTitle: "Vous souhaitez transférer vos contrats d'énergie?" },
          { slug: 'nextories', preTitle: 'Vous déménagez?' },
        ];
    }
  }

  const partners = partnersInfo();
  const randomPartner = partners ? partners[Math.floor(Math.random()*partners.length)] : {};

  return (
    <div data-testid='Advices'>
      <LoadingLayer isReady={articles.length > 0}>
        <FadeIn>
          <div className='flex flex-col items-center justify-center gap-8'>
            <div className='md:w-1/2 h2 text-center text-ih-indigo mx-auto leading-10'>
              Tous nos conseils pour
              <span className='text-ih-blue'> préparer votre projet </span>
              de vente
            </div>

            <div className='flex flex-col md:flex-col-reverse gap-8'>
              <div className='text-center'>
                <a href={generateLink(RailsRoutes.blog_articles_path())} target='_blank' rel='noreferrer'>
                  <Button label='Découvrir tous nos conseils' />
                </a>
              </div>

              <div>
                <ArticlesList articles={articles} user={user} />
              </div>
            </div>

            <Partner slug={randomPartner.slug} preTitle={randomPartner.preTitle} />
          </div>
        </FadeIn>
      </LoadingLayer>
    </div>
  )
}

export default Advices;
