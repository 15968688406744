import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CustomRoutes from '../../../../../../../shared/utils/customRoutes';
import { validateStep } from '../../steps/Validators';
const PERSISTSTORE_MAX_DURATION = 60 * 60 * 1000; // One hour

import RestoreModal from './components/RestoreModal';
import { Modal, Button } from 'hosman-material';

import { updateStep, purgeEstimationData, updateFullStore } from '../../../../../../reducers/_Pages/Public/estimationFunnelSlice';

import { DemoStore } from './DemoStore';

const RoutesWrapper = ({ children }) => {
  const { storeCreationTime, currentStep, estimationData } = useSelector((state) => state.estimationFunnel);
  const [persitedStoreModalOpen, setPersitedStoreModalOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // Check validity of step at URL arrival
    const contract = validateStep({ stepNumber: currentStep.number - 1, data: estimationData });
    if (contract.valid) return;

    dispatch(updateStep({ stepNumber: 1 }))
  }, []);

  useEffect(() => {
    // Empty persisted store when persisted store is more than 1 hour old
    const oldEstimation = ((new Date) - new Date(storeCreationTime)) > PERSISTSTORE_MAX_DURATION

    if (oldEstimation) {
      setTimeout(() => {
        dispatch(purgeEstimationData())
      }, 500);
    }
  }, []);

  const minHeightMobile = () => {
    return currentStep.displayNavigation ? 'min-h-[calc(100vh-10rem)]' : 'min-h-[calc(100vh-7rem)]'
  }

  return (
    <div className='bg-hosman-gradient-4'>
      <div className={`flex flex-col justify-center ${minHeightMobile()} md:min-h-[calc(100vh-7rem)] overflow-scroll w-full py-10 px-6 md:px-0 md:w-2/5 mx-auto scrollbar-hide`}>
        {!persitedStoreModalOpen &&
          <CustomRoutes>
            {children}
          </CustomRoutes>
        }

        {process.env.NODE_ENV === 'development' &&
          <div className='fixed bottom-20 md:bottom-1 right-1 p-2 flex gap-3'>
            <button className='p-2 bg-ih-green rounded text-white' onClick={() => dispatch(updateFullStore(DemoStore))}>Full store</button>
            <button className='p-2 bg-ih-red rounded text-white' onClick={() => dispatch(purgeEstimationData())}>Empty store</button>
          </div>
        }
      </div>

      <RestoreModal persitedStoreModalOpen={persitedStoreModalOpen} setPersitedStoreModalOpen={setPersitedStoreModalOpen} />
    </div>
  )
}

export default RoutesWrapper;
