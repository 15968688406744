import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import requiredIf from '../../../../../../../../../shared/utils/propTypesCondition';

import { Header } from './components/Header';
import { DaysHeader } from './components/DaysHeader';
import { HorizontalLines } from './components/HorizontalLines';
import { VerticalLines } from './components/VerticalLines';
import { EventsGrid } from './components/EventsGrid';
import { Events } from './components/Events';
import { NewEvent } from './components/NewEvent';


export const Calendar = ({
  height = 'h-full',
  header,
  setParentReferenceDate,
  events = [],
  handleEventOpen,
  handleEventUpdate,
  handleEventDelete,
  editable = false,
  newEventModal,
  setNewEventModalOpen,
  setNewEventData
}) => {
  const lastMonday = new Date();
  lastMonday.setDate(lastMonday.getDate() - new Date().getDay() + 1)

  const [referenceDate, setReferenceDate] = useState(lastMonday)
  const containerRef = useRef(null)
  const containerNavRef = useRef(null)
  const containerOffsetRef = useRef(null)

  useEffect(() => {
    setParentReferenceDate(referenceDate);
  }, [referenceDate])

  useEffect(() => {
    if (!containerNavRef.current) return;

    // Set the container scroll position based on the current time.
    const currentMinute = (new Date().getHours() -7) * 60
    containerRef.current.scrollTop =
      ((containerRef.current.scrollHeight - containerNavRef.current.offsetHeight - containerOffsetRef.current.offsetHeight) *
        currentMinute) /
      1440 - 100
  }, [])

  return (
    <div className={height}>
      <div className='flex h-full flex-col'>
        <Header referenceDate={referenceDate} setReferenceDate={setReferenceDate} header={header} />
        <div ref={containerRef} className='isolate flex flex-auto flex-col overflow-auto bg-white md:scrollbar-hide'>
          <div className='flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full'>
            <DaysHeader containerNavRef={containerNavRef} referenceDate={referenceDate} setReferenceDate={setReferenceDate} />
            <div className='flex flex-auto h-full'>
              <div className='sticky left-0 z-10 w-20 flex-none bg-white ring-1 ring-gray-100' />
              <div className='grid flex-auto grid-cols-1 grid-rows-1'>
                <HorizontalLines containerOffsetRef={containerOffsetRef} />

                <VerticalLines />

                <EventsGrid referenceDate={referenceDate}>
                  <Events
                    events={events}
                    referenceDate={referenceDate}
                    handleEventOpen={handleEventOpen}
                    handleEventUpdate={handleEventUpdate}
                    handleEventDelete={handleEventDelete}
                  />
                </EventsGrid>

                {editable &&
                  <NewEvent referenceDate={referenceDate} newEventModal={newEventModal} setModalOpen={setNewEventModalOpen} setNewEventData={setNewEventData} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Calendar.propTypes = {
  height: PropTypes.string,
  header: PropTypes.node,
  setParentReferenceDate: PropTypes.func,
  events: PropTypes.array,
  handleEventOpen: PropTypes.func,
  handleEventUpdate: PropTypes.func,
  handleEventDelete: PropTypes.func,
  editable: PropTypes.bool,
  newEventModal: PropTypes.node,
  setNewEventModalOpen: requiredIf(PropTypes.func, (props) => props.editable),
  setNewEventData: requiredIf(PropTypes.func, (props) => props.editable)
};
