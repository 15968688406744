import client from '../../client';

const ESTIMATION_FOLDERS = {
  getEstimationFolder(id) {
    return client.get(`/estimation_folders/${id}`)
  },

  getComparableSales(estimationFolderId) {
    return client.get(`/estimation_folders/${estimationFolderId}/comparable_sales`)
  },

  getSimilarSalesScreenshots(estimationFolderId) {
    return client.get(`/estimation_folders/${estimationFolderId}/similar_sale_screenshots`)
  }
}

export default ESTIMATION_FOLDERS;
