import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import PHOTO_MEETINGS from '../../api/ressources/SellerApp/photoMeetings';

export const getPhotoMeetings = createAsyncThunk(
  '/photoMeetings',
  async (params) => {
    const response = await PHOTO_MEETINGS.getPhotoMeetings(params)
    return response.data
  }
);

const initialState = {
  photoMeetings: [],
  plannedPhotoMeeting: {},
  requestState: '',
};

export const photoMeetingsSlice = createSlice({
  name: 'photoMeetings',
  initialState,
  reducers: {
    resetPhotoMeetings(state) {
      state.photoMeetings = initialState.photoMeetings;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPhotoMeetings.pending, (state) => {
      state.photoMeetings = initialState.photoMeetings;
      state.plannedPhotoMeeting = initialState.plannedPhotoMeeting;
      state.requestState = 'pending'
    })
    builder.addCase(getPhotoMeetings.fulfilled, (state, { payload }) => {
      state.photoMeetings = payload.results;
      state.plannedPhotoMeeting = lastPlannedPhotoMeeting(payload.results);
      state.requestState = 'done'
    })
  },
})

const lastPlannedPhotoMeeting = (results) => {
  const planned = results.filter((photoMeeting) => photoMeeting.status === 'planned').pop();

  return planned;
};

export const { resetPhotoMeetings } = photoMeetingsSlice.actions;

export default photoMeetingsSlice.reducer;
