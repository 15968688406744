import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

import PROPERTIES from '../../../../../../api/ressources/SellerApp/properties';

import { FadeIn, Card, TextInput, NumberInput, PhoneNumberInput, TextAreaInput, Button } from 'hosman-material';

const PropertyInfosTab = () => {
  const { sale } = useSelector((state) => state.sellerSales);
  const [propertyState, setPropertyState] = useState({});
  const [condoState, setCondoState] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (!sale.id) return;

    PROPERTIES.getProperty(sale.property.id).then((response) => {
      const data = response.data;
      setPropertyState({
        works_property: data.works_property,
        taxe_fonciere: data.taxe_fonciere,
        quarterly_charges: data.quarterly_charges
      })
      setCondoState({
        syndic_name: data.condo?.syndic_name,
        phone_number: data.condo?.phone_number,
        phone_country_code: data.condo?.phone_country_code,
        email: data.condo?.email
      })
    });
  }, [sale]);

  const handleSubmit = () => {
    setFormErrors({});
    setIsSubmitting(true)

    PROPERTIES.updateProperty(sale.property.id, { ...propertyState, condo_attributes: condoState })
      .then(function () {
        toast.success('Les informations ont bien été enregistrées', { duration: 5000 })
        setIsSubmitting(false)
      })
      .catch(function (error) {
        const errors = error.response.data.error.message;
        setFormErrors({
          phone_number: errors['condo.phone_number']
        });
        setIsSubmitting(false)
      });
  }

  const handlePropertyInputChange = (e) => {
    const { name, value } = e.target
    setPropertyState((prev) => {
      return {...propertyState, [name]: value};
    });
  };

  const handlePropertyNumberInputChange = (e) => {
    const { name, value } = e.target
    setPropertyState((prev) => {
      return {...propertyState, [name]: parseInt(value.replace(/\s/g, ''))};
    });
  };

  const handleCondoInputChange = (e) => {
    const { name, value } = e.target
    setCondoState((prev) => {
      return {...condoState, [name]: value};
    });
  };

  return (
    <div className='md:w-3/4 mx-auto mt-6' data-testid='PropertyInfosTab'>
      <FadeIn>
        <Card>
          <div className='p-6 flex flex-col gap-6'>
            <div className='flex flex-col md:flex-row gap-4'>
              <TextInput label='Syndicat de copropriété' name='syndic_name' value={condoState.syndic_name} onChange={handleCondoInputChange} />
              <TextInput label='Mail du gestionnaire' name='email' value={condoState.email} onChange={handleCondoInputChange} />
              <PhoneNumberInput
                label='Téléphone du gestionnaire'
                onChangePhone={(val) => handleCondoInputChange({ target: { name: 'phone_number', value: val }})}
                onChangeCountryCode={(val) => handleCondoInputChange({ target: { name: 'phone_country_code', value: val }})}
                phoneValue={condoState.phone_number}
                countryCodeValue={condoState.phone_country_code}
                errors={formErrors}
              />
            </div>
            <div className='flex flex-col md:flex-row gap-4 md:w-2/3'>
              <NumberInput sign='€' label='Taxe foncière' name='taxe_fonciere' value={propertyState.taxe_fonciere} onChange={handlePropertyNumberInputChange} />
              <NumberInput sign='€' label='Charges de copropriété' name='quarterly_charges' value={propertyState.quarterly_charges} onChange={handlePropertyNumberInputChange} />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <TextAreaInput label='Travaux effectués dans votre bien' rows={5} name='works_property' value={propertyState.works_property} onChange={handlePropertyInputChange} />
              <div className='flex mt-6 md:mt-0 flex-col justify-end items-end'>
                <Button label='Enregistrer les informations' onClick={handleSubmit} isLoading={isSubmitting} />
              </div>
            </div>
          </div>
        </Card>
      </FadeIn>
    </div>
  )
}

export default PropertyInfosTab;
