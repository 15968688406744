import React from 'react';
import { useSelector } from 'react-redux';

import { jeReveDuneMaisonLogo,
         monChasseurImmoLogo,
         perleRareLogo} from '../../../../../../../../../../assets/logos';
import { MarketingCard } from './shared/MarketingCard';

const Partners = () => {
  const { sale: { region } } = useSelector((state) => state.sellerSales);

  const logos = [
    { src: jeReveDuneMaisonLogo, altText: 'jeReveDuneMaison'},
    { src: monChasseurImmoLogo, altText: 'monChasseurImmoLogo' },
    { src: perleRareLogo, altText: 'perleRareLogo' }
  ]

  const displayPartnersNumber = () => {
    switch (region.name_raw) {
      case 'Bordeaux':
        return 13
      case 'Lyon':
        return 18
      case 'Marseille':
        return 26
      case 'Nantes':
        return 19
      default:
        return 96
    }
  }

  return(
    <MarketingCard
      number={displayPartnersNumber()}
      title='Partenaires professionnels sollicités'
      subtitle='Chasseurs & investisseurs immobilier'
      logos={logos}
    />
  )
};

export default Partners
