import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { HosmanLogo, S_CircleCheck } from 'hosman-material';
import { generateLink } from '../../../../../../../../shared/utils/railsRoutes';

const LeftBanner = () => {
  const pros = [
    "Une première fourchette d'estimation de votre bien",
    "Des informations précises sur l'immobilier dans votre quartier",
    'La possibilité de faire estimer votre bien par un expert Hosman'
  ]

  return (
    <div className='bg-hosman-gradient-1'>
      <div className='h-screen hidden md:flex flex-col gap-6 justify-center items-center w-3/4 mx-auto'>
        <a href={generateLink(RailsRoutes.root_path())} className='w-max hidden md:block'>
          <HosmanLogo width={160} />
        </a>
        <p className='h2 text-center'>Sur <span className='text-ih-blue'>votre espace en ligne Hosman</span> vous pourrez retrouver</p>

        {pros.map(pro => {
          return (
            <div className='bg-white p-2 rounded-xl flex gap-2 items-center mx-auto' key={pro}>
              <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-green' />
              <p>{pro}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default LeftBanner;
