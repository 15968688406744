import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import PARTNERS from '../../api/ressources/App/partners';

export const getPartners = createAsyncThunk(
  '/partners',
  async (params) => {
    const response = await PARTNERS.getPartners(params)
    return response.data
  }
);

const initialState = {
  partners: []
};

export const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getPartners.fulfilled, (state, { payload }) => {
      state.partners = payload.results;
    })
  },
})

export default partnersSlice.reducer;
