import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generateLink } from '../../../../../shared/utils/railsRoutes';
import { D_Handshake } from 'hosman-material';

import PageTitle from '../../../../components/PageTitle/PageTitle';
import PartnersList from './components/PartnersList';
import { LoadingLayer, Button, FadeIn } from 'hosman-material';

import { getPartners } from '../../../../reducers/App/partnersSlice';

const PartnerServices = () => {
  const { partners } = useSelector((state) => state.partners);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPartners({ 'q[category_slug_eq]': 'projet_achat', 'per_page': 3 }));
  }, []);

  return (
    <div data-testid='PartnerServices'>
      <PageTitle icon={D_Handshake} title='Services partenaires' />

      <LoadingLayer isReady={partners.length > 0}>
        <FadeIn>
          <div className='md:w-2/3 h2 text-center text-ih-indigo mx-auto mb-8'>
            Hosman s'associe afin de
            <span className='text-ih-blue'> vous accompagner au mieux </span>
            tout au long de votre projet.
          </div>

          <div className='my-8'>
            <PartnersList partners={partners} />
          </div>

          <div className='text-center'>
            <a href={generateLink(RailsRoutes.partner_categories_path())} target='_blank'>
              <Button label='Découvrir tous nos partenaires' />
            </a>
          </div>
        </FadeIn>
      </LoadingLayer>
    </div>
  );
}

export default PartnerServices;
