import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as dayjs from 'dayjs';
const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

export const EventsGrid = ({ referenceDate, children }) => {
  const [nowLine, setNowLine] = useState()

  useEffect(() => {
    calculateNowLinePosition()
    const interval = setInterval(() => {
      calculateNowLinePosition()
    }, 1000);

    return () => clearInterval(interval);
  }, [])

  const calculateNowLinePosition = () => {
    const span = (new Date().getHours()-7) * 4 + 1 + Math.floor(new Date().getMinutes() / 15)

    setNowLine(span);
  }

  const setGreenBg = (i) => {
    const ref = new Date(referenceDate)
    ref.setDate(ref.getDate() + i)
    if (dayjs(new Date()).isBetween(ref.setUTCHours(7,0,0,0), ref.setUTCHours(21,0,0,0))) {
      const span = (new Date().getHours()-7) * 4 + 2 + Math.floor(new Date().getMinutes() / 15)
      return { col: `col-start-${i+1}`, grid: `${span} / span ${57 - span}` };
    } else if (ref.getTime() < new Date().getTime()) {
      return { col: 'hidden', grid: '0 / span 0' };
    } else {
      return { col: `col-start-${i+1}`, grid: '2 / span 55' }
    }
  }

  const setColStart = (date) => {
    const dateBeginningOfDay = new Date(date).setUTCHours(0,0,0,0)
    const referenceDateBeginningOfDay = new Date(referenceDate).setUTCHours(0,0,0,0)
    const diffDays = Math.floor((dateBeginningOfDay - referenceDateBeginningOfDay) / 86400000);

    switch (diffDays) {
      case 0: return 'col-start-1';
      case 1: return 'col-start-2';
      case 2: return 'col-start-3';
      case 3: return 'col-start-4';
      case 4: return 'col-start-5';
      case 5: return 'col-start-6';
      case 6: return 'col-start-7';
      default: return 'hidden'
    }
  }

  return (
    <ol
      className='col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7'
      style={{ gridTemplateRows: '1.75rem repeat(56, minmax(0, 1fr)) auto' }}
    >

      {/* Green backround */}
      {Array.from(new Array(7), (x,i) => i).map((i) => {
        return (
          <Fragment key={i}>
            <li key={`day ${i}`} className={`${setGreenBg(i).col} relative mt-px flex bg-ih-green opacity-5`} style={{ gridRow: setGreenBg(i).grid }}></li>
          </Fragment>
        )
      })}

      {/* Now blue line */}
      <li className={`${setColStart(new Date())} relative mt-px flex border-b border-ih-blue z-40`} style={{ gridRow: `${nowLine} / span 1` }}>
        <div className='-left-1 -bottom-[4px] absolute w-2 h-2 rounded-full bg-ih-blue'></div>
      </li>

      {/* Events as children */}
      {children}
    </ol>
  )
}

EventsGrid.propTypes = {
  referenceDate: PropTypes.object,
  children: PropTypes.node
};
