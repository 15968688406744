import React, { useState, useEffect } from 'react';

const MobileHeader = ({ currentStep, allSteps }) => {
  const [progress, setProgress] = useState()
  const totalSteps = allSteps.filter(step => step.displayNavigation).length;

  useEffect(() => {
    setProgress(((currentStep.number / totalSteps) - 0.1) * 100);
  }, [currentStep]);

  return (
    <div className='relative w-full lg:hidden'>
      <div className='flex justify-center items-center gap-3 h-16'>
        <p className='h3 text-ih-blue'>{currentStep.number}/{totalSteps}</p>
        <p className='text-ih-indigo-60'>{currentStep.name}</p>
      </div>
      <div className='relative h-1 w-full bg-gray-200'>
        <div
          className='absolute top-0 left-0 h-full bg-ih-blue transition-all duration-300 ease-in'
          style={{ width: `${progress}%` }}
        />
        <div
          className='absolute top-1/2 transform -translate-y-1/2 h-4 w-4 bg-ih-blue rounded-full transition-all duration-300 ease-in'
          style={{ left: `${progress}%` }}
        />
      </div>
    </div>
  )
}

export default MobileHeader;
