import React from 'react';
import { useSelector } from 'react-redux';

import SaleFolder from '../../../../../../components/Sale/SaleFolder';
import { LoadingLayer, Emoji } from 'hosman-material';
import { folder } from '../../../../../../../shared/assets/emojis';

const ArchievesTab = () => {
  const { documents, requestState } = useSelector((state) => state.sellerDocuments);

  return (
    <div data-testid='ArchievesTab'>
      <LoadingLayer isReady={requestState == 'done'}>
        <div className='h2 text-ih-indigo flex justify-around mt-10'>
          <div className='flex items-center gap-4'>
            <Emoji emojiSrc={folder} width='w-8' />
            <p>Mon mandat et avenants</p>
          </div>
        </div>

        <SaleFolder documents={documents.filter(document => document.type.category_slug === 'acquisition')} />
      </LoadingLayer>
    </div>
  );
};

export default ArchievesTab;
