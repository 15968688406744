import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { smilingFace } from '../../../../shared/assets/emojis'
import { D_House } from 'hosman-material';

import PageTitle from '../../../components/PageTitle/PageTitle';
import SaleSummary from './components/SaleSummary';

import { LoadingLayer, FadeIn, Emoji } from 'hosman-material';
import { greeting } from "../../../../shared/utils/helpers/hosmanVars";

const SalesIndex = ({ setNavigation }) => {
  const { user } = useSelector((state) => state.user);
  const { sales, requestState } = useSelector((state) => state.sellerSales);
  const [navigationState, setNavigationState] = useState([{ name: 'Mes ventes', path: 'ventes', icon: D_House }]);
  let navigate = useNavigate();

  useEffect(() => {
    setNavigationState([{ name: 'Mes ventes', path: 'ventes', icon: D_House, count: sales.length }]);
  }, [sales]);

  useEffect(() => {
    setNavigation(navigationState);
  }, [navigationState]);

  useEffect(() => {
    if (requestState == 'done' && sales.length == 1 && sales[0].state != 'awaiting_term_signature') navigate(`${sales[0].id}`);
  }, [sales]);

  return (
    <div data-testid='SalesIndex'>
      <PageTitle icon={navigationState[0].icon} title={navigationState[0].name} />

      <LoadingLayer isReady={requestState === 'done'}>
        <FadeIn>
          <div className='w-full' data-testid='sales-list'>
            <p className='h2 mb-8 text-ih-indigo text-center md:text-left'>{`${greeting(user)}, voici vos `} <span className='text-ih-blue'>projets de vente</span> <span className='ml-2'><Emoji emojiSrc={smilingFace} width='w-7' /></span></p>
            <div className='md:flex md:gap-6 md:flex-wrap'>
              {sales.map((sale) => (
                <div key={sale.id} className='relative cursor-pointer rounded-3xl mb-6'>
                  {sale.state === 'awaiting_term_signature' &&
                    <a href={sale.onboarding_url}>
                      <div className='md:w-80 mx-auto'>
                        <SaleSummary sale={sale} />
                      </div>
                    </a>
                  }

                  {sale.state !== 'awaiting_term_signature' &&
                    <Link to={`${sale.id}`}>
                      <div className='md:w-80 mx-auto'>
                        <SaleSummary sale={sale} />
                      </div>
                    </Link>
                  }
                </div>
              ))}
            </div>
          </div>
        </FadeIn>
      </LoadingLayer>
    </div>
  );
};

// PropTypes
SalesIndex.propTypes = {
  setNavigation: PropTypes.func
};

export default SalesIndex;
