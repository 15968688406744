import client from '../../client';

const PARTNERS = {
  getPartners(params) {
    return client.get('/partners', params)
  },

  getPartner(slug) {
    return client.get(`/partners/${slug}`)
  },
}

export default PARTNERS;
