import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { longDate } from '../../../../../../../../shared/utils/formattedDates';

import { Event } from '../Event';

export const MobileEventsFeed = ({ events, handleEventOpen, handleEventUpdate, handleEventDelete }) => {
  const [groupedEvents, setSortedEvents] = useState([]);

  useEffect(() => {
    let result = {};
    events.map((event) => {
      let day = `${longDate({ date: event.start, capitalized: true })} ${new Date(event.start).getFullYear()}`;
      result[day] = result[day] ?? [];
      result[day].push(event);
    });
    setSortedEvents(result)
  }, [events]);

  useEffect(() => {
    if (document.getElementById('event-separator')) return;

    const previousEvents = events.filter((event) => new Date().getTime() > new Date(event.start).getTime());
    if (previousEvents.length < 1) return;

    const lastPreviousEvent = previousEvents[previousEvents.length -1]
    const element = document.getElementById(lastPreviousEvent.id)
    if (element) {
      addSeparator(element)
    }
  }, [groupedEvents])

  const handleClick = (event) => {
    if (event.clickable) handleEventOpen(event);
  }

  return (
    <div className='md:hidden'>
      {Object.entries(groupedEvents).map(([day, events]) => {
        return (
          <div key={day}>
            <p className='h3 text-ih-indigo my-3'>{day}</p>
            <div className='flex flex-col gap-2'>
              {events.map((event) => {
                return (
                  <div key={event.id} id={event.id} onClick={() => handleClick(event)}>
                    <Event event={event} handleEventUpdate={handleEventUpdate} handleEventDelete={handleEventDelete} />
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  );
};

function addSeparator(element) {
  let separator = document.createElement('div');
  let buble = document.createElement('div');

  separator.classList.add('relative')
  separator.classList.add('mt-4')
  separator.classList.add('border-t')
  separator.classList.add('border-ih-blue')
  separator.setAttribute('id','event-separator')
  buble.classList.add('-left-1')
  buble.classList.add('-top-1')
  buble.classList.add('absolute')
  buble.classList.add('w-2')
  buble.classList.add('h-2')
  buble.classList.add('rounded-full')
  buble.classList.add('bg-ih-blue')

  element.parentElement.parentElement.insertBefore(separator, element.parentElement.nextSibling);
  separator.insertAdjacentElement('afterbegin', buble)
}

// PropTypes
MobileEventsFeed.propTypes = {
  events: PropTypes.array,
  handleEventOpen: PropTypes.func,
  handleEventUpdate: PropTypes.func,
  handleEventDelete: PropTypes.func
};
