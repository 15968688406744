import React from 'react';
import { InfoIcon } from 'hosman-material'
import PropTypes from 'prop-types';

export const ButtonInfo = ({ handleClick }) => {
  return(
    <button
      type='button'
      onClick={handleClick}
      className='text-sm flex items-center gap-2 bg-ih-purple-10 hover:bg-ih-purple-20 border-ih-purple-50 hover:border-ih-purple-70 p-3 border rounded-2xl transition-all'
    >

      <InfoIcon bgColor='bg-ih-indigo-30' iconColor='text-ih-indigo-80'/>

      <div className='lg:whitespace-nowrap font-medium'>Comment ajouter une indisponibilité ?</div>
    </button>
  )
};

ButtonInfo.propTypes = {
  handleClick: PropTypes.func,
  disabled: PropTypes.bool
}
