import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Route,
  Navigate,
  useNavigate,
  useLocation
} from 'react-router-dom';
import CustomRoutes from '../../../../../../shared/utils/customRoutes';

import Preview from '../../components/Preview';
import DocumentsTab from './views/DocumentsTab';
import ArchievesTab from './views/ArchievesTab';
import PropertyInfosTab from './views/PropertyInfosTab';
import { Tabs, Tab } from 'hosman-material';

const SaleFolder = () => {
  const { sale } = useSelector((state) => state.sellerSales);
  let navigate = useNavigate();
  let location = useLocation();

  const items = [
    { name: 'Mes documents', path: 'documents' },
    { name: 'Informations sur le bien', path: 'infos-du-bien' }
  ]
  sale.mandateSigned && items.splice(1, 0, { name: 'Mon mandat', path: 'archives' })
  const [selectedItem, setSelectedItem] = useState(items[0])

  useEffect(() => {
    const matchedItem = items.find((item) => location.pathname.includes(item.path));
    setSelectedItem(matchedItem || items[0])
  }, []);

  const handleSelect = (item) => {
    setSelectedItem(item);
    navigate(item.path);
  }

  return (
    <div data-testid='SaleFolder'>
      <div className='flex justify-center'>
        <Tabs>
          {items.map((item) => {
            return (
              <Tab
                key={item.name}
                item={item}
                selectedItem={selectedItem}
                handleSelect={handleSelect}
              />
            );
          })}
        </Tabs>
      </div>

      <CustomRoutes>
        <Route path='/*' element={<Navigate to={items[0].path} />} />
        <Route path='/documents' element={<DocumentsTab />} />
        {sale.mandateSigned && <Route path='/archives' element={<ArchievesTab />} />}
        <Route path='/infos-du-bien' element={<PropertyInfosTab />} />
      </CustomRoutes>
    </div>
  )
}

export default SaleFolder;
