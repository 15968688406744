import React from 'react';
import { useSelector } from 'react-redux';

import { MarketingCard } from './shared/MarketingCard';
export const PotentialBuyers = () => {
  const { publicationData: { potential_buyers_solicitations_count, lead_initials } } = useSelector((state) => state.sellerSales);

  if (potential_buyers_solicitations_count < 100) return null

  return(
    <MarketingCard
      number={potential_buyers_solicitations_count}
      title='Acheteurs potentiels de notre base ont reçu votre annonce'
      subtitle='Acheteurs ayant renseignés des critères de recherche similaires à votre bien'
      leadInitials={lead_initials}
    />
  )
};

export default PotentialBuyers
