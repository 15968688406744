import React from 'react';
import { useSelector } from 'react-redux';
import { differenceInDays } from 'date-fns';
import { D_ClockThree } from 'hosman-material';
import SaleKeyNumbersCard from './shared/SaleKeyNumbersCard';

const Duration = () => {
  const { sale } = useSelector((state) => state.sellerSales);

  const daysDifference = differenceInDays(new Date(), new Date(sale.timeline.first_awaiting_offer_at))

  return(
    <SaleKeyNumbersCard
      thresholdWarning={false}
      icon={D_ClockThree}
      title='Durée de commercialisation'
      numbersColor='text-ih-blue'
      subtitle={`${daysDifference} jours`}
      />
  )
};

export default Duration
