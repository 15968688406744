import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { S_CalendarDays, S_Message, S_FileCircleCheck } from 'hosman-material';

import { eventDate } from '../../../../../../../../shared/utils/formattedDates';

import { Modal, Label, Separator } from 'hosman-material';

export const VisitModal = ({ visitId, closeModal }) => {
  const { visits } = useSelector((state) => state.sellerVisits);
  const visit = visits.filter((visit) => visit.id === visitId)[0]
  let { saleId } = useParams();

  if (visit === undefined) return null;

  return (
    <Modal isOpen={visit !== null} closeModal={closeModal}>
      <div className='p-6 md:w-96 flex flex-col gap-3'>
        <p className='h4 text-ih-indigo'>{visit.user.first_name} {visit.user.last_name?.[0]}.</p>

        <div className='flex items-center gap-2 text-ih-purple-50'>
          <FontAwesomeIcon icon={S_CalendarDays} />
          <p>{eventDate(visit.start_time, visit.end_time)}</p>
        </div>

        {visit.canceled &&
          <div className='grid grid-cols-2'>
            <div><Label color='bg-ih-indigo'>Visite annulée</Label></div>
            <p className='text-ih-indigo text-sm border rounded-xl p-2'>{visit.state === 'rescheduled' ? 'Visite replanifiée' : visit.cancel_reason_text}</p>
          </div>
        }

        {!visit.canceled &&
          <div>
            <Separator margins='my-2' />

            <div className='flex gap-2'>
              <FontAwesomeIcon icon={visit.visit_report ? S_Message : S_FileCircleCheck} className='text-ih-purple-50 mt-1' />
              {visit.visit_report &&
                <div className='flex flex-col gap-3'>
                  <p className='text-indigo-80 line-clamp-5'>{visit.visit_report.context}</p>
                  <Link to={`/ev/ventes/${saleId}/comptes-rendus`}>
                    <p className='text-ih-blue font-semibold cursor-pointer'>Lire la suite...</p>
                  </Link>
                </div>
              }
              {!visit.visit_report &&
                <p className='text-indigo-80'>Le compte rendu de visite sera disponible après la visite.</p>
              }
            </div>
          </div>
        }
      </div>
    </Modal>
  );
};
