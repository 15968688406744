import React from 'react';
import PropTypes from 'prop-types';
import { generateLink } from '../../../../../../../shared/utils/railsRoutes'

import { IconCard } from 'hosman-material';
import { S_Bell } from 'hosman-material';
import { indexPointingRight, starStruck } from '../../../../../../../shared/assets/emojis';

const CtaBanner = ({ offer }) => {
  return (
    <div className='my-10' data-testid='CtaBanner'>
      <div className='mb-6'>
        {(offer === undefined || offer?.review_status === 'accepted') &&
          <IconCard
            emojiSrc={indexPointingRight}
            linkLabel='Découvrir la valeur de mon bien'
            link={'/estimation'}
          >
            Vous souhaitez <span className='text-ih-blue'>vendre pour acheter</span> un bien ? Découvrez la valeur de votre bien en quelques clics seulement !
          </IconCard>
        }

        {offer && offer?.review_status !== 'accepted' &&
          <IconCard
            emojiSrc={starStruck}
            linkIcon={S_Bell}
            linkLabel='Créer une alerte achat'
            link={generateLink(RailsRoutes.new_buyer_alert_path())}
          >
            Envie de recevoir en avant-première des <span className='text-ih-blue'>biens correspondants à votre recherche ?</span> Créez une alerte achat !
          </IconCard>
        }
      </div>
    </div>
  )
}

export default CtaBanner;

// PropTypes
CtaBanner.propTypes = {
  offer: PropTypes.object
};
