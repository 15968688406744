import React from 'react';

import { ArticleCard } from 'hosman-material';

const PartnersList = ({ partners }) => {
  return (
    <div className='grid grid-cols-1 md:flex md:justify-center md:flex-wrap md:gap-8' data-testid='partner-cards'>
      {partners.map((partner) => (
        <div key={partner.id} className='mb-10 xl:mb-0'>
          <ArticleCard
            href={partner.link}
            imageSrc={partner.card_picture_url}
            labelContent={partner.category.title}
            labelColor='bg-ih-indigo'
            title={partner.partner_name}
            titleColor='text-ih-blue-80'
          >
            {partner.description}
          </ArticleCard>
        </div>
      ))}
    </div>
  )
}

export default PartnersList;
