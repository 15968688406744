import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import { camera } from '../../../../../../../../shared/assets/emojis';

import ExplainCard from './components/ExplainCard';
import ContactForm from './components/ContactForm';
import { Modal, LoadingCircle, SlotsSelector, Emoji } from 'hosman-material';

import { getSale } from '../../../../../../../reducers/SellerApp/salesSlice';
import PHOTO_MEETINGS from '../../../../../../../api/ressources/SellerApp/photoMeetings';

const PhotoMeetingModal = ({ sale, closeModal }) => {
  const [slotsDays, setSlotsDays] = useState({});
  const [selectedDaySlots, setSelectedDaySlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [daysDisplayed, setDaysDisplayed] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    PHOTO_MEETINGS.getSlots(sale.id).then((response) => {
      const slots = response.data.results;
      const slotsObjects = slots.map(function(slot) {
        const date = new Date(slot)
        return {
          day: `${date.toLocaleDateString('fr-FR', { weekday: 'long' })} ${date.getUTCDate()}`,
          slot: slot
        };
      });

      const slotsByDay = slotsObjects.reduce((group, slot) => {
        const { day } = slot;
        group[day] = group[day] ?? [];
        group[day].push(slot);
        return group;
      }, {});

      setSlotsDays(slotsByDay);
      setSelectedDaySlots(Object.values(slotsByDay)[0]);
    });
  }, []);

  const handleDaysDisplayed = () => {
    const number = window.innerWidth > 460 ? 4 : 2

    setDaysDisplayed(number)
  }

  useEffect(() => {
    window.addEventListener('resize', handleDaysDisplayed);
    handleDaysDisplayed();
  }, []);

  const handleSubmit = (body) => {
    setFormErrors({});
    setIsSubmitting(true)
    PHOTO_MEETINGS.createPhotoMeeting(sale.id, { ...body, start_date: selectedSlot })
      .then(function (response) {
        toast.success('Le rendez-vous photo à bien été enregistré', { duration: 5000 })
        dispatch(getSale(sale.id));
        closeModal();
      })
      .catch(function (error) {
        setIsSubmitting(false)
        setFormErrors(error.response.data.error.message);
      });
  }

  return (
    <Modal isOpen closeModal={() => closeModal()} width='w-full xl:w-[1200px]'>
      <div className='px-6 xl:px-12 py-8 text-ih-indigo'>
        <div className='flex'>
          <div className='flex flex-col xl:flex-row-reverse align-items gap-4 mb-5 mx-auto'>
            <div className='flex justify-around'>
              <Emoji emojiSrc={camera} width='w-9' />
            </div>
            <div className='flex text-center'>
              <p className='h2'>Réservez votre reportage photo</p>
            </div>
          </div>
        </div>

        <div className='grid grid-cols-1 xl:grid-cols-5 gap-4'>
          <div className='xl:col-span-2 mx-auto'>
            {selectedDaySlots.length < 1 && <div className='w-full flex justify-around'><LoadingCircle /></div>}
            {selectedDaySlots.length > 0 &&
              <SlotsSelector
                slotsDays={slotsDays}
                selectedDaySlots={selectedDaySlots}
                setSelectedDaySlots={setSelectedDaySlots}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
                daysDisplayed={daysDisplayed}
              />
            }
          </div>

          <ExplainCard />
        </div>

        <ContactForm
          selectedSlot={selectedSlot}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          formErrors={formErrors}
        />
      </div>
    </Modal>
  )
}

export default PhotoMeetingModal;

// PropTypes
PhotoMeetingModal.propTypes = {
  sale: PropTypes.object.isRequired,
  closeModal: PropTypes.func
};
