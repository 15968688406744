import React from 'react';
import { Outlet } from 'react-router-dom';
import { generateLink } from '../../shared/utils/railsRoutes';

const AuthenticatedRoutes = ({ user, children }) => {
  switch (user.authState) {
    case 'done':
      if (user.role_name !== 'client') {
        location = generateLink(RailsRoutes.root_path())
      } else {
        document.title = 'Espace Client'
        return <Outlet />
      }
    case 'none':
      location = generateLink(RailsRoutes.new_user_session_path());
      return null;
    default:
      return null;
  }
};

export default AuthenticatedRoutes;
