import React from 'react';
import PropTypes from 'prop-types';
import { longDateTime } from '../../../../../../shared/utils/formattedDates';

import OfferState from '../../../../../components/Offer/OfferState';
import { Box, Label } from 'hosman-material';

const OfferSummaryCard = ({ offer }) => {
  return (
    <div className='mt-12 bg-white flex flex-col md:flex-row gap-4 justify-between items-center p-4 w-full rounded-2xl border border-ih-purple-20'>
      <div className='flex gap-4'>
        <Box bgColor='bg-ih-blue'>
          <p className='h4 text-white'>{offer.owner.first_name[0]}{offer.owner.last_name[0]}</p>
        </Box>
        <div>
          <p className='h4 text-ih-neutral'>{offer.owner.first_name} {offer.owner.last_name}</p>
          <p className='text-ih-indigo-60 text-xs'>Remise le {longDateTime({ date: offer.timeline.review_ongoing_at })}</p>
        </div>
      </div>
      <div className='flex items-center'>
        <OfferState status={offer.review_status} />
      </div>
      <Label color='bg-ih-indigo'><p className='h4'>{offer.amount.toLocaleString()} €</p></Label>
    </div>
  )
}

// PropTypes
OfferSummaryCard.propTypes = {
  offer: PropTypes.object.isRequired
}

export default OfferSummaryCard;
