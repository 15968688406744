import React from 'react';
import { partyingFace } from '../../../shared/assets/emojis';
import { generateLink } from '../../../shared/utils/railsRoutes';
import {useLocation} from 'react-router-dom';

import { expertsShakingHands } from '../../../shared/assets/images';
import OfferPriceCards from './components/OfferPriceCards';

import { Button, Card, Emoji } from 'hosman-material';

const SponsorshipBanner = () => {
  const title = () => {
    return (
      <>
        <Emoji emojiSrc={partyingFace} width='w-8' />

        <div className='h2'>Recommandez Hosman à un proche : <span className='text-ih-blue'>gagnez jusqu'à 600€ !</span></div>
      </>
    )
  }

  let location = useLocation();

  return (
    <div className='flex justify-center' data-testid='SponsorshipBanner'>
      <div className='w-full md:w-11/12'>
        <Card>
          <div className='flex flex-col items-center justify-center md:flex-row gap-8 px-6 md:px-10 py-10 border-2 border-ih-blue rounded-3xl bg-ih-blue-5'>
            <div className='flex flex-col justify-center items-center gap-3 text-center md:hidden'>
              {title()}
            </div>

            <img src={expertsShakingHands}
                alt='expert shaking hands'
                className='rounded-full w-64 h-64 object-cover overflow-hidden shadow-ih-blue-moon flex-shrink-0'
            />

            <div className='flex flex-col justify-center items-center gap-5 text-center text-ih-indigo'>
              <div className='hidden md:flex flex-col justify-center items-center gap-3'>
                {title()}
              </div>

              <div className='flex flex-col gap-8'>
                <div className='flex justify-center flex-wrap gap-7'>
                  <OfferPriceCards bgColor='bg-white' />
                </div>

                <div className='text-center w-fit mx-auto'>
                  <a href={generateLink(RailsRoutes.parrainage_new_path({ utm_source: 'espacehosman', utm_medium: location.pathname.includes('/ea/') ? 'acheteur' : 'vendeur', utm_campaign: 'parrainage' }))} target='_blank'>
                    <Button label="Découvrir l'offre parrainage" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
};

export default SponsorshipBanner;
