import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { smilingFace } from '../../../../../../../shared/assets/emojis'
import { D_House } from 'hosman-material';

import SponsorshipBanner from '../../../../../../components/Marketing/SponsorshipBanner';
import PageTitle from '../../../../../../components/PageTitle/PageTitle';
import CtaBanner from '../components/CtaBanner';
import OfferSummary from '../components/OfferSummary';

import { LoadingLayer, FadeIn, Emoji, Separator } from 'hosman-material';


const OffersIndex = () => {
  const { indexOffers, requestState } = useSelector((state) => state.buyerOffers);
  const { user } = useSelector((state) => state.user);
  let navigate = useNavigate();

  // If user has no offer, navigate to seller app
  // If user has only one offer, navigate to offerShow
  useEffect(() => {
    if (requestState == 'done' && indexOffers.length === 0) navigate(`/ev`);
    if (requestState == 'done' && indexOffers.length === 1) navigate(`${indexOffers[0].id}`);
  }, [indexOffers]);

  return (
    <div data-testid='OffersIndex'>
      <PageTitle icon={D_House} title='Accueil' />

      <LoadingLayer isReady={requestState === 'done'}>
        <FadeIn>
          <div className='w-full' data-testid='offers-list'>
            <p className='h2 mb-8 text-ih-indigo text-center md:text-left'>{`${user.first_name}, voici `} <span className='text-ih-blue'>vos offres</span> <span className='ml-2'><Emoji emojiSrc={smilingFace} width='w-7' /></span></p>
            <div className='md:flex md:gap-6 md:flex-wrap'>
              {indexOffers.map((offer) => (
                <div key={offer.id} className='relative cursor-pointer rounded-3xl mb-6'>
                  <Link to={`${offer.id}`}>
                    <div className='md:w-80 mx-auto'>
                      <OfferSummary offer={offer} />
                    </div>
                  </Link>
                </div>
              ))}
            </div>

            <CtaBanner />

            <Separator />

            <SponsorshipBanner />
          </div>
        </FadeIn>
      </LoadingLayer>
    </div>
  )
}

export default OffersIndex;
