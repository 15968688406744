import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { generateLink } from '../../../../../../../../shared/utils/railsRoutes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { smilingFace } from '../../../../../../../../shared/assets/emojis'

import { TextInput, Button, Emoji, Label, HosmanLogo } from 'hosman-material';
import { S_CircleCheck, S_EyeSlash, S_Eye } from 'hosman-material';

import { getUser } from '../../../../../../../reducers/App/userSlice';
import USERS from '../../../../../../../../shared/apiPublic/ressources/users';
import { greeting } from "../../../../../../../../shared/utils/helpers/hosmanVars";

const LoginForm = ({ baseEstimationData }) => {
  const [user, setUser] = useState({ email: baseEstimationData.owner.email, password: '', passwordConfirmation: '' })
  const [errors, setErrors] = useState({})
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [showPasswords, setShowPasswords] = useState({})
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const minCharPassword = user.password.length >= 8
  const newUser = baseEstimationData.owner.last_sign_in_at == null

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;

    setUser((prev) => ({ ...prev, [name]: value }))
  }

  const validatePasswords = () => {
    const { password, passwordConfirmation } = user;

    if (!minCharPassword) return setErrors({ password: ['*Le mot de passe doit contenir au minimum 8 caractères'] })
    if (password !== passwordConfirmation) return setErrors({ passwordConfirmation: ['*Les mots de passe ne sont pas identiques'] })

    return true;
  }

  const signUserIn = async() => {
    const passwordIsValid = !newUser || validatePasswords()

    if (!passwordIsValid) return;
    let response;

    try {
      setIsSubmitted(true)
      response = await USERS.signIn({ email: user.email, password: user.password })

      if (response.data.error) {
        setIsSubmitted(false)
        setErrors({ password: [`*${response.data.error}`] })
      }
    } catch (e) {
      console.log(e);
    } finally {
      if (response.data.sign_in) {
        localStorage.setItem('token', response.data.token);
        dispatch(getUser());
        navigate(`/ev`);
      }
    }
  }

  return (
    <div className='bg-white h-screen'>
      <div className='pt-20 flex flex-col gap-8 justify-center items-center md:w-2/3 mx-auto px-5'>
        <div className='flex flex-col gap-3'>
          <a href={generateLink(RailsRoutes.root_path())} className='md:hidden justify-center mb-3'>
            <HosmanLogo width={160} />
          </a>
          <div className='flex items-center justify-center gap-3'>
            <p className='h2'>Bienvenue <span className='text-ih-blue'>{greeting(baseEstimationData.owner)}</span></p>
            <Emoji emojiSrc={smilingFace} />
          </div>

          {!newUser && <p className='text-center'>Votre estimation est prête ! Visualisez-là sur votre espace en ligne en vous connectant à votre compte</p>}
          {newUser && <p className='text-center'>Votre estimation est prête ! Visualisez-là sur votre espace en ligne en choisissant un mot de passe</p>}
        </div>

        <div className='flex flex-col gap-4 w-full'>
          <div className='relative w-full'>
            <TextInput
              label='E-mail'
              name='email'
              value={user.email}
              disabled={true}
            />

            <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-green absolute top-12 mt-1 right-3' />
          </div>

          <div className='flex flex-col gap-2 relative w-full'>
            <TextInput
              type={showPasswords.password ? 'text' : 'password'}
              label={newUser ? 'Créez votre mot de passe' : 'Mot de passe'}
              placeholder='Mot de passe'
              name='password'
              value={user.password}
              onChange={handleChange}
              errors={errors}
            />

            <FontAwesomeIcon icon={showPasswords.password ? S_EyeSlash : S_Eye} className='text-ih-purple-50 absolute top-12 mt-1 right-3 cursor-pointer' onClick={() => setShowPasswords({ ...showPasswords, password: !showPasswords.password })} />
            <Label color={`w-max ${minCharPassword ? 'bg-ih-green-20' : 'bg-ih-blue-20'}`} textColor={minCharPassword ? 'text-ih-green' : 'text-ih-indigo'}>8 caractères minimum</Label>
          </div>

          {newUser &&
            <div className='relative w-full'>
              <TextInput
                type={showPasswords.newPassword ? 'text' : 'password'}
                label='Confirmer votre mot de passe'
                placeholder='Mot de passe'
                name='passwordConfirmation'
                value={user.passwordConfirmation}
                onChange={handleChange}
                errors={errors}
              />

              <FontAwesomeIcon icon={showPasswords.newPassword ? S_EyeSlash : S_Eye} className='text-ih-purple-50 absolute top-12 mt-1 right-3 cursor-pointer' onClick={() => setShowPasswords({ ...showPasswords, newPassword: !showPasswords.newPassword })} />
            </div>
          }
        </div>

        <div className='flex flex-col gap-5 justify-center'>
          <Button onClick={() => signUserIn()} label='Accéder à mon estimation' isLoading={isSubmitted} />
          <button className='underline font-semibold' onClick={() => navigate('invite')}>Continuer en tant qu'invité</button>
        </div>
      </div>
    </div>
  )
}

export default LoginForm;
