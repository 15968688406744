import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const classNames = require('classnames');

import NavigationButtons from '../../components/NavigationButtons'
import { D_CircleExclamation } from 'hosman-material';
import { FadeIn } from 'hosman-material';

import { updateStep, updateEstimationData } from '../../../../../../reducers/_Pages/Public/estimationFunnelSlice';

const Reason = () => {
  const { estimationData, estimationData: { estimationReason }, errors} = useSelector((state) => state.estimationFunnel);
  const dispatch = useDispatch();
  // We need to reinitialize hasEffectRun to make sure it can rerun for a new estimation
  localStorage.removeItem('hasEffectRun');

  const reasons = [
    { slug: 'seller', label: "J'ai un potentiel projet de vente" },
    { slug: 'inbound', label: "Je souhaite m'informer" },
    { slug: 'buyer', label: "Je souhaite l'acheter" }
  ]

  const handleChange = (reason) => {
    dispatch(updateEstimationData({ ...estimationData, estimationReason: reason }))
    dispatch(updateStep({ stepNumber: 6 }))
  }

  const estimationReasonIs = (reason) => {
    return reason === estimationReason;
  }

  const cardClasses = (reason) => {
    return classNames(
      'flex flex-col justify-center border-2 border-ih-30 rounded-2xl p-5 bg-white cursor-pointer',
      { 'border-ih-blue-30 hover:border-ih-blue-50': !estimationReasonIs(reason) },
      { 'border-ih-blue': estimationReasonIs(reason) }
    )
  }

  return (
    <div data-testid='Reason'>
      <FadeIn>
        <div className='flex flex-col gap-6'>
          <p className='h2 text-ih-indigo'>Pour quel motif estimez-vous ce bien ?</p>

          <div className='flex flex-col gap-4'>
            {reasons.map(reason => {
              return (
                <div key={reason.slug} onClick={() => handleChange(reason.slug)} className={cardClasses(reason.slug)}>
                  <p className='h4 text-ih-indigo text-center'>{reason.label}</p>
                </div>
              )
            })}
          </div>

          {errors.estimationReason &&
            <div className='flex justify-start items-center gap-2 mt-2'>
              <FontAwesomeIcon icon={D_CircleExclamation} size='lg' className='text-ih-red' />

              <p className='text-ih-red-60 text-xs'>{errors.estimationReason}</p>
            </div>
          }

          <NavigationButtons classes='hidden md:block my-5' />
        </div>
      </FadeIn>
    </div>
  )
}

export default Reason;
