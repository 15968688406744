import React from 'react';
import PropTypes from 'prop-types';

import { BulletPoint } from 'hosman-material';

const OfferState = ({ status }) => {
  const stateInfos = () => {
    switch (status) {
      case 'pending':
      case 'ongoing':
      case 'partly_accepted':
        return { color: 'bg-ih-blue', label: 'Offre en attente' };
      case 'accepted':
        return { color: 'bg-ih-green', label: 'Offre acceptée' };
      case 'refused':
        return { color: 'bg-ih-red', label: 'Offre refusée' };
      case 'dropped':
        return { color: 'bg-ih-red', label: 'Offre abandonnée' };
    }
  }

  const payload = stateInfos();

  return (
    <div className='flex items-center'>
      <BulletPoint color={payload.color} />
      {payload.label}
    </div>
  )
}

export default OfferState;

// PropTypes
OfferState.propTypes = {
  status: PropTypes.string.isRequired
};
