import client from '../../client';

const ESTIMATION_MEETINGS = {
  getEstimationMeeting(estimationMeetingId) {
    return client.get(`/estimation_meetings/${estimationMeetingId}`);
  },

  getAvailabaleSlots() {
    return client.get('/expert_meetings/slots');
  },

  createEstimationMeeting(saleId, slot) {
    return client.post(`/sales/${saleId}/estimation_meetings`, { appointment: slot });
  }
}

export default ESTIMATION_MEETINGS;
