import React from 'react';
import PropTypes from 'prop-types';
import { BulletPoint } from 'hosman-material';

export const ProsAndConsCard = ({ visit }) => {
  if (visit.visit_report.positive_points?.length < 1 && visit.visit_report.negative_points?.length < 1) {
    return null;
  }

  return (
    <div className='bg-ih-purple-10 p-5 rounded-b-3xl grid grid-cols-1 xl:grid-cols-2 gap-8 text-center text-sm'>
      {(visit.visit_report.positive_points?.length > 0) &&
        <div>
          <div className='flex items-center mb-3 w-max mx-auto'>
            <BulletPoint color='bg-ih-green' />
            <p className='font-semibold'>{`Ce qu'${visit.user.civility === 'mrs' ? 'elle' : 'il'} a apprécié`}</p>
          </div>
          <p>{visit.visit_report.positive_points}</p>
        </div>
      }
      {(visit.visit_report.negative_points?.length > 0) &&
        <div>
          <div className='flex items-center mb-3 w-max mx-auto'>
            <BulletPoint color='bg-ih-red' />
            <p className='font-semibold'>{`Ce qu'${visit.user.civility === 'mrs' ? 'elle' : 'il'} n'a pas apprécié`}</p>
          </div>
          <p>{visit.visit_report.negative_points}</p>
        </div>
      }
    </div>
  )
}

// ProsAndConsCard
ProsAndConsCard.propTypes = {
  visit: PropTypes.object.isRequired
}
