import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ESTIMATION_FOLDERS from '../../api/ressources/SellerApp/estimationFolders';

export const getEstimationFolder = createAsyncThunk(
  '/estimationFolderShow',
  async (params) => {
    const response = await ESTIMATION_FOLDERS.getEstimationFolder(params)
    return response.data
  }
);

export const getComparableSales = createAsyncThunk(
  '/comparableSalesIndex',
  async (params) => {
    const response = await ESTIMATION_FOLDERS.getComparableSales(params)
    return response.data
  }
);

export const getSimilarSalesScreenshots = createAsyncThunk(
  '/similarSalesScreenshotsIndex',
  async (params) => {
    const response = await ESTIMATION_FOLDERS.getSimilarSalesScreenshots(params)
    return response.data
  }
);

const initialState = {
  estimationFolder: {},
  comparableSales: [],
  similarSalesScreenshots: [],
  requestState: ''
};

export const estimationFoldersSlice = createSlice({
  name: 'estimationFolder',
  initialState,
  reducers: {
    resetEstimationFolder(state) {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEstimationFolder.pending, (state) => {
      state.requestState = 'pending'
    })
    builder.addCase(getEstimationFolder.fulfilled, (state, { payload }) => {
      state.estimationFolder = payload;
      state.requestState = 'done';
    })
    builder.addCase(getComparableSales.fulfilled, (state, { payload }) => {
      state.comparableSales = payload;
    })
    builder.addCase(getSimilarSalesScreenshots.fulfilled, (state, { payload }) => {
      state.similarSalesScreenshots = payload;
    })
  },
})

export const { resetEstimationFolder } = estimationFoldersSlice.actions;

export default estimationFoldersSlice.reducer;
