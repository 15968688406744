import React from 'react';

import LeftBanner from './components/LeftBanner';
import LoginForm from './components/LoginForm';

import { LoadingLayer, FadeIn } from 'hosman-material';

const Signup = ({ baseEstimationData, setDisplayResult }) => {
  return (
    <div className='md:grid grid-cols-2 divide-x divide-ih-purple-30 text-ih-indigo'>
      <LeftBanner />

      <LoadingLayer isReady={baseEstimationData.id != null}>
        <FadeIn>
          <LoginForm baseEstimationData={baseEstimationData} setDisplayResult={setDisplayResult} />
        </FadeIn>
      </LoadingLayer>
    </div>
  )
}

export default Signup;
