import client from '../../client';

const SALES = {
  getSales(params) {
    return client.get('/me/sellers/sales', params);
  },

  getSale(saleId) {
    return client.get(`/me/sellers/sales/${saleId}`);
  },

  getPublicationData(saleId) {
    return client.get(`/me/sellers/sales/${saleId}/publication_data`);
  },
}

export default SALES;
