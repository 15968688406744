export const goodStateOptions = [
  { id: 1, value: 'to_renovate', name: 'A rénover' },
  { id: 2, value: 'good', name: 'Bon état' },
  { id: 3, value: 'new', name: 'Neuf / Refait à neuf (< 3 ans)' }
]

export const constructionTimeOptions = [
  { id: 1, value: 'before_1850', name: 'avant 1850' },
  { id: 2, value: 'f1850t1913', name: 'de 1850 à 1913' },
  { id: 3, value: 'f1914t1947', name: 'de 1914 à 1947' },
  { id: 4, value: 'f1948t1969', name: 'de 1948 à 1969' },
  { id: 5, value: 'f1970t1980', name: '1970 à 1980' },
  { id: 6, value: 'f1981t1991', name: '1981 à 1991' },
  { id: 7, value: 'f1992t2000', name: '1992 à 2000' },
  { id: 8, value: 'f2001t2010', name: '2001 à 2010' },
  { id: 9, value: 'f2011tnow', name: 'après 2011' },
  { id: 10, value: 'future', name: 'Programme neuf à livrer' },
  { id: 11, value: 'unknown', name: 'Je ne sais pas' }
]
