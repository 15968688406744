import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { scrollToSection } from '../../../../../../../shared/utils/uiEffects/scrollTo'

import EstimationFolderView from '../../../../../../../shared/containers/EstimationFolderView';

import { LoadingLayer, FadeIn, Tabs, Tab } from 'hosman-material';

import { getComparableSales, getSimilarSalesScreenshots } from '../../../../../../reducers/SellerApp/estimationFoldersSlice';

const items = [
  { name: 'Introduction', anchor: 'introduction'},
  { name: 'Votre bien', anchor: 'votre-bien'},
  { name: 'Analyse du marché', anchor: 'analyse-du-marche'},
  { name: 'Biens comparables', anchor: 'biens-comparables'},
  { name: 'Votre estimation', anchor: 'votre-estimation'},
  { name: 'Prochaines étapes', anchor: 'prochaines-etapes'},
  { name: 'Pourquoi Hosman', anchor: 'pourquoi-hosman' }
]

const ExpertEstimation = () => {
  const { sale } = useSelector((state) => state.sellerSales);
  const { estimationFolder, comparableSales, similarSalesScreenshots } = useSelector((state) => state.sellerEstimationFolders);
  const [selectedItem, setSelectedItem] = useState(items[0])
  const dispatch = useDispatch();
  const sectionRefs = useRef([]);

  const handleSelect = (item) => {
    setSelectedItem(item);
    if (item.scroll) return;

    scrollToSection(document.getElementById(item.anchor))
  }

  useEffect(() => {
    dispatch(getComparableSales(sale.estimation_folder_id));
    dispatch(getSimilarSalesScreenshots(sale.estimation_folder_id));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;

      sectionRefs.current.forEach((ref) => {
        if (ref) {
          const id = ref.id
          const { top, bottom } = ref.getBoundingClientRect();

          if (top < windowHeight/3 && bottom >= 0) {
            handleSelect({ ...items.find(item => item.anchor === id), scroll: true })
            return;
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    window.onclick = () => {
      window.removeEventListener('scroll', handleScroll);
      setTimeout(() => {
        window.addEventListener('scroll', handleScroll);
      }, 1000);
    };

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div data-testid='ExpertEstimation'>
      <LoadingLayer isReady={estimationFolder.id !== undefined}>
        <FadeIn>
          <div className='sticky top-[70px] lg:top-20 flex justify-center ih-bg z-30'>
            <Tabs>
              {items.map((item) => {
                return (
                  <Tab
                    key={item.name}
                    item={item}
                    selectedItem={selectedItem}
                    handleSelect={handleSelect}
                  />
                );
              })}
            </Tabs>
          </div>

          <EstimationFolderView
            sectionRefs={sectionRefs}
            estimationFolder={estimationFolder}
            comparableSales={comparableSales}
            similarSalesScreenshots={similarSalesScreenshots}
          />
        </FadeIn>
      </LoadingLayer>
    </div>
  );
}

export default ExpertEstimation;
