import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, useParams, useNavigate } from 'react-router-dom';
import CustomRoutes from '../../../../../shared/utils/customRoutes';
import { generateLink } from '../../../../../shared/utils/railsRoutes';

import Result from './pages/Result';
import Signup from './pages/Signup';
import { LoadingLayer, FadeIn } from 'hosman-material';

import ESTIMATIONS from '../../../../../shared/apiPublic/ressources/estimations';
import { purgeEstimationData, updateStep } from '../../../../reducers/_Pages/Public/estimationFunnelSlice';

const EstimationResult = () => {
  const { user } = useSelector((state) => state.user);
  const [baseEstimationData, setBaseEstimationData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { uniqueHash } = useParams();

  useEffect(() => {
    // Catch browser back button to redirect to AdressStep and avoid duplicates
    const handleBackButton = () => {
      dispatch(updateStep({ stepNumber: 1 }))
    };

    window.addEventListener('popstate', handleBackButton);
    document.title = 'Estimation - Résultat';
  }, []);

  const fetchBaseEstimationData = async () => {
    try {
      const response = await ESTIMATIONS.getBaseEstimationData(uniqueHash)
      setBaseEstimationData(response.data);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        alert("Cette estimation n'est plus accessible");
        window.location = generateLink(RailsRoutes.root_path())
      } else {
        window.location = generateLink(RailsRoutes.root_path())
      }
    }
  }

  useEffect(() => {
    dispatch(purgeEstimationData())
    fetchBaseEstimationData()
  }, []);

  useEffect(() => {
    if (!baseEstimationData || Object.keys(baseEstimationData).length === 0) return;

    if (baseEstimationData.state === 'dead') {
      navigate('invite')
    } else {
      if (user.id) {
        navigate(`/ev/ventes/${baseEstimationData.id || ''}`);
      }
    }
  }, [baseEstimationData]);

  return (
    <LoadingLayer isReady={baseEstimationData.id !== undefined}>
      <FadeIn>
        <CustomRoutes>
          <Route index element={<Signup baseEstimationData={baseEstimationData} />} />
          <Route path='/invite' element={<Result baseEstimationData={baseEstimationData} />} />
        </CustomRoutes>
      </FadeIn>
    </LoadingLayer>
  )
}

export default EstimationResult;
