import React from 'react';
import { generateLink } from '../../../../../../shared/utils/railsRoutes';

import { Card, Button } from 'hosman-material';


const GuidesList = () => {
  const guides = [
    {slug: "guide-du-vendeur",title: "Le guide du vendeur", content: "Vous vendez votre bien ? On vous donne nos tips pour y parvenir."},
    {slug: "guide-de-l-acheteur",title: "Le guide de l'acheteur", content: "Un projet d'achat ? Découvrez nos conseils pour acheter sereinement."},
    {slug: "guide-du-proprietaire",title: "Le guide du propriétaire", content: "Découvrez tout ce qu'il faut savoir pour gérer parfaitement son bien immobilier."},
  ]
  return (
    <div className='w-full xl:w-[1000px] flex flex-wrap gap-5 mx-auto'>
      {guides.map((guide) => (
        <div key={guide.title} className='xl:mb-0 w-full md:w-80'>
          <Card>
            <div className='p-8 h-full'>
              <p className='h3 text-ih-indigo'>{guide.title}</p>

              <p className='text-ih-indigo my-5'>
                {guide.content}
              </p>

              <div className='text-center'>
                <a href={generateLink(RailsRoutes.guide_path(guide.slug))} target='_blank'>
                  <Button label='Découvrir ce guide' type='secondary' fullWidth />
                </a>
              </div>
            </div>
          </Card>
        </div>
      ))}
    </div>
  )
}

export default GuidesList;
