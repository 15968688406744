import React from 'react';
import { useToaster } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { S_Xmark } from 'hosman-material';
import { toast as libToast } from 'react-hot-toast';
import { FadeIn } from 'hosman-material';
const classNames = require('classnames');

const Toaster = () => {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause, calculateOffset, updateHeight } = handlers;

  return (
    <div className='fixed top-20 md:top-2 right-2 z-50'
      onMouseEnter={startPause}
      onMouseLeave={endPause}
    >
      {toasts.map((toast) => {
        const offset = calculateOffset(toast.id, {
          reverseOrder: false,
          margin: 10
        });
        const ref = (el) => {
          if (el && !toast.height) {
            const height = el.getBoundingClientRect().height;
            updateHeight(toast.id, height);
          }
        };
        let classes = classNames(
          'px-6 py-3 border-2 rounded-xl transition-all h-min',
          { 'bg-ih-red-5 text-ih-red border-ih-red-30': toast.type == 'error' },
          { 'bg-ih-green-5 text-ih-green border-ih-green-30': toast.type == 'success' },
          { 'bg-ih-purple-5 text-ih-purple-50 border-ih-purple-30': toast.type == 'info' }
        );

        return (
          <div
            key={toast.id}
            id={toast.id}
            ref={ref}
            className={classes}
            style={{
              transform: `translateY(${offset}px)`,
              opacity: toast.visible ? 1 : 0
            }}
          >
            <FadeIn>
              <div className='flex items-center gap-4'>
                {toast.icon && <FontAwesomeIcon icon={toast.icon} size='lg' />}
                {toast.message}
                <FontAwesomeIcon icon={S_Xmark} size='lg' onClick={() => libToast.dismiss(toast.id)}/>
              </div>
            </FadeIn>
          </div>
        );
      })}
    </div>
  );
};

export default Toaster;
