import React from 'react';
import PropTypes from 'prop-types';

import Characteristics from '../../../../../../shared/components/Property/Characteristics';
import { Card, Carousel, Avatar, Box, Label } from 'hosman-material';

const DesktopSidebar = ({ offer }) => {
  const property = offer.sale.property

  return (
    <div className='hidden md:block col-span-2 h-screen bg-hosman-gradient-1'>
      <div className='flex items-center justify-around h-full'>
        <div className='w-72'>
          <Card>
            <Carousel images={[property.property_pictures[0].photo_url]} />

            <div className='relative p-6'>
              <div className='absolute -top-6 z-10'>
                <Avatar border imageSrc={offer.sale.expert?.user?.profile_pic_url} />
              </div>

              <div className='absolute -top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                <Label color='bg-ih-indigo'>{property.city}</Label>
              </div>

              <p className={'text-sm font-semibold truncate my-4 text-ih-neutral'}>
                {property.street_number} {property.street_name} {property.zip_code} {property.city}
              </p>

              <Characteristics property={property} short />
            </div>
            <div className='h4 text-center text-ih-blue bg-ih-blue-10 p-3 rounded-b-2xl w-full'>
              {offer.sale.price.toLocaleString()} €
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}

// PropTypes
DesktopSidebar.propTypes = {
  offer: PropTypes.object.isRequired
}

export default DesktopSidebar;
