import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FadeIn } from 'hosman-material';
import { S_CircleCheck, S_HourglassHalf } from 'hosman-material';

const FadeInList = () => {
  const baseItems = [
    { visible: false, icon: S_CircleCheck, iconColor: 'text-ih-blue', content: 'Récupération des transactions historiques' },
    { visible: false, icon: S_CircleCheck, iconColor: 'text-ih-blue', content: 'Analyse des biens en vente à proximité' },
    { visible: false, icon: S_CircleCheck, iconColor: 'text-ih-blue', content: 'Calcul de votre estimation...' },
    { visible: false, icon: S_HourglassHalf, iconColor: 'text-ih-orange', content: "Le calcul est plus long que d'habitude, merci pour votre patience" }
  ];
  const [items, setItems] = useState(baseItems)

  useEffect(() => {
    items.map((item, index) => {
      setTimeout(() => {
        setItems((prev => {
          const newItems = [...prev]
          newItems[index].visible = true
          return newItems
        }))
      }, index * 2000);
    })
  }, []);

  return (
    <div className='flex flex-col gap-6'>
      {items.map(item => {
        return (
          <div className={`flex gap-3 items-center transition-opacity duration-700 ${item.visible ? 'opacity-100' : 'opacity-0'}`} key={item.content}>
            <FontAwesomeIcon icon={item.icon} size='xl' className={item.iconColor} />
            <p>{item.content}</p>
          </div>
        )
      })}
    </div>
  )
}

export default FadeInList;
