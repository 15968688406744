import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import NavigationButtons from '../../components/NavigationButtons'

import { FadeIn, Checkbox } from 'hosman-material'

import { updateEstimationData } from '../../../../../../reducers/_Pages/Public/estimationFunnelSlice';

export const characteristics = {
  apartment: [
    { slug: 'elevator', name: 'Ascenseur' },
    { slug: 'balcony', name: 'Balcon' },
    { slug: 'terrace', name: 'Terrasse' },
    { slug: 'cellar', name: 'Cave' },
    { slug: 'concierge', name: 'Gardien' },
    { slug: 'parking', name: 'Parking' }
  ],
  house: [
    { slug: 'terrace', name: 'Terrasse' },
    { slug: 'parking', name: 'Parking' },
    { slug: 'garage', name: 'Garage' },
    { slug: 'pool', name: 'Piscine' }
  ]
}

const ExtraInfos = () => {
  const { estimationData, estimationData: { typology, extraInfos }} = useSelector((state) => state.estimationFunnel);
  const dispatch = useDispatch();
  const typoCharacteristics = characteristics[typology]

  const handleChange = (e, characteristic) => {
    e.preventDefault();
    dispatch(updateEstimationData({
                              ...estimationData,
                              extraInfos: {
                                ...extraInfos,
                                [characteristic.slug]: !extraInfos[characteristic.slug]
                              }
                            }))
  }

  if (!typoCharacteristics) return null;

  return (
    <div data-testid='ExtraInfos'>
      <FadeIn>
        <div className='flex flex-col gap-6'>
          <p className='h2 text-ih-indigo'>Votre logement comporte-t-il une de ces spécificités ?</p>

          <div className='border border-ih-purple-20 rounded-xl'>
            {characteristics[typology].map(characteristic => {
              return (
                <div
                  key={characteristic.slug}
                  className='p-4 bg-white hover:bg-ih-purple-5 border-b border-ih-purple-20 last:border-b-0 first:rounded-t-xl last:rounded-b-xl cursor-pointer'
                  onClick={(e) => handleChange(e, characteristic)}
                >
                  <Checkbox
                    key={characteristic.slug}
                    name={characteristic.slug}
                    label={characteristic.name}
                    isChecked={extraInfos[characteristic.slug]}
                    readOnly
                  />
                </div>
              )
            })}
          </div>
        </div>

        <NavigationButtons classes='hidden md:block my-5' />
      </FadeIn>
    </div>
  )
}

export default ExtraInfos;
