import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MenuButton = ({ setSidebarOpen, icon }) => {
  return(
    <button type='button' onClick={setSidebarOpen} className='bg-ih-indigo-80 py-1.5 px-3 flex items-center gap-x-2 white rounded-xl' data-testid='menu-button'>
      <span className='h4 text-white'>Menu</span>

      <FontAwesomeIcon
        icon={ icon }
        className='h-4 w-4 flex items-center text-ih-blue'
      />
    </button>
  )
}

export default MenuButton;

// PropTypes
MenuButton.propTypes = {
  icon: PropTypes.object.isRequired,
  setSidebarOpen: PropTypes.func.isRequired
};
