import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { S_HourglassHalf, S_Check, S_Xmark } from 'hosman-material';
import { TimeFeed, FadeIn } from 'hosman-material';

import OfferStateLabel from '../../../../../../../components/Offer/StateLabel.js';
import OfferStateCard from '../../../../../../../components/Offer/StateCard.js';
import OfferModal from './components/OfferModal';

import { generateLink } from '../../../../../../../../shared/utils/railsRoutes';
import { ga4Event } from '../../../../../../../../shared/utils/ga4Event';

const Offers = () => {
  const { showOffers } = useSelector((state) => state.buyerOffers);
  const { user } = useSelector((state) => state.user);
  const [events, setEvents] = useState([])
  const [modalOffer, setModalOffer] = useState(null)

  const handleNewOffer = (offer) => {
    const eventName = offer.review_status === null ? 'finalize_offer' : 'do_counter_offer'
    ga4Event(eventName, { 'user_id': user.id, 'unique_hash': offer.sale.unique_hash })
    window.open(generateLink(
      RailsRoutes.online_offers_path({ property_slug: offer.sale.property.slug })
    ))
  }

  useEffect(() => {
    if (showOffers.length > 0) {
      let payload = []

      showOffers.map(offer => {
        const event = {
          id: offer.id,
          stateLabel: <OfferStateLabel offer={offer} />,
          icon: icon(offer.review_status),
          iconColor: iconColor(offer.review_status),
          content: (
            <OfferStateCard
              offer={offer}
              handleShowOffer={() => setModalOffer(offer)}
              handleNewOffer={() => handleNewOffer(offer)}
            />
          ),
        };

        payload.push(event)
      })

      setEvents(payload)
    }
  }, [showOffers]);

  const icon = (status) => {
    switch (status) {
      case null:
      case 'pending':
      case 'ongoing':
      case 'partly_accepted':
      case 'unsent_for_review':
        return S_HourglassHalf;
      case 'accepted':
        return S_Check;
      case 'refused':
      case 'dropped':
        return S_Xmark;
    }
  }

  const iconColor = (status) => {
    switch (status) {
      case null:
        return 'bg-ih-orange';
      case 'pending':
      case 'ongoing':
      case 'partly_accepted':
      case 'unsent_for_review':
        return 'bg-ih-blue';
      case 'accepted':
        return 'bg-ih-green';
      case 'refused':
        return 'bg-ih-red';
      case 'dropped':
        return 'bg-ih-red';
    }
  }

  const displayOfferModal = () => {
    if (modalOffer) {
      return (
        <OfferModal modalOffer={modalOffer} closeModal={() => setModalOffer(null)} />
      )
    }
  }

  return (
    <FadeIn>
      <TimeFeed events={events} />
      {displayOfferModal()}
    </FadeIn>
  )
}

export default Offers;
