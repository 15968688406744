import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Route,
  Navigate,
  useNavigate,
  useLocation
} from 'react-router-dom';
import CustomRoutes from '../../../shared/utils/customRoutes';

import SalesIndex from '../SellerApp/SalesIndex';
import SaleShow from '../SellerApp/SaleShow';
import Sidebar from '../../components/Sidebar/Sidebar';

import { getSales } from '../../reducers/SellerApp/salesSlice';

const SellerApp = ({ clientSwitch, accountCard }) => {
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();

  const [navigation, setNavigation] = useState([])
  const [selectedNav, setSelectedNav] = useState({})

  useEffect(() => {
    if (navigation.length < 1) return;

    const matchedPath = navigation.find((nav, index) => index !== 0 && location.pathname.includes(nav.path));
    setSelectedNav(matchedPath || navigation[0])
  }, [navigation, location.pathname]);

  const handleNavChange = (navigation) => {
    if (navigation.disabled) return;

    setSelectedNav(navigation);
    navigate(navigation.path);
  }

  useEffect(() => {
    dispatch(getSales());
  }, []);

  return (
    <div data-testid='SellerApp'>
      <Sidebar
        navigation={navigation}
        selectedNav={selectedNav}
        handleSelect={handleNavChange}
        clientSwitch={clientSwitch}
        accountCard={accountCard}
      >
        <CustomRoutes>
          <Route index element={<Navigate to='ventes' />} />
          <Route path='/ventes' element={<SalesIndex setNavigation={setNavigation} />} />
          <Route path='/ventes/:saleId/*' element={<SaleShow setNavigation={setNavigation} selectedNav={selectedNav} />} />
        </CustomRoutes>
      </Sidebar>
    </div>
  );
}

// PropTypes
SellerApp.propTypes = {
  clientSwitch: PropTypes.func,
  accountCard: PropTypes.func
}

export default SellerApp;
