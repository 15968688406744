import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import SponsorshipBanner from '../../../../../components/Marketing/SponsorshipBanner';
import SaleSteps from './components/SaleSteps';
import SalePropertySummary from './components/SalePropertySummary';
import CallToActions from './components/CallToActions';
import PhotoMeetingModal from './components/PhotoMeetingModal';
import { smilingFace } from '../../../../../../shared/assets/emojis'

import { LoadingLayer, FadeIn, Emoji, Separator } from 'hosman-material';

import SaleKeyNumbers from './components/SaleKeyNumbers';
import Marketing from './components/Marketing';
import { greeting } from "../../../../../../shared/utils/helpers/hosmanVars";

const Sale = () => {
  const { user } = useSelector((state) => state.user);
  const { sale, requestState } = useSelector((state) => state.sellerSales);
  const { acceptedOffer } = useSelector((state) => state.sellerOffers);
  const [photoMeetingmodalOpen, setPhotoMeetingModalOpen] = useState(false)

  const displayPhotoMeetingModal = () => {
    if (photoMeetingmodalOpen) {
      return (
        <PhotoMeetingModal sale={sale} closeModal={() => setPhotoMeetingModalOpen(false)} />
      )
    }
  }

  return (
    <div data-testid='Sale'>
      <LoadingLayer isReady={requestState == 'done'}>
        <FadeIn>
          <p className='h2 mb-8 text-ih-indigo text-center md:text-left'>{`Bonjour, ${greeting(user)}`}<span className='ml-2'><Emoji emojiSrc={smilingFace} width='w-7' /></span></p>

          <CallToActions setPhotoMeetingModalOpen={setPhotoMeetingModalOpen} />

          <SaleSteps sale={sale} acceptedOffer={acceptedOffer} openPhotoMeetingModal={() => setPhotoMeetingModalOpen(true)} />

          <SaleKeyNumbers />

          <Separator />

          <SalePropertySummary />

          <Marketing />

          <Separator />

          {displayPhotoMeetingModal()}

          <SponsorshipBanner />
        </FadeIn>
      </LoadingLayer>
    </div>
  );
}

export default Sale;
