import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ stepInfos }) => {
  return (
    <div data-testid='ProgressBar' className='flex flex-col gap-2'>
      <div className='w-full bg-ih-purple-10 rounded-full h-2'>
        <div className='bg-ih-blue h-2' style={{width: `${stepInfos.step/stepInfos.total*100}%`}}></div>
      </div>
      <div className='w-full'>
        <div className='md:mx-10'>
          <div className='flex justify-between px-6'>
            <p className='h4 text-ih-blue'>{stepInfos.title}</p>
            <p className='h4 text-ih-blue'>{stepInfos.step ? `${stepInfos.step}/${stepInfos.total}` : ''}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProgressBar;

// PropTypes
ProgressBar.propTypes = {
  stepInfos: PropTypes.object.isRequired
}

