import { validateEmail, validatePhoneNumber } from '../../../../../../shared/utils/validators';
import { NO_SPECIAL_CHARACTER_REGEX } from '../../../../../../shared/utils/helpers/hosmanVars';

export const validateStep = ({ stepNumber, data }) => {
  switch (stepNumber) {
    case 1:
      return validateAddress(data);
    case 2:
      return validateTypology(data)
    case 3:
    case 4:
      return validateMainInfos(data)
    case 5:
      return validateEstimationReason(data)
    case 6:
    case 7:
      return validateLead(data)
    default:
      return { valid: true, errors: {} }
  }
}

function validateAddress({ placeData }) {
  if (!placeData.formatted_address) return { valid: false, errors: { formatted_address: ['*Veuillez entrer une adresse'] }}
  if (!placeData.street_number)     return { valid: false, errors: { formatted_address: ['*Le numéro de rue est requis'] }}

  return { valid: true }
}

function validateTypology({ typology, placeData }) {
  if (!validateAddress({ placeData }).valid) return { valid: false }
  if (typology) return { valid: true }

  return { valid: false, errors: { typology: ['Veuillez sélectionner un type de bien'] }}
}

function validateMainInfos({ typology, mainInfos, placeData }) {
  if (!validateAddress({ placeData }).valid) return { valid: false }

  const { carrez_area, floor, total_floor, room_number, bedroom_number, construction_time, good_state, living_area, land_area } = mainInfos
  const errors = {}

  if (!total_floor) errors.total_floor = ['est requis']
  if (!room_number) errors.room_number = ['est requis']
  if (!bedroom_number) errors.bedroom_number = ['est requis']
  if (!construction_time) errors.construction_time = ['est requis']
  if (!good_state) errors.good_state = ['est requis']
  if (floor && total_floor && (Number(floor) > Number(total_floor))) errors.total_floor = ["*L'étage du bien ne peut pas être supérieur au nombre d'étage total de l'immeuble"]
  if (room_number && bedroom_number && (Number(bedroom_number) > Number(room_number))) errors.bedroom_number = ["*Le nombre de chambres ne peut pas être supérieur au nombre de pièces"]
  if (typology == 'apartment' && !carrez_area) errors.carrez_area = ['est requis']
  if (typology == 'apartment' && !floor) errors.floor = ['est requis']
  if (typology == 'house' && !living_area) errors.living_area = ['est requis']
  if (typology == 'house' && !land_area) errors.land_area = ['est requis']

  const isValid = Object.keys(errors).length === 0
  return { valid: isValid, errors: errors }
}

function validateEstimationReason({ estimationReason, placeData }) {
  if (!validateAddress({ placeData }).valid) return { valid: false }
  if (estimationReason) return { valid: true }

  return { valid: false, errors: { estimationReason: ["Veuillez sélectionner un motif d'estimation"] }}
}

async function validateLead({ lead }) {
  const { civility, first_name, last_name, email, phone_country_code, phone_number } = lead
  const errors = {}

  if (!civility) errors.civility = ['est requis']
  if (!first_name) errors.first_name = ['est requis']
  if (!last_name) errors.last_name = ['est requis']
  if (!NO_SPECIAL_CHARACTER_REGEX.test(first_name)) errors.first_name = ['contient des caractères spéciaux non valides']
  if (!NO_SPECIAL_CHARACTER_REGEX.test(last_name)) errors.last_name = ['contient des caractères spéciaux non valides']
  if (!validateEmail(email)) errors.email = ['non valide']
  const isPhoneValid = await validatePhoneNumber(phone_country_code, phone_number)
  if (!isPhoneValid.valid) errors.phone_number = ['*Numéro de téléphone non valide']

  const isValid = Object.keys(errors).length === 0
  return { valid: isValid, errors: errors }
}
