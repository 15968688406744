import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { D_CalendarCheck } from 'hosman-material';

import { shortDate, displayTime, timeSince } from '../../../../../../../../../shared/utils/formattedDates'
import SaleKeyNumbersCard from './shared/SaleKeyNumbersCard';

const VisitorsInterest = () => {
  const { visitsToCome, visits, visitsWithReport } = useSelector((state) => state.sellerVisits);
  const [percentageInterestedVisitors, setPercentageInterestedVisitors] = useState(0);
  const [numbersColor, setNumbersColor] = useState('text-ih-blue');
  const [notInRange, setNotInRange] = useState(false);

  useEffect(() => {
    if (visitsWithReport.length <= 0) return

    const interestedVisitors = visitsWithReport.filter(visit => ['very_interested', 'interested'].includes(visit.visit_report.interest))
    const percentageInterestedVisitors = (interestedVisitors.length / visitsWithReport.length) * 100

    setPercentageInterestedVisitors(Math.round(percentageInterestedVisitors));
  }, [visitsToCome, visits, visitsWithReport])

  useEffect(() => {
    const uninterestedVisitors = visitsWithReport.filter(visit => ['maybe_interested', 'uninterested'].includes(visit.visit_report.interest))

    if ((percentageInterestedVisitors > 0 && percentageInterestedVisitors <= 50 && visitsWithReport.length > 0) || uninterestedVisitors.length > 0) {
      setNumbersColor('text-ih-red')
      setNotInRange(true)
    }

    if (percentageInterestedVisitors > 50) setNumbersColor('text-ih-green')

  }, [percentageInterestedVisitors])

  const handleWarningText = () => {
    const visitsDone = visits.filter(visit => visit.kind === 'classic' && visit.state === 'done')

    if (visitsDone.length === 0 && visitsToCome.length === 0) {
      return 'Pas encore de visite réalisée'
    } else if (visitsToCome.length > 0) {
      const nextVisitToCome = visitsToCome[0];
      return `Prochaine visite ${shortDate(nextVisitToCome.start_time)} à ${displayTime(new Date(nextVisitToCome.start_time))}`
    } else if (visitsDone.length > 0) {
      const lastVisit = visitsDone.pop();
      return `Dernière visite ${timeSince(new Date(lastVisit.end_time))}`
    }
  }

  return(
    <SaleKeyNumbersCard
      thresholdWarning={notInRange && visitsToCome.length < 1}
      icon={D_CalendarCheck}
      title='Visiteurs intéressés'
      numbersColor={numbersColor}
      subtitle={`${percentageInterestedVisitors} %`}
      warningText={handleWarningText()}
      visitToComeColorException={visitsToCome.length > 0} />
  )
};

export default VisitorsInterest
