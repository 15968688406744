import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { generateLink } from '../../../../shared/utils/railsRoutes'

import { ImageCard, Button } from 'hosman-material';

import PARTNERS from '../../../api/ressources/App/partners';

const Partner = ({ slug = 'nextories', preTitle }) => {
  const [partner, setPartner] = useState({});

  useEffect(() => {
    PARTNERS.getPartner(slug).then((response) => {
        setPartner(response.data);
    });
  }, []);

  return (
    <a href={generateLink(RailsRoutes.partner_page_path(slug))} target='_blank' data-testid='Partner'>
      {partner.id &&
        <ImageCard
          imageSrc={partner.card_picture_url}
        >
          <p className='h2 text-2xl pb-2'>
            {preTitle} Découvrez <span className='text-ih-blue'>{partner.partner_name}</span>
          </p>
          <p className='pb-4 line-clamp-5'>{partner.description}</p>
          <Button label='Découvrir ce partenaire' type='secondary' size='large' />
        </ImageCard>
      }
    </a>
  )
}

Partner.propTypes = {
  slug: PropTypes.string,
  preTitle: PropTypes.string
};

export default Partner;
