let actionConsumer = null;

if (process.env.NODE_ENV !== 'test') {
  import('@rails/actioncable/src').then(module => {
    const { createConsumer } = module;
    actionConsumer = createConsumer();
  });
}

export { actionConsumer };
