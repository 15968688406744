import React from 'react';
import { useSelector } from 'react-redux';
import { trustpilot } from '../../../../../../../shared/assets/logos'
import { generateLink } from '../../../../../../../shared/utils/railsRoutes';

import StepsNavigation from './components/StepsNavigation';
import MobileHeader from './components/MobileHeader';
import { HosmanLogo } from 'hosman-material';

const Header = () => {
  const { currentStep, allSteps } = useSelector((state) => state.estimationFunnel);

  return (
    <div className='sticky top-0 md:border-b-2 border-ih-purple-30 bg-white z-30' data-testid='Header'>
      <div className='hidden lg:flex items-center justify-between h-24 px-12'>
        <div className='flex'>
          <a href={generateLink(RailsRoutes.root_path())} className='w-max hidden md:block'>
            <HosmanLogo width={160} />
          </a>
        </div>

        {currentStep.displayNavigation && <StepsNavigation />}

        <div className='flex items-center justify-end text-ih-indigo divide-x-2'>
          <img src={trustpilot} alt='trustpilot' className='h-5 mr-2 mb-1 '/>

          <p><span className='text-ih-neutral-50 ml-2'>4,9/5 • </span>Excellent</p>
        </div>
      </div>

      {currentStep.displayNavigation &&
        <MobileHeader currentStep={currentStep} allSteps={allSteps} />
      }
    </div>
  )
}

export default Header;
