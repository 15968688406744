import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { D_House } from 'hosman-material';

import SaleKeyNumbersCard from './shared/SaleKeyNumbersCard';

const Price = () => {
  const { sale, publicationData: { sale_estimation_medium } } = useSelector((state) => state.sellerSales);
  const [priceAboveEstimation, setPriceAboveEstimation] = useState(false)

  useEffect(() => {
    setPriceAboveEstimation(sale.price > sale_estimation_medium)
  }, [sale, sale_estimation_medium])

  const handleSalePriceSubtitle = () => {
    if (priceAboveEstimation) return `Supérieur au prix de marché conseillé (${sale_estimation_medium.toLocaleString()} €)`
  }

  return (
    <SaleKeyNumbersCard
      thresholdWarning={priceAboveEstimation}
      icon={D_House}
      title='Prix de vente actuel'
      numbersColor={priceAboveEstimation ? 'text-ih-indigo': 'text-ih-blue'}
      subtitle={`${sale.price.toLocaleString()} €`}
      warningText={handleSalePriceSubtitle()} />
  )
};

export default Price
