import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Button } from 'hosman-material';
import { S_Check, S_MagnifyingGlass, S_TrashCan } from 'hosman-material';

export const FileInputUploaded = ({ file, lastDocumentUpload, isLoading, deleteAction }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = () => {
    setIsDeleting(true);
    deleteAction();
  }

  return (
   <>
    {
      isLoading && (lastDocumentUpload === Number(file.id)) ?
      <div className='flex justify-center items-center'>
        <div className='w-6 h-6 border-4 border-t-4 border-gray-200 border-t-ih-blue rounded-full animate-spin'></div>
      </div>
      :
      <label
        key={file.slug}
        className='flex justify-between items-center w-full transition appearance-none cursor-pointer hover:border-solid focus:outline-none mb-4 last:mb-0'
        data-testid='fileInputLabel'
      >
        <a href={file.document_url} target='_blank' rel='noreferrer'>
          <div className='flex items-center'>
            <FontAwesomeIcon icon={S_Check} size='sm' className='text-ih-green mr-2' />
            <span className='text-ih-neutral text-xs w-52 md:w-full'>{`${file.type.name.toLowerCase().replace(/ /g, '-')}.pdf`} {file.year ? `(${file.year})` : ''}</span>
          </div>
        </a>

        <div className='flex gap-2 items-center'>
          {deleteAction &&
            <>
              <FontAwesomeIcon icon={S_TrashCan} size='sm' className='text-ih-red cursor-pointer' onClick={() => setShowConfirmation(true)} />

              <Modal isOpen={showConfirmation} closeModal={() => setShowConfirmation(false)}>
                <div className='flex flex-col gap-3 py-6 px-12'>
                  <p>Êtes-vous sur de vouloir supprimer le document suivant :</p>
                  <p className='font-bold text-center'>{`${file.type.name.toLowerCase().replace(/ /g, '-')}.pdf`} {file.year ? `(${file.year})` : ''}</p>
                  <div className='flex gap-3 justify-center'>
                    <Button label='Supprimer' onClick={() => handleDelete()} isLoading={isDeleting} />
                    <Button label='Annuler' type='secondary' onClick={() => setShowConfirmation(false)} />
                  </div>
                </div>
              </Modal>
            </>
          }

          <a href={file.document_url} target='_blank'>
            <FontAwesomeIcon icon={S_MagnifyingGlass} size='sm' className='text-ih-indigo-60' />
          </a>
        </div>
      </label>
    }
   </>
  )
};

FileInputUploaded.propTypes = {
  file: PropTypes.object.isRequired,
  lastDocumentUpload: PropTypes.number,
  isLoading: PropTypes.bool,
}
