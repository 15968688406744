import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import OFFERS from '../../api/ressources/BuyerApp/offers';

export const getIndexOffers = createAsyncThunk(
  '/offersIndex',
  async (params) => {
    const response = await OFFERS.getOffers(params)
    return response.data
  }
);

export const getShowOffers = createAsyncThunk(
  '/offersShow',
  async (params) => {
    const response = await OFFERS.getOffers(params)
    return response.data
  }
);

export const getOffer = createAsyncThunk(
  '/offer',
  async (offerId) => {
    const response = await OFFERS.getOffer(offerId)
    return response.data
  }
);

const initialState = {
  indexOffers: [],
  showOffers: [],
  offer: {},
  requestState: ''
};

export const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    resetOffer(state) {
      state.offer = initialState.offer;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getIndexOffers.pending, (state) => {
      state.indexOffers = initialState.indexOffers;
      state.requestState = 'pending'
    })
    builder.addCase(getIndexOffers.fulfilled, (state, { payload }) => {
      let selectedOffers = selectOffers(payload.results);

      // Return selectedOffers ordered by creation date
      state.indexOffers = selectedOffers.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      state.requestState = 'done'
    })
    builder.addCase(getShowOffers.pending, (state) => {
      state.showOffers = initialState.showOffers;
      state.requestState = 'pending'
    })
    builder.addCase(getShowOffers.fulfilled, (state, { payload }) => {
      state.showOffers = payload.results;
      state.requestState = 'done'
    })
    builder.addCase(getOffer.pending, (state) => {
      state.offer = initialState.offer;
      state.requestState = 'pending'
    })
    builder.addCase(getOffer.fulfilled, (state, { payload }) => {
      state.offer = payload;
      state.requestState = 'done'
    })
  },
})

const selectOffers = (rawOffers) => {
  // Split offers by sale
  let sales = {};
  rawOffers.map((rawOffer) => {
    let id = rawOffer.sale.id;
    sales[id] = sales[id] ?? [];
    sales[id].push(rawOffer);
  });

  // Select one offer per sale by priority order
  let selectedOffers = [];
  Object.entries(sales).map(([_, offers]) => {
    if (offers.length < 2) {
      selectedOffers.push(offers[0]);
      return;
    }

    let offer = null
    const priorities = ['accepted', 'partly_accepted', 'ongoing', 'pending', 'unsent_for_review', 'dropped', 'refused', null]

    priorities.map((state) => {
      if (offer) return;

      offer = offers.filter(offer => offer.review_status === state)[0]
    })

    offer && selectedOffers.push(offer);
  });

  return selectedOffers;
};

export const { resetOffer } = offersSlice.actions;

export default offersSlice.reducer;
