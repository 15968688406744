export const allSteps = [
  { number: 1, name: 'Adresse du bien', path: 'adresse', displayNavigation: true },
  { number: 2, name: 'Typologie du bien', path: 'typologie', displayNavigation: true },
  { number: 3, name: 'Informations principales', path: 'informations', displayNavigation: true },
  { number: 4, name: 'Spécificités du bien', path: 'specificites', displayNavigation: true },
  { number: 5, name: "Motif d'estimation", path: 'motif', displayNavigation: true },
  { number: 6, name: 'Contact', path: 'contact', displayNavigation: false },
  { number: 7, name: 'Loader', path: 'calcul-en-cours', displayNavigation: false }
]

export const matchedStep = allSteps.find((step) => window.location.pathname.includes(step.path));

export const initialPlaceData = {
  formatted_address: null,
  street_number:     null,
  street_name:       null,
  zip_code:          null,
  city:              null,
  country_code:      null,
  lat:               null,
  lng:               null
}

export const initialMainInfos = {
  carrez_area:       null,
  living_area:       null,
  land_area:         null,
  floor:             '',
  total_floor:       '',
  room_number:       '',
  bedroom_number:    '',
  construction_time: null,
  good_state:        null
}

export const initialExtraInfos = {
  elevator: false,
  balcony: false,
  terrace: false,
  cellar: false,
  concierge: false,
  parking: false,
  pool: false,
  garage : false
}

export const initialLead = {
  civility: '',
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  phone_country_code: 'FR'
}
