import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export const HorizontalLines = ({ containerOffsetRef }) => {
  return (
    <div
      className='col-start-1 col-end-2 row-start-1 grid divide-y divide-ih-blue-10'
      style={{ gridTemplateRows: 'repeat(14, minmax(3rem, 1fr))' }}
    >
      <div ref={containerOffsetRef} className='row-end-1 h-7'></div>
      {Array.from(new Array(15), (x,i) => i).map((hour) => {
        return (
          <Fragment key={hour}>
            <div>
              <div className='sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-ih-neutral-20'>
                {`${hour+7}:00`}
              </div>
            </div>
          </Fragment>
        )
      })}
    </div>
  )
}

HorizontalLines.propTypes = {
  containerOffsetRef: PropTypes.object
};
