import React from 'react';
import PropTypes from 'prop-types';
import * as dayjs from 'dayjs';
const isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday);

export const Header = ({ referenceDate, header }) => {
  const monthAsString = (monthIndex) => {
    const months = new Array(12);
    months[0] = 'Janvier';
    months[1] = 'Février';
    months[2] = 'Mars';
    months[3] = 'Avril';
    months[4] = 'Mai';
    months[5] = 'Juin';
    months[6] = 'Juillet';
    months[7] = 'Août';
    months[8] = 'Septembre';
    months[9] = 'Octobre';
    months[10] = 'Novembre';
    months[11] = 'Décembre';

    return months[monthIndex];
  }

  const newReferenceDay = (newDate) => {
    referenceDate.setDate(newDate)

    return new Date(referenceDate);
  }

  return (
    <header className='pb-4'>
      <div className='flex items-center gap-3'>
        <h1 className='w-fit whitespace-nowrap text-md font-semibold text-indigo'>
          <p className='md:hidden'>{dayjs(referenceDate).isToday() ? "Aujourd'hui" : `${referenceDate.getDate()} ${monthAsString(referenceDate.getMonth())} ${referenceDate.getFullYear() }`}</p>
          <p className='hidden md:block'>{monthAsString(referenceDate.getMonth())} {referenceDate.getFullYear() }</p>
        </h1>
        {header}
      </div>
    </header>
  )
}

Header.propTypes = {
  referenceDate: PropTypes.object,
  header: PropTypes.node
};
