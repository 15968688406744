import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ExpertEstimation from './views/ExpertEstimation';
import FunnelEstimation from './views/FunnelEstimation';

import { LoadingLayer } from 'hosman-material';

const Estimation = () => {
  const { sale } = useSelector((state) => state.sellerSales);
  const { estimationFolder, requestState } = useSelector((state) => state.sellerEstimationFolders);
  let navigate = useNavigate();

  useEffect(() => {
    // Catch browser back button to redirect to OffersIndex
    const handleBackButton = () => {
      navigate('/ev');
    };

    window.addEventListener('popstate', handleBackButton);
  }, []);

  return (
    <div data-testid='Estimation'>
      <LoadingLayer isReady={!sale.estimation_folder_id || requestState === 'done'}>
        {estimationFolder.sent_at ?
          <ExpertEstimation />
        :
          <FunnelEstimation />
        }
      </LoadingLayer>
    </div>
  );
}

export default Estimation;
