import { useState, useEffect, useRef } from 'react';

const useVisibility = (options) => {
  const [observedElementIsVisible, setObservedElementIsVisible] = useState(false);
  const observedElementRef = useRef(null);

  useEffect(() => {
    const callback = (entries) => {
      entries.forEach(entry => {
        setObservedElementIsVisible(entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(callback, options);

    const currentElement = observedElementRef.current;
    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [options]);

  return [observedElementIsVisible, observedElementRef];
};

export default useVisibility;
