import React from 'react';
import { useSelector } from 'react-redux';
import { FadeIn, Label, PriceBox, Button, Separator } from 'hosman-material';

import PropertySummary from '../../../../../../../components/Property/PropertySummary';
import OfferStateLabel from '../../../../../../../components/Offer/StateLabel.js';
import CtaBanner from '../../components/CtaBanner';
import SponsorshipBanner from '../../../../../../../components/Marketing/SponsorshipBanner.js';

import { generateLink } from '../../../../../../../../shared/utils/railsRoutes';
import { ga4Event } from '../../../../../../../../shared/utils/ga4Event';

const Property = () => {
  const { offer } = useSelector((state) => state.buyerOffers);
  const { user } = useSelector((state) => state.user);
  const property = offer.sale.property

  const handleNewOffer = (e) => {
    e.stopPropagation()
    ga4Event('finalize_offer', { 'user_id': user.id, 'unique_hash': offer.sale.unique_hash })
  }

  return (
    <div data-testid='Property'>
      {offer.id &&
        <FadeIn>
          <PropertySummary
            property={property}
            stateLabel={offer && <OfferStateLabel offer={offer} />}
            cityLabel={<Label color='bg-ih-indigo'>{property.city}</Label>}
            avatar={offer.sale.expert?.user?.profile_pic_url}
          >
            <div className='w-1/2 md:w-max mx-auto md:mx-0 mb-3 md:mb-0'>
              <PriceBox title='Prix de vente' price={offer.sale.price.toLocaleString()} />
            </div>
            <div className='flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mb-3 md:mb-0'>
              {offer.review_status &&
                <Button label='Voir la fiche du bien' small />
              }
              {!offer.review_status &&
                <a href={generateLink(RailsRoutes.online_offers_path({ property_slug: property.slug }))} target='_blank' onClick={handleNewOffer}>
                  <Button label='Finaliser mon offre' small />
                </a>
              }
              {property.advert.link_virtual_visit &&
                <a href={property.advert.link_virtual_visit} target='_blank' onClick={(e) => e.stopPropagation()}>
                  <Button label='Voir la visite 3D' type='tertiary' small />
                </a>
              }
            </div>
          </PropertySummary>

          <CtaBanner offer={offer} />

          <Separator />

          <SponsorshipBanner />
        </FadeIn>
      }
    </div>
  )
}

export default Property;
