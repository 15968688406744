import React, { useEffect, useState } from 'react';

import BUYER_ALERTS from '../../../../../api/ressources/SellerApp/buyerAlerts';

import AvatarsCounter from '../../../../../../shared/components/AvatarsCounter';

const PotentialBuyers = ({ sale, valuation }) => {
  const [buyerAlerts, setBuyerAlerts] = useState(null)

  useEffect(() => {
    if (!sale || !valuation) return;

    const getBuyerAlerts = async ({ saleUniqueHash, price }) => {
      const response = await BUYER_ALERTS.getBuyerAlerts(saleUniqueHash, price)
      setBuyerAlerts(response.data)
    }

    getBuyerAlerts({ saleUniqueHash: sale.unique_hash, price: valuation.sale_price})
  }, [sale, valuation]);

  if (!buyerAlerts || buyerAlerts.count <= 5) return null;

  return (
    <div className='flex flex-col md:flex-row gap-4 md:gap-6 items-center justify-center border border-ih-purple-30 p-5 rounded-3xl text-center' data-testid='PotentialBuyers'>
      <AvatarsCounter count={buyerAlerts.count} />

      <div className='flex flex-col items-start'>
        <p className='h4 text-ih-indigo'>{buyerAlerts.count} acquéreurs potentiels</p>

        <p className='text-ih-indigo-50 text-sm'>sont intéressés par votre bien</p>
      </div>
    </div>
  );
}

export default PotentialBuyers;
