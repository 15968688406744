import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { longDateTime } from '../../../../../../../../../shared/utils/formattedDates';

import { TextInput, PhoneNumberInput, TextAreaInput, Button } from 'hosman-material';

const ContactForm = ({ selectedSlot, handleSubmit, isSubmitting, formErrors }) => {
  const [userState, setUserState] = useState({
    contact_name: '',
    last_name: '',
    contact_phone_country_code: 'FR',
    contact_phone_number: '',
    access_details: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setUserState(() => {
      return {...userState, [name]: value};
    });
  };

  const submitForm = () => {
    let body = userState
    delete body.last_name
    handleSubmit(body)
  }

  return (
    <div>
      <p className='h4 my-7'>Point de contact pour le photographe</p>

      <div className='md:grid grid-cols-4 gap-5'>
        <div className='col-span-3'>
          <div className='flex flex-col md:flex-row gap-3'>
            <TextInput label='Prénom' name='contact_name' value={userState.contact_name} onChange={handleInputChange} errors={formErrors} />
            <TextInput label='Nom' name='last_name' value={userState.last_name} onChange={handleInputChange} />
            <PhoneNumberInput
              name='contact_phone_number'
              onChangePhone={(val) => handleInputChange({ target: { name: 'contact_phone_number', value: val }})}
              onChangeCountryCode={(val) => handleInputChange({ target: { name: 'contact_phone_country_code', value: val }})}
              phoneValue={userState.contact_phone_number}
              countryCodeValue={userState.contact_phone_country_code}
              errors={formErrors}
            />
          </div>
          <div className='mt-5'>
            <TextAreaInput rows={3} label="Informations d'accès pour le photographe (digicodes, bâtiment, code...)" name='access_details' value={userState.access_details} onChange={handleInputChange} errors={formErrors} />
          </div>
        </div>
        <div className='col-span-1 flex flex-col justify-between mt-5'>
          <div/>
          <div className='flex flex-col gap-3 text-center'>
            <p className='font-semibold h-6'>{selectedSlot && longDateTime({ date: selectedSlot, capitalized: true })}</p>
            <Button label='Réserver ce créneau' onClick={() => submitForm()} isLoading={isSubmitting} disabled={!selectedSlot} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactForm;

// PropTypes
ContactForm.propTypes = {
  selectedSlot: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  formErrors: PropTypes.object
};
