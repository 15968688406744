import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ARTICLES from '../../api/ressources/App/articles';

export const getArticles = createAsyncThunk(
  '/articles',
  async (params) => {
    const response = await ARTICLES.getArticles(params)
    return response.data
  }
);

const initialState = {
  articles: []
};

export const articlesSlice = createSlice({
  name: 'articles',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getArticles.fulfilled, (state, { payload }) => {
      state.articles = payload.results;
    })
  },
})

export default articlesSlice.reducer;
