import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import CONTACTS from '../../api/ressources/BuyerApp/contacts';

export const getContacts = createAsyncThunk(
  '/contacts',
  async (saleId) => {
    const response = await CONTACTS.getContacts(saleId)
    return response.data
  }
);

const initialState = {
  contacts: [],
  requestState: ''
};

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getContacts.pending, (state) => {
      state.contacts = initialState.contacts
      state.requestState= 'pending'
    })
    builder.addCase(getContacts.fulfilled, (state, { payload }) => {
      state.contacts = payload
      state.requestState= 'done'
    })
  },
})

export default contactsSlice.reducer;
