import React from 'react';
import { sun, partyingFace } from '../../../../../../../../../shared/assets/emojis';

import { Emoji } from 'hosman-material';

const ExplainCard = () => {
  return (
    <div className='md:col-span-3 h-min rounded-3xl shadow-ih-1'>
      <div className='p-5'>
        <div className='flex align-items gap-4 mb-3'>
          <Emoji emojiSrc={sun} width='w-5' />
          <p>Les créneaux proposés sont ceux où la lumière du jour est suffisante</p>
        </div>
        <div className='flex align-items gap-4'>
          <Emoji emojiSrc={partyingFace} width='w-4' />
          <p>Plus tôt le reportage photo est pris, plus vite votre vente débute</p>
        </div>
      </div>
    </div>
  )
}

export default ExplainCard;
