import client from '../client';

const ESTIMATIONS = {
  createEstimation(params) {
    if (isNonEmptyString(params.email)) return {};

    return client.post('/estimations', { estimation: params }, { withCredentials: true })
  },

  getBaseEstimationData(uniqueHash) {
    return client.get(`/sales/${uniqueHash}/estimation_results`)
  },
}

function isNonEmptyString(value) {
  return typeof value !== 'string' || value.length <= 1;
}

export default ESTIMATIONS;
