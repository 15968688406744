import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { S_CircleCheck } from 'hosman-material';
import { Button, Avatar } from 'hosman-material';
import { ga4Event } from '../../../../../../../shared/utils/ga4Event';

const BottomBanner = ({ open, expert }) => {
  return open ? createPortal(
    <div className={`fixed bottom-0 w-full bg-white shadow-ih-1 p-2`}>
      <div className='flex flex-col-reverse md:flex-row gap-3 md:gap-6 justify-center items-center md:ml-80'>
        <div className='flex items-center gap-1'>
          <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-blue' />
          <p className='text-ih-blue text-sm'>100% gratuit et sans engagement</p>
        </div>

        <div data-testid='RegionalExpert' className='hidden md:block'>
          <Avatar border imageSrc={expert.user.profile_pic_url} userName={`${expert.user.first_name} ${expert.user.last_name}`} bgColor='bg-ih-blue' size='w-12' />
        </div>

        <Link to={expert.phone_calendar_link} target='_blank' onClick={() => { ga4Event('click_phone_appointment') }}>
          <Button label={
            <div className='flex items-center gap-2'>
              <div className='md:hidden'>
                <Avatar border imageSrc={expert.user.profile_pic_url} userName={`${expert.user.first_name} ${expert.user.last_name}`} bgColor='bg-ih-blue' size='w-10' />
              </div>
              <p>{`Être rappelé(e) par ${expert.user.first_name}`}</p>
            </div>
          }/>
        </Link>
      </div>
    </div>,
    document.body
  ) : null;
}

export default BottomBanner;
