import React from 'react';
import PropTypes from 'prop-types';
import { Card, Avatar } from 'hosman-material';

export const MarketingCard = ({ number = 0, title, subtitle, logos = [], leadInitials = [] }) => {
  return(
    <Card>
      <div className='flex flex-col items-center justify-center gap-2 mx-auto p-4 max-w-[280px] h-full' data-testid='MarketingCard'>
        <p className='h2 text-center text-ih-blue' data-testid='MarketingNumber'>{number}</p>

        <div className='font-semibold text-center text-sm' data-testid='MarketingTitle'>{title}</div>

        <div className='text-ih-indigo-40 text-center text-sm' data-testid='MarketingSubtitle'>{subtitle}</div>

        <div className='flex justify-center items-center flex-wrap gap-2'>
          {
            <div className='flex flex-wrap justify-around items-center gap-1'>
              {logos.map(({ src, altText }, i) => {
                return (
                  <img key={i} src={src} alt={altText} className='w-5/12' data-testid='MarketingLogos'/>
                )
              })}
            </div>
          }

          {
            leadInitials.map((leadInitial, index) => (
              <Avatar
                key={`${index}${leadInitial.replace(/\s+/g, '')}`}
                userName={leadInitial}
              />
            ))
          }

          {(leadInitials.length > 0 && number > 3) &&
            <div className='relative w-max'>
              <div className='w-12 h-12 rounded-full bg-ih-indigo'>
                <p className='h-12 flex justify-center items-center text-white h4' data-testid='MarketingInitials'>
                  +{ number - 3 }
                </p>
              </div>
            </div>
          }
        </div>
      </div>
    </Card>
  )
};

MarketingCard.propTypes = {
  title:  PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  logos: PropTypes.array,
  leadInitials: PropTypes.array
}
