import React from 'react';
import { useSelector } from 'react-redux';

import Partners from './components/Partners';
import PortalsContacts from './components/PortalsContacts';
import PotentialBuyers from './components/PotentialBuyers';

const Marketing = () => {
  const { sale } = useSelector((state) => state.sellerSales);

  if (sale.state !== 'awaiting_offer' || sale.on_break) return null;

  return(
    <div className='flex justify-center flex-wrap gap-7 mt-10'>
      <PotentialBuyers />

      <Partners />

      <PortalsContacts />
    </div>
  )
};

export default Marketing
