import client from '../../client';

const VALUATIONS = {
  getValuation(valuationId) {
    return client.get(`/valuations/${valuationId}`);
  },

  updateValuation(valuationId, params) {
    return client.put(`/valuations/${valuationId}`, { valuation: params });
  },
}

export default VALUATIONS;
