import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const NewEvent = ({ referenceDate, newEventModal, setModalOpen, setNewEventData }) => {
  const [newEvent, setNewEvent] = useState({ displayed: false })

  useEffect(() => {
    if (!newEventModal) setNewEvent({ displayed: false });
  }, [newEventModal])

  const handleMouseDown = (e) => {
    const dayRef = e.target.closest('.dayRef');
    const day = parseInt(dayRef.dataset.day);
    const timeStart = parseInt(e.target.dataset.time);

    // Not allow adding events in the past
    const dateTime = new Date(referenceDate)
    dateTime.setDate(dateTime.getDate() + day)
    if (dateTime.getTime() < new Date().getTime()) return;

    setNewEvent({ displayed: true, day: day, time: timeStart, span: 4 })
    dayRef.addEventListener('mousemove', updateSpan);

    function updateSpan(event) {
      if (!event) return;

      const timeEnd = parseInt(event.target.closest('.timeRef').dataset.time);
      const dur = (timeEnd - timeStart + 2);
      const span = dur > 4 ? dur : 4;
      setNewEvent(prev => ({
        ...prev,
        span: span
      }));
    }
  }

  const handleMouseUp = () => {
    if (!newEvent.displayed) return;

    setModalOpen(true);
    setNewEventData(setSelectedEvent());
  }

  function setSelectedEvent() {
    const selectedDate = new Date(referenceDate);
    selectedDate.setDate(selectedDate.getDate() + (newEvent.day === 0 ? 6 : newEvent.day - 1))
    const startHourRaw = 7 + newEvent.time / 4
    const duration = (newEvent.span-1) * 15
    const endHourRaw = startHourRaw + duration / 60

    const startDateTime = new Date(selectedDate)
    const endDateTime = new Date(selectedDate)
    startDateTime.setHours(parseInt(startHourRaw), (startHourRaw-parseInt(startHourRaw))*60, 0, 0)
    endDateTime.setHours(parseInt(endHourRaw), (endHourRaw-parseInt(endHourRaw))*60, 0, 0)

    return { start: startDateTime, end: endDateTime }
  }

  return (
    <>
      <ol
        className={`col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 ${newEvent.displayed ? 'cursor-row-resize' : ''}`}
        style={{ gridTemplateRows: '1.75rem repeat(57, minmax(0, 1fr))' }}
        onMouseUp={() => handleMouseUp()}
      >
        {Array.from(new Array(7), (x,i) => i).map((i) => {
          return (
            <li key={i+1} className={`col-start-${i+1} relative mt-px flex mx-1 dayRef`}
                style={{ gridRow: '2 / span 56' }}
                data-day={i+1}
            >
              <ol
                className='col-start-1 col-end-2 row-start-1 grid w-full'
                style={{ gridTemplateRows: 'repeat(56, minmax(0, 0.75rem))' }}
              >
                {Array.from(new Array(56), (x,i) => i).map((i) => {
                  return (
                    <li key={i} className='h-full w-full z-10 timeRef'
                        onMouseDown={(e) => handleMouseDown(e)}
                        data-time={i}
                    >
                    </li>
                  )
                })}
              </ol>
            </li>
          )
        })}

        {newEvent.displayed &&
          <li className={`col-start-${newEvent.day} relative min-h-[1rem] mt-5 mx-1 bg-ih-red-5 border border-ih-red-60 opacity-80 rounded`} style={{ gridRow: `${newEvent.time+1} / span ${newEvent.span}` }}>
            <div className='flex gap-1 text-[10px] font-semibold text-ih-indigo px-3 pt-1'>
              <p>{setSelectedEvent().start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
              <p>-</p>
              <p>{setSelectedEvent().end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
            </div>
          </li>
        }
      </ol>

      {newEventModal}
    </>
  )
}

// PropTypes
NewEvent.propTypes = {
  referenceDate: PropTypes.object.isRequired,
  newEventModal: PropTypes.node,
  setModalOpen: PropTypes.func.isRequired,
  setNewEventData: PropTypes.func.isRequired
};
