import { useEffect, useState } from 'react';

function usePreventRefresh() {
  const [isRefreshAttempted, setIsRefreshAttempted] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'F5' || (event.ctrlKey && event.key === 'r')) {
        event.preventDefault();
        setIsRefreshAttempted(true);
      }
    };

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ''; // Nécessaire pour certains navigateurs
      setIsRefreshAttempted(true);
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean les eventListeners quand le composant est démonté
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return isRefreshAttempted;
}

export default usePreventRefresh;
