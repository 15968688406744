import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { S_CaretRight, S_CaretLeft } from 'hosman-material';
import * as dayjs from 'dayjs';

export const DaysHeader = ({ containerNavRef, referenceDate, setReferenceDate }) => {
  const [dates, setDates] = useState([])

  useEffect(() => {
    getWeekDays(referenceDate);
  }, [referenceDate])

  const dayAsString = (dayIndex) => {
    const weekDays = new Array(7);
    weekDays[0] = 'Dim';
    weekDays[1] = 'Lun';
    weekDays[2] = 'Mar';
    weekDays[3] = 'Mer';
    weekDays[4] = 'Jeu';
    weekDays[5] = 'Ven';
    weekDays[6] = 'Sam';

    return weekDays[dayIndex];
  }

  const newReferenceDay = (newDate) => {
    referenceDate.setDate(newDate)

    return new Date(referenceDate);
  }

  const getWeekDays = (startDate) => {
    const rawDates = [];

    for (let i = 0; i <= 6; i++) {
      const currentDate = new Date(startDate);
      currentDate.setDate(startDate.getDate() + i);

      rawDates.push({ today: dayjs().isSame(currentDate, 'day'), dayName: dayAsString(currentDate.getDay()), dayNumber: currentDate.getDate() });
    }

    setDates(rawDates)
  }

  const displayDate = (weekDay) => {
    return (
      <div className={`${weekDay.today ? 'bg-ih-blue rounded-2xl text-white' : ''} px-3 py-2 w-fit mx-auto text-ih-indigo`}>
        <div className='flex flex-col gap-1'>
          <p className='h3 leading-none'>
            {String(weekDay.dayNumber).padStart(2, '0')}
          </p>
          <p className={`${weekDay.today ? 'text-white' : 'text-ih-purple-50'} text-sm leading-none`}>
            {weekDay.dayName}
          </p>
        </div>
      </div>
    )
  }

  return (
    <div
      ref={containerNavRef}
      className='sticky top-0 z-50 flex-none bg-ih-blue-5'
    >
      <div className='-mr-px hidden grid-cols-7 divide-x divide-ih-blue-20 border-r border-gray-100 text-sm leading-6 sm:grid text-center'>
        <div className='col-end-1 w-20 flex items-center'>
          <div className='flex justify-evenly w-full h-full'>
            <button
              onClick={() => setReferenceDate(newReferenceDay(referenceDate.getDate() - 7))}
              type='button'
              className='bg-ih-purple-20 hover:bg-ih-purple-30 w-full'
            >
              <span className='sr-only'>Semaine précédente</span>
              <FontAwesomeIcon icon={S_CaretLeft} size='2xl' className='text-ih-blue hover:text-ih-blue' />
            </button>
            <div className='bg-ih-blue-40 w-[3px] h-full'></div>
            <button
              onClick={() => setReferenceDate(newReferenceDay(referenceDate.getDate() + 7))}
              type='button'
              className='bg-ih-purple-20 hover:bg-ih-purple-30 w-full'
            >
              <span className='sr-only'>Semaine suivante</span>
              <FontAwesomeIcon icon={S_CaretRight} size='2xl' className='text-ih-blue hover:text-ih-blue' />
            </button>
          </div>
        </div>

        {dates.map((weekDay) => {
          return (
            <div key={weekDay.dayNumber} className={`${weekDay.today ? 'bg-ih-blue-10' : 'bg-ih-blue-5'} py-2`}>
              {displayDate(weekDay)}
            </div>
          )
        })}
      </div>
    </div>
  )
}

DaysHeader.propTypes = {
  containerNavRef: PropTypes.object,
  referenceDate: PropTypes.object,
  setReferenceDate: PropTypes.func
};
