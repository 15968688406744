import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { D_House } from 'hosman-material';

const Loader = ({ resultData, setIsReady }) => {
  const [value, setValue] = useState(0)
  const loaderStarted = useRef(false);

  useEffect(() => {
    if (loaderStarted.current) return;

    loaderStarted.current = true;
    Array.from({ length: 90 }).map((item, index) => {
      setTimeout(() => {
        setValue((prev => prev + 1))
      }, index * 50);
    })
  }, []);

  useEffect(() => {
    if (!resultData) return;

    setValue(90)
    Array.from({ length: 10 }).map((item, index) => {
      setTimeout(() => {
        setValue((prev => prev + 1))
      }, index * 90);
    })

    setTimeout(() => {
      setIsReady(true)
    }, 500);
  }, [resultData]);

  return (
    <div className='w-max mx-auto'>
      <div
        className={`shadow-[inset_0px_0px_0px_6px_#CBD6FC] [--size:6rem] [--thickness:calc(var(--size)_/_16)] [--value:0] [--color:#5076F6]
                    radial-progress radial-progress-colored relative inline-grid h-24 w-24 place-content-center rounded-full bg-transparent align-middle box-content
                    after:content-[''] after:rounded-full after:absolute after:bg-ih-blue after:transition-all
                    before:content-[''] before:rounded-full before:absolute before:top-0 before:right-0 before:left-0 before:bottom-0 thickness
                    after:inset-[calc(50%-var(--thickness)/2)]`}
        style={{ '--value': `${value}` }}
        data-testid='circle'
      >
        <FontAwesomeIcon icon={D_House} size='2xl' className='text-ih-blue' />
      </div>
    </div>
  )
}

export default Loader;
