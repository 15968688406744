import React from 'react';
import PropTypes from 'prop-types';
import { displayTime } from '../../../../../../../shared/utils/formattedDates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { S_Xmark, S_PenToSquare } from 'hosman-material';
const classNames = require('classnames');

export const Event = ({ event, handleEventUpdate, handleEventDelete }) => {
  const eventDone = new Date(event.start).getTime() < new Date().getTime();

  let cardClasses = classNames(
    'md:absolute md:inset-1 flex flex-col overflow-y-auto rounded-xl md:rounded-lg px-2 py-2 md:py-0 text-xs leading-6 border',
    { 'bg-ih-blue hover:bg-ih-blue-80': eventDone && event.typeSlug == 'visit' && !event.canceled },
    { 'bg-white hover:bg-ih-blue-5 border-ih-blue': !eventDone && event.typeSlug == 'visit' && !event.canceled },
    { 'bg-ih-red-5 border-ih-red-60': event.typeSlug == 'indispo' || event.canceled }
  );

  let textClasses = classNames(
    'md:text-[10px] font-semibold',
    { 'text-white': eventDone && event.typeSlug == 'visit' && !event.canceled },
    { 'text-ih-blue': !eventDone && event.typeSlug == 'visit' && !event.canceled },
    { 'text-ih-red': event.typeSlug == 'indispo' || event.canceled },
    { 'line-through': event.canceled }
  );

  let timeClasses = classNames(
    'text-[10px] flex gap-1 items-center',
    { 'text-ih-purple-30': eventDone && event.typeSlug == 'visit' && !event.canceled },
    { 'text-ih-indigo': !eventDone || event.typeSlug !== 'visit' || event.canceled }
  );

  const handleDelete = (e) => {
    e.stopPropagation();
    handleEventDelete(event);
  }

  return (
    <div className={cardClasses}>
      <div className={`flex flex-col ${event.typeSlug == 'indispo' ? 'md:flex-col' : 'md:flex-row'} justify-between`}>
        <div className={`${event.typeSlug == 'indispo' ? 'flex items-center justify-between' : ''}`}>
          <p className={textClasses}>{event.name}</p>
          {event.typeSlug == 'indispo' && <FontAwesomeIcon icon={S_Xmark} className={`${textClasses} md:text-base cursor-pointer`} onClick={(e) => handleDelete(e)} />}
        </div>
        <p className={timeClasses}>
          <time dateTime={event.start}>{`${displayTime(event.start)} - ${displayTime(event.end)}`}</time>
          {event.typeSlug == 'indispo' && <FontAwesomeIcon icon={S_PenToSquare} className='hidden md:block md:text-[10px] hover:text-ih-blue cursor-pointer' />}
        </p>
      </div>
    </div>
  )
}

Event.propTypes = {
  event: PropTypes.object.isRequired
};
