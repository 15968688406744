import React from 'react';
import { useSelector } from 'react-redux';

import Price from './components/Price'
import Duration from './components/Duration'
import VisitorsInterest from './components/VisitorsInterest';

const SaleKeyNumbers = () => {
  const { sale } = useSelector((state) => state.sellerSales);

  if (sale.state !== 'awaiting_offer') return null;

  return(
    <div className='mt-10 flex flex-col gap-10'>
      <div className='text-center text-2xl font-bold'>
        Quelques <span className='text-ih-blue'>chiffres clés</span> sur votre vente
      </div>

      <div className='flex justify-center flex-wrap gap-7'>
        <Price />

        <Duration />

        <VisitorsInterest />
      </div>
    </div>
  )
};

export default SaleKeyNumbers;
