import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import BUYER_DOCUMENTS from '../../api/ressources/BuyerApp/documents';

export const getDocuments = createAsyncThunk(
  '/documents',
  async (saleId) => {
    const response = await BUYER_DOCUMENTS.getDocuments(saleId)
    return response.data
  }
);

const initialState = {
  documents: [],
  requestState: ''
};

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDocuments.pending, (state) => {
      state.documents = initialState.documents
      state.requestState = 'pending'
    })
    builder.addCase(getDocuments.fulfilled, (state, { payload }) => {
      state.documents = payload
      state.requestState = 'done'
    })
  },
})

export const { } = documentsSlice.actions;

export default documentsSlice.reducer;
