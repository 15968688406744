import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { shortDateAndYear } from '../../../../../../../shared/utils/formattedDates';

import { EventsList, Emoji } from 'hosman-material';

import { houseWithGarden } from '../../../../../../../shared/assets/emojis';

const SaleSteps = ({ sale, acceptedOffer, openPhotoMeetingModal }) => {
  const memoizedevents = useMemo(() => setEvents(sale), [sale]);

  function setEvents(sale) {
    if (sale.id) {
      const mandateSigned = sale.timeline.last_awaiting_publication_at && shortDateAndYear(sale.timeline.last_awaiting_publication_at);
      const photoMeeting = sale.timeline.last_photo_meeting_at && shortDateAndYear(sale.timeline.last_photo_meeting_at);
      const publication = sale.timeline.last_awaiting_offer_at && shortDateAndYear(sale.timeline.last_awaiting_offer_at);
      const acceptedOfferDate = acceptedOffer && sale.timeline.last_awaiting_sale_agreement_at && shortDateAndYear(sale.timeline.last_awaiting_sale_agreement_at);
      const saleAgreement = sale.timeline.last_awaiting_notarial_deed_at && shortDateAndYear(sale.timeline.last_awaiting_notarial_deed_at);
      const notarialDeed = sale.timeline.last_sold_at && shortDateAndYear(sale.timeline.last_sold_at);

      const steps = [
        {
          id: 1,
          label: 'Mandat signé',
          date: mandateSigned || '',
          done: mandateSigned !== null
        },
        {
          id: 2,
          label: 'Reportage photo',
          date: photoMeeting || '',
          customNoDate: sale.lightshop ? <a className='link text-ih-blue underline' onClick={openPhotoMeetingModal}>A planifier</a> : sale.timeline.last_awaiting_offer_at != null ? 'Effectué' : 'A venir',
          done: sale.lightshop ? photoMeeting !== null && new Date(sale.timeline.last_photo_meeting_at).getTime() < new Date().getTime() : sale.timeline.last_awaiting_offer_at != null
        },
        {
          id: 3,
          label: 'Publication annonce',
          date: publication || '',
          customNoDate: 'A venir',
          done: publication !== null
        },
        {
          id: 4,
          label: 'Offre acceptée',
          date: acceptedOfferDate || '',
          customNoDate: 'A venir',
          done: acceptedOfferDate !== null
        },
        {
          id: 5,
          label: 'Promesse de vente',
          date: acceptedOffer?.sale_agreement_date ? shortDateAndYear(acceptedOffer.sale_agreement_date) : '',
          customNoDate: sale.state === 'awaiting_sale_agreement' ? null : 'A venir',
          done: saleAgreement !== null && new Date(sale.timeline.last_awaiting_notarial_deed_at).getTime() < new Date().getTime()
        },
        {
          id: 6,
          label: 'Acte authentique',
          date: acceptedOffer?.notarial_deed_date ? shortDateAndYear(acceptedOffer.notarial_deed_date) : '',
          customNoDate: sale.state === 'awaiting_notarial_deed' ? null : 'A venir',
          done: notarialDeed !== null && new Date(sale.timeline.last_sold_at).getTime() < new Date().getTime()
        }
      ];

      if (notarialDeed !== null && saleAgreement == null) {
        steps.splice(4, 1)
      }

      return steps;
    }
  }

  return (
    <div className='flex flex-col gap-10'>
      <div className='flex flex-col gap-2 items-center justify-center'>
        <Emoji emojiSrc={houseWithGarden} width='w-11' />
        <p className='h2 text-center'>Suivez l'avancée de votre vente <span className='text-ih-blue'>en temps réel</span></p>
      </div>

      <div className='p-6 md:border border-ih-neutral-100 md:shadow-ih-1 rounded-3xl'>
        <EventsList events={memoizedevents} />
      </div>
    </div>
  )
}

export default SaleSteps;

// PropTypes
SaleSteps.propTypes = {
  sale: PropTypes.object.isRequired,
  acceptedOffer: PropTypes.object,
  openPhotoMeetingModal: PropTypes.func
};
