import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AvatarMenu from '../Sidebar/components/AvatarMenu';
import { Box } from 'hosman-material';

const PageTitle = ({ icon, title, children }) => {
  return (
    <div className='lg:sticky lg:top-0 ih-bg -mx-5 z-30 mb-12'>
      <div className='bg-hosman-gradient-title lg:bg-none flex justify-between px-5 h-20 z-20'>
        <div className='flex items-center space-x-3'>
          {icon &&
            <Box>
              <FontAwesomeIcon icon={icon} size='lg' className='text-ih-blue' />
            </Box>
          }
          <p className='h3 text-ih-indigo'>{title}</p>
        </div>
        <div className='hidden lg:flex items-center space-x-3'>
          {children}
          <AvatarMenu />
        </div>

      </div>
      {/* Separator on desktop */}
      <hr className='w-full hidden lg:block'></hr>
    </div>
  );
};

export default PageTitle;

// PropTypes
PageTitle.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  children: PropTypes.node
};
