import React, { useState, useEffect  } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, useParams, Link } from 'react-router-dom';
import CustomRoutes from '../../../../shared/utils/customRoutes';

import ProgressBar from './components/ProgressBar';
import OfferSummary from './views/OfferSummary';
import OfferSign from './views/OfferSign';

import { getOffer } from '../../../reducers/SellerApp/offersSlice';

import { LoadingLayer, HosmanLogo, Label } from 'hosman-material';

const AcceptOffer = () => {
  const { offer, requestState } = useSelector((state) => state.sellerOffers);
  const [stepInfos, setStepInfos] = useState({});
  const dispatch = useDispatch();
  let { offerId } = useParams();

  useEffect(() => {
    dispatch(getOffer(offerId));
  }, [offerId]);

  return (
    <div>
      <div className='fixed top-0 bg-white w-full z-10'>
        <div className='flex justify-around mb-4 pt-6'>
          <div className='flex items-center'>
            <Link to='/'><HosmanLogo /></Link>

            {offer.review_status === 'ongoing' &&
              <Label color='bg-ih-indigo'>
                Signature de l'offre
              </Label>
            }
          </div>
        </div>

        <ProgressBar stepInfos={stepInfos} />
      </div>

      <div className='mt-36'>
        <LoadingLayer isReady={requestState == 'done'}>
          <CustomRoutes>
            <Route index element={<OfferSummary setStepInfos={setStepInfos} offer={offer} />} />
            <Route path='/signature' element={<OfferSign setStepInfos={setStepInfos} offer={offer} />} />
          </CustomRoutes>
        </LoadingLayer>
      </div>
    </div>
  )
}

export default AcceptOffer;
