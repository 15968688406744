import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ga4Event } from '../../../../../shared/utils/ga4Event';

import { previewContent } from './previewContent';
import { S_CircleCheck } from 'hosman-material';
import { FadeIn, Avatar, Button } from 'hosman-material';

const Preview = ({ page, sale }) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const content = previewContent[page];

  return (
    <div className='text-ih-indigo' data-testid='Preview'>
      <FadeIn>
        <div className='flex flex-col gap-3'>
          <p className='h1 text-center'>{content.title}</p>

          <div className='flex flex-col md:flex-row gap-3 justify-center'>
            {content.checks.map((check) => {
              return (
                <div key={check} className='flex items-center gap-1 mx-auto md:mx-0'>
                  <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-green' size='lg' />
                  {check}
                </div>
              )
            })}
          </div>
        </div>

        <div className={`flex items-center gap-8 py-10 transition-all ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}>
          <img src={content.imageSrc} className='w-1/2 sm:w-2/3 hidden md:block px-10' onLoad={() => setImageLoaded(true)} />
          <div className='flex flex-col gap-3 p-5 bg-ih-purple-10 border border-ih-purple-50 rounded-3xl text-center'>
            {sale.expert && <div className='mx-auto'><Avatar border imageSrc={sale.expert.user.profile_pic_url} /></div>}

            <p>Ces fonctionnalités seront disponibles après la signature de votre mandat de vente</p>

            {sale.expert?.phone_calendar_link &&
              <Link to={sale.expert.phone_calendar_link} target='_blank' onClick={() => ga4Event('click_phone_appointment')}>
                <Button label={`Affiner mon estimation avec ${sale.expert.user.first_name}`} />
              </Link>
            }
          </div>
        </div>
      </FadeIn>
    </div>
  );
}

// PropTypes
Preview.propTypes = {
  page: PropTypes.string.isRequired,
  sale: PropTypes.object.isRequired
}

export default Preview;
