import React from 'react';
import { useSelector } from 'react-redux';

import SponsorshipCard from '../../../../components/Marketing/SponsorshipCard'
import { FadeIn } from 'hosman-material';

const Sponsorship = () => {
  return (
    <div data-testid='Sponsorship'>
      <FadeIn>
        <div className='md:mx-8 md:mt-24'>
          <SponsorshipCard />
        </div>
      </FadeIn>
    </div>
  )
};

export default Sponsorship;
