import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { D_CalendarStar } from 'hosman-material';

import { FadeIn, Modal, Box, Button } from 'hosman-material';

export const DemoModal = ({ isOpen, closeModal }) => {
  return (
    <FadeIn>
      <Modal isOpen={isOpen} closeModal={closeModal}>
        <div className='border-[3px] border-ih-blue rounded-3xl'>
          <div className='flex flex-col gap-4 text-center pt-8 pb-4 text-ih-indigo'>
            <div className='flex flex-col gap-2 mx-auto'>
              <div className='mx-auto'>
                <Box>
                  <FontAwesomeIcon icon={D_CalendarStar} size='lg' className='text-ih-blue' />
                </Box>
              </div>
              <p className='h3'>Votre bien n'est <span className='text-ih-blue'>pas disponible à la visite ?</span></p>
              <p>Ajoutez un créneau d'indisponibilité en un clic grâce à <span className='text-ih-blue'>un cliquer-glisser.</span></p>
            </div>

            <div className='w-4/5 mx-auto rounded-3xl border border-ih-indigo-20'>
              <video autoPlay loop muted className='h-full rounded-3xl'>
                <source src='https://res.cloudinary.com/vesta-home/video/upload/c_scale,w_900/v1684225995/assets/Napoleon/Videos/demo_planning_web.mp4' type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>

            <div className='m-max'>
              <Button label='Gérer mes disponibilités' onClick={closeModal} />
            </div>
          </div>
        </div>
      </Modal>
    </FadeIn>
  );
};
