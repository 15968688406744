import React, { useMemo } from "react";
import { useSelector } from 'react-redux';

import { EventsList, FadeIn } from 'hosman-material';

const NextSteps = () => {
  const { offer } = useSelector((state) => state.buyerOffers);

  const memoizedevents = useMemo(() => setEvents(offer), [offer]);

  function setEvents(offer) {
    if (offer.id) {
      const pendingAt = offer.timeline.review_ongoing_at && new Date(offer.timeline.review_ongoing_at).toLocaleDateString();
      const acceptedAt = offer.timeline.review_accepted_at && new Date(offer.timeline.review_accepted_at).toLocaleDateString();
      const saleAgreementAt = offer.sale_agreement_date && new Date(offer.sale_agreement_date).toLocaleDateString();
      const notarialDeedAt = offer.notarial_deed_date && new Date(offer.notarial_deed_date).toLocaleDateString();

      return [
        {
          id: 1,
          label: 'Offre remise',
          date: pendingAt || '',
          done: pendingAt !== null
        },
        {
          id: 2,
          label: 'Offre acceptée',
          date: acceptedAt || '',
          done: acceptedAt !== null
        },
        {
          id: 3,
          label: 'Promesse de vente',
          date: saleAgreementAt || '',
          done: saleAgreementAt !== null && new Date(offer.sale_agreement_date).getTime() < new Date().getTime()
        },
        {
          id: 4,
          label: 'Acte authentique',
          date: notarialDeedAt || '',
          done: notarialDeedAt !== null && new Date(offer.notarial_deed_date).getTime() < new Date().getTime()
        }
      ];
    }
  }

  return (
    <FadeIn>
      <div className='mt-5 md:mt-20'>
        <p className='hidden md:block h3 mb-10 text-center'>Les étapes de votre acquisition</p>
        <div className='p-6 md:border border-ih-neutral-100 md:shadow-ih-1 rounded-2xl w-max mx-auto'>
          <EventsList events={memoizedevents} />
        </div>
      </div>
    </FadeIn>
  )
}

export default NextSteps;
