import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { validateStep } from '../../../steps/Validators';
import { toast } from 'react-hot-toast';

import { HosmanLogo } from 'hosman-material';

import { updateStep } from '../../../../../../../reducers/_Pages/Public/estimationFunnelSlice'

const StepsNavigation = () => {
  const { currentStep, allSteps, estimationData } = useSelector((state) => state.estimationFunnel);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleNavigate = (step) => {
    if (isPreviousStepValid(step.number)) {
      dispatch(updateStep({ stepNumber: step.number }))
    } else {
      toast(
        <p>Veuillez remplir les étapes précédentes d'abord</p>
      , { type: 'info', duration: 3000, id: 'stepFunnel' })
    }
  }

  const isPreviousStepValid = (stepNumber) => {
    const contract = validateStep({ stepNumber: stepNumber - 1, data: estimationData });

    return contract.valid
  }

  return (
    <div className='flex gap-4 w-min mx-auto' data-testid='StepsNavigation'>
      {allSteps.filter(step => step.displayNavigation).map((step, index) => {
        return (
          <div className={`flex gap-2 items-center transition-all ${currentStep.name === step.name ? 'w-full' : 'w-12'} ${isPreviousStepValid(step.number) ? 'cursor-pointer' : ''}`} key={step.path} onClick={() => handleNavigate(step)}>
            <div>
              <div className={`w-10 h-10 flex items-center justify-center rounded-full transition-all duration-700 ${isPreviousStepValid(step.number) ? 'bg-ih-blue text-white' : 'bg-ih-purple-10 text-ih-blue-50 cursor-not-allowed'}`}>0{step.number}</div>
            </div>

            <div className='flex items-center'>
              <p className={`text-ih-indigo-50 whitespace-nowrap transition-opacity duration-300 overflow-hidden ${currentStep.name === step.name ? 'w-full opacity-100' : 'w-[0%] opacity-0'}`}>{step.name}</p>
              {(currentStep.name !== step.name && index !== 4) && <p className='text-ih-purple-40'>-</p>}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default StepsNavigation;
