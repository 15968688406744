import { D_House, D_FolderOpen, D_CalendarStar, D_FileLines, D_FileCircleCheck, D_Book, D_HandsHoldingDollar, D_MessageHeart } from 'hosman-material';

export const navigation = ({ sale, estimationFolder, visitsToCome, visitsWithReport, offers }) => {
  let items = [];

  if (!sale.id) return items;

  const BASE_PATH = `ventes/${sale.id}`

  items = [
    { name: 'Mon estimation', path: `${BASE_PATH}/mon-estimation`, icon: D_HandsHoldingDollar },
    { name: 'Dossier de vente', path: `${BASE_PATH}/dossier-de-vente`, icon: D_FolderOpen },
    { name: 'Mon planning', path: `${BASE_PATH}/planning`, icon: D_CalendarStar, count: visitsToCome.length },
    { name: 'Comptes-rendus', path: `${BASE_PATH}/comptes-rendus`, icon: D_FileLines, count: visitsWithReport.length },
    { name: 'Mes offres', path: `${BASE_PATH}/offres`, icon: D_FileCircleCheck, count: offers.length },
    { name: 'Conseils immo', path: `${BASE_PATH}/conseils-immo`, icon: D_Book },
    { name: 'Parrainez un proche', subtitle: "Gagnez jusqu'à 600€ ! ", path: `${BASE_PATH}/parrainage`, icon: D_MessageHeart }
  ]

  sale.mandateSigned && items.splice(0, 0, { name: 'Ma vente', path: BASE_PATH, icon: D_House })

  return items;
}
