import client from '../../client';

const USER = {
  getUser() {
    return client.get('/me/user')
  },

  updateUser(params) {
    return client.put('/me/user', { user: params })
  },
}

export default USER;
