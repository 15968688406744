import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const classNames = require('classnames');

import { S_CircleCheck } from 'hosman-material';

import VALUATIONS from '../../../../../../../api/ressources/SellerApp/valuations';

const EstimationReview = ({ valuation, setValuation }) => {
  const estimationReviews = [
    { slug: 'too_low', text: 'Trop basse' },
    { slug: 'accurate', text: 'Juste' },
    { slug: 'too_high', text: 'Trop haute' },
  ]

  const updateOpinionValuation = async ({ valuationId, params }) => {
    let response = await VALUATIONS.updateValuation(valuationId, params)
    setValuation(response.data)
  }

  const optionClasses = (slug) => {
    return classNames(
      `text-ih-indigo bg-white text-sm font-semibold p-3 rounded-2xl cursor-pointer border transition ease-in-out duration-300 ${valuation.opinion ? 'opacity-0 z-0' : 'opacity-100 z-10'}`,
      { 'hover:border-ih-blue-80 hover:bg-ih-blue-10': valuation.opinion !== slug }
    );
  }

  return(
    <div className='flex flex-col justify-center items-center gap-2' data-testid='EstimationReview'>
      <p className='text-center text-ih-indigo font-semibold text-sm'>Que pensez-vous de cette estimation ?</p>

      <div className='flex justify-center items-center flex-wrap gap-2 relative'>
        {estimationReviews.map(({ slug, text }) => {
          return (
            <div key={slug} className={optionClasses(slug)} onClick={() => updateOpinionValuation({ valuationId: valuation.id, params: { opinion: slug }})} data-testid='opinionOption'>
              {text}
            </div>
          )
        })}

        <div className={`flex justify-center items-center gap-1 whitespace-nowrap text-ih-indigo text-sm p-3 transition duration-300 absolute ease-in-out ${valuation.opinion ? 'opacity-1 z-10' : 'opacity-0 z-0'}`} data-testid='opinionSentMsg'>
          <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-green' />
          <div>Votre avis a bien été pris en compte</div>
        </div>
      </div>
    </div>
  )
};

export default EstimationReview;
