import React from 'react';
import { useSelector } from 'react-redux';

import SponsorshipCard from '../../../../../components/Marketing/SponsorshipCard';
import { LoadingLayer, FadeIn } from 'hosman-material';

const Sponsorship = () => {
  const { sale, requestState } = useSelector((state) => state.sellerSales);

  return (
    <div data-testid='Sponsorship'>
      <LoadingLayer isReady={requestState === 'done'}>
        <FadeIn>
          <div className='md:mx-8 md:mt-24'>
            <SponsorshipCard avatarSrc={sale.expert?.user?.profile_pic_url} />
          </div>
        </FadeIn>
      </LoadingLayer>
    </div>
  )
};

export default Sponsorship;
