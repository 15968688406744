import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Navigate,
  useNavigate,
  useLocation
} from 'react-router-dom';
import CustomRoutes from '../../../shared/utils/customRoutes';
import { D_House, D_Book, D_Handshake, D_MessageHeart } from 'hosman-material';

import Home from '../BuyerApp/pages/Home';
import PartnerServices from '../BuyerApp/pages/PartnerServices';
import Ressources from '../BuyerApp/pages/Ressources';
import Sponsorship from '../BuyerApp/pages/Sponsorship';
import Sidebar from '../../components/Sidebar/Sidebar';

const BASE_PATH = '/ea';
const navigation = [
  { name: 'Accueil', path: `${BASE_PATH}/offres`, icon: D_House },
  { name: 'Ressources', path: `${BASE_PATH}/ressources`, icon: D_Book },
  { name: 'Services partenaires', path: `${BASE_PATH}/services-partenaires`, icon: D_Handshake },
  { name: 'Parrainez un proche', subtitle: "Gagnez jusqu'à 600€ !", path: `${BASE_PATH}/parrainage`, icon: D_MessageHeart }
]

const BuyerApp = ({ clientSwitch, accountCard }) => {
  let navigate = useNavigate();
  let location = useLocation();

  const matchedPath = navigation.find((nav, index) => index !== 0 && location.pathname.includes(nav.path));
  const [selectedNav, setSelectedNav] = useState(matchedPath ? matchedPath : navigation[0])

  const handleNavChange = (navigation) => {
    setSelectedNav(navigation);
    navigate(navigation.path);
  }

  return (
    <div data-testid='buyer-app'>
      <Sidebar
        navigation={navigation}
        selectedNav={selectedNav}
        handleSelect={handleNavChange}
        clientSwitch={clientSwitch}
        accountCard={accountCard}
      >
        <CustomRoutes>
          <Route index element={<Navigate to='offres' />} />
          <Route path='/offres/*' element={<Home />} />
          <Route path='/ressources' element={<Ressources />} />
          <Route path='/services-partenaires' element={<PartnerServices />} />
          <Route path='/parrainage' element={<Sponsorship />} />
        </CustomRoutes>
      </Sidebar>
    </div>
  );
}

// PropTypes
BuyerApp.propTypes = {
  clientSwitch: PropTypes.func,
  accountCard: PropTypes.func
}

export default BuyerApp;
