import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';

import OffersIndex from './views/OffersIndex'
import OfferShow from './views/OfferShow'
import CustomRoutes from '../../../../../shared/utils/customRoutes';

import { getIndexOffers } from '../../../../reducers/BuyerApp/offersSlice';

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIndexOffers());
  }, []);

  return (
    <div data-testid='Home'>
      <CustomRoutes>
        <Route index element={<OffersIndex />} />
        <Route path=':offerId/*' element={<OfferShow />} />
      </CustomRoutes>
    </div>
  );
}

export default Home
