import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import OFFERS from '../../api/ressources/SellerApp/offers';

export const getOffers = createAsyncThunk(
  '/offers',
  async (params) => {
    const response = await OFFERS.getOffers(params)
    return response.data
  }
);

export const getOffer = createAsyncThunk(
  '/offer',
  async (offerId) => {
    const response = await OFFERS.getOffer(offerId)
    return response.data
  }
);

const initialState = {
  offers: [],
  ongoingOffers: [],
  offer: {},
  acceptedOffer: null,
  requestState: ''
};

export const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    resetOffers(state) {
      state.offers = initialState.offers;
      state.acceptedOffer = initialState.acceptedOffer;
    },
    resetOffer(state) {
      state.offer = initialState.offer;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getOffers.pending, (state) => {
      state.offers = initialState.offers;
      state.requestState = 'pending'
    })
    builder.addCase(getOffers.fulfilled, (state, { payload }) => {
      state.offers = payload.results;
      state.ongoingOffers = payload.results.filter((offer) => offer.review_status === 'ongoing');
      state.acceptedOffer = getAcceptedOffer(payload.results);
      state.requestState = 'done'
    })
    builder.addCase(getOffer.pending, (state) => {
      state.offer = initialState.offer;
      state.requestState = 'pending'
    })
    builder.addCase(getOffer.fulfilled, (state, { payload }) => {
      state.offer = payload;
      state.requestState = 'done'
    })
  },
})

const getAcceptedOffer = (results) => {
  return results.filter(offer => offer.review_status === 'accepted')[0] || null
};

export const { resetOffers, resetOffer } = offersSlice.actions;

export default offersSlice.reducer;
