import React from 'react';
import { useSelector } from 'react-redux';

import { leboncoinLogo,
         jinkaLogo,
         selogerLogo,
         bellesDemeures,
         luxResidence,
         proprietesFigaro } from '../../../../../../../../../../assets/logos';
import { MarketingCard } from './shared/MarketingCard';

const PortalsContacts = () => {
  const { sale, sale: { property: { property_leads_count } } } = useSelector((state) => state.sellerSales);

  const handleContactPortailsSubtitle = () => {
    if (sale.on_ubiflow) return 'Publié sur + de 40 sites immobiliers'
    else return "Votre bien n'est pas encore publié sur les sites immobiliers"
  }

  const handleContactPortailsLogos = () => {
    if (!sale.on_ubiflow) return []

    if (sale.premium) return [
      { src: bellesDemeures, altText: 'bellesDemeures'},
      { src: luxResidence, altText: 'luxResidence' },
      { src: proprietesFigaro, altText: 'proprietesFigaro' }
    ]

    return [
      { src: leboncoinLogo, altText: 'leboncoin' },
      { src: jinkaLogo, altText: 'jinka' },
      { src: selogerLogo, altText: 'seloger' }
    ]
  }

  return(
    <MarketingCard
      number={property_leads_count}
      title={`Demande${property_leads_count > 1 ? 's' : ''} de contacts sur les sites immobiliers`}
      subtitle={handleContactPortailsSubtitle()}
      logos={handleContactPortailsLogos()}
    />
  )
};

export default PortalsContacts
