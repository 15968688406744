import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { S_CircleCheck, D_CalendarStar } from 'hosman-material';
import { partyingFace } from '../../../../../../../../shared/assets/emojis';
import { longDate, displayTime } from '../../../../../../../../shared/utils/formattedDates';
import * as dayjs from 'dayjs';

import { Modal, Button, Emoji } from 'hosman-material';

export const RemoveEventModal = ({ event, setRemoveEventSlot, handleRemoveEvent }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = () => {
    setIsSubmitting(true)
    handleRemoveEvent(event);
  }

  const slotDuration = () => {
    return dayjs(event.end).diff(dayjs(event.start), 'hour')
  }

  return (
    <Modal isOpen closeModal={() => setRemoveEventSlot(false)}>
      <div className='flex flex-col gap-6 p-6 w-full mx-auto mt-6'>
        <p className='h3 text-center flex flex-col'>
          Êtes-vous sûr de vouloir
          <span className='text-ih-green'> ouvrir ce créneau de visites ?</span>
        </p>
        <div className='flex flex-col gap-2 text-ih-indigo text-sm'>
          <div className='flex items-center gap-2'>
            <FontAwesomeIcon icon={D_CalendarStar} />
            <p className='font-semibold'>{longDate({ date: event.start, capitalized: true })} de {displayTime(event.start)} à {displayTime(event.end)}</p>
          </div>
          <div className='flex items-center gap-2'>
            <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-green' />
            <p>Cela rajoute {slotDuration()} heures de visites !</p>
            <Emoji emojiSrc={partyingFace} width='w-4' />
          </div>
        </div>

        <div className='flex flex-col md:flex-row gap-3'>
          <Button label='Annuler' type='tertiary' onClick={() => setRemoveEventSlot(false)} small />
          <Button label='Ouvrir ce créneaux à la visite' onClick={() => handleSubmit(event)} isLoading={isSubmitting} small />
        </div>
      </div>
    </Modal>
  );
};

// PropTypes
RemoveEventModal.propTypes = {
  event: PropTypes.object,
  setRemoveEventSlot: PropTypes.func,
  handleRemoveEvent: PropTypes.func
};
