import React, { useState, useEffect } from 'react';

import { FadeIn, DocumentsBox, Document } from 'hosman-material';
import { house, handShake, building, triangularRuler, folder } from '../../../shared/assets/emojis';

const SaleFolder = ({ documents }) => {
  const [groupedDocuments, setGroupedDocuments] = useState([]);

  useEffect(() => {
    if (documents.length === 0) return;

    let group = {}
    documents.map((document) => {
      let category = document.type.category_slug;
      group[category] = group[category] ?? [];
      group[category].push(document);
      setGroupedDocuments(group)
    });
  }, []);

  const displayTitle = (slug) => {
    switch (slug) {
      case 'property':
        return { title: 'Documents liés au bien', emoji: house }
      case 'condo':
        return { title: 'Documents de copropriété', emoji: building }
      case 'diagnostic':
        return { title: 'Diagnostics liés au bien', emoji: triangularRuler };
      case 'acquisition':
        return { title: "Documents liés à l'acquisition", emoji: handShake };
      default:
        return { title: 'Autres documents', emoji: folder }
    }
  }

  return (
    <div data-testid='SaleFolder'>
      <FadeIn>
        <div className='w-full mt-10'>
          {Object.entries(groupedDocuments).map(([category, documents]) => {
            return (
              <div key={category}>
                <DocumentsBox title={displayTitle(category).title} emoji={displayTitle(category).emoji}>
                  <div className='p-5 flex flex-wrap gap-8'>
                    {documents.map((document) => {
                      return (
                        <div key={document.id}>
                          <Document document={document} />
                        </div>
                      )
                    })}
                  </div>
                </DocumentsBox>
              </div>
            );
          })}
        </div>
      </FadeIn>
    </div>
  );
};

export default SaleFolder;
