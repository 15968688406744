import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Card } from 'hosman-material';
import { D_CircleExclamation } from 'hosman-material';

const NoValuationError = () => {
  return(
    <div className='flex flex-col justify-center items-center gap-2 border border-ih-purple-30 px-3 py-8 md:px-8 rounded-3xl md:w-3/4 mx-auto text-center' data-testid='NoValuationError'>
      <FontAwesomeIcon icon={D_CircleExclamation} size='xl' className='text-ih-red' />
      <p className='h2 text-ih-indigo'>Un problème technique vient de se produire...</p>
      <p className='typo-subtitle text-ih-indigo-60'>Malheureusement votre estimation n'est pas disponible. Nous vous invitons à contacter l'expert en charge de votre secteur pour échanger sur l'estimation de votre bien.</p>
    </div>
  )
};

export default NoValuationError;
