import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import VISITS from '../../api/ressources/SellerApp/visits';

export const getVisits = createAsyncThunk(
  '/visits',
  async (params) => {
    const response = await VISITS.getVisits(params)
    return response.data
  }
);

const initialState = {
  visits: [],
  visitsToCome: [],
  visitsWithReport: [],
  requestState: '',
};

export const visitsSlice = createSlice({
  name: 'visits',
  initialState,
  reducers: {
    resetVisits(state) {
      state = initialState
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getVisits.pending, (state) => {
      state.visits = initialState.visits;
      state.requestState = 'pending'
    })
    builder.addCase(getVisits.fulfilled, (state, { payload }) => {
      state.visits = payload.results;
      state.visitsWithReport = filterVisitsWithReport(payload.results);
      state.visitsToCome = filterVisitsToCome(payload.results);
      state.requestState = 'done'
    })
  },
})

const filterVisitsWithReport = (results) => {
  return results.filter((visit) => visit.visit_report && visit.visit_report.notif_seller_sent == true).reverse();
};

const filterVisitsToCome = (results) => {
  return results.filter((visit) => !visit.canceled && new Date().getTime() < new Date(visit.start_time).getTime());
};

export const { resetVisits } = visitsSlice.actions;

export default visitsSlice.reducer;
