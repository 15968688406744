import React from 'react';
import PropTypes from 'prop-types';

import OfferStateLabel from '../../../../../../components/Offer/StateLabel.js';
import Characteristics from '../../../../../../../shared/components/Property/Characteristics';
import { Card, Label, Avatar, Carousel } from 'hosman-material';

const OfferSummary = ({ offer }) => {
  const property = offer.sale.property

  return (
    <div className='group' data-testid='OfferSummary'>
      <Card>
        <Carousel images={property.property_pictures.map(picture => picture.photo_url )} />

        <div className='absolute z-10 top-4 left-4'>
          <OfferStateLabel offer={offer} />
        </div>

        <div className='relative p-6'>
          {offer.sale.expert &&
            <div className='absolute -top-6 z-10'>
              <Avatar border imageSrc={offer.sale.expert?.user?.profile_pic_url} />
            </div>
          }

          <div className='absolute -top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
            <Label color='bg-ih-indigo'>{property.city}</Label>
          </div>

          <p className={'text-sm font-semibold truncate my-4 text-ih-neutral'}>
            {property.street_number} {property.street_name} {property.zip_code} {property.city}
          </p>

          <Characteristics property={property} short />
        </div>

        <div className='h4 text-center text-ih-blue-5 bg-ih-blue p-3 rounded-b-2xl transition-all w-full group-hover:bg-ih-blue-80'>
          {offer.review_status === 'accepted' ? 'Accéder à mon bien' : 'Accéder à mon offre'}
        </div>
      </Card>
    </div>
  )
}

export default OfferSummary;

// PropTypes
OfferSummary.propTypes = {
  offer: PropTypes.object.isRequired
};
