export const trustpilotLogo = 'https://res.cloudinary.com/vesta-home/image/upload/v1643136755/assets/images/revamp%202022/logos/trustpilot.webp'

export const figaroImmoLogo = 'https://res.cloudinary.com/vesta-home/image/upload/v1687435556/assets/images/logos/Capture_d_e%CC%81cran_2023-04-03_a%CC%80_17.18_4.webp'
export const leboncoinLogo = 'https://res.cloudinary.com/vesta-home/image/upload/v1687435556/assets/images/logos/Capture_d_e%CC%81cran_2023-04-03_a%CC%80_17.18_2.webp'
export const logicImmoLogo = 'https://res.cloudinary.com/vesta-home/image/upload/v1687435556/assets/images/logos/Capture_d_e%CC%81cran_2023-04-03_a%CC%80_17.18_3.webp'
export const jinkaLogo = 'https://res.cloudinary.com/vesta-home/image/upload/v1687435556/assets/images/logos/Logo-Jinka_1.webp'
export const selogerLogo = 'https://res.cloudinary.com/vesta-home/image/upload/v1687435556/assets/images/logos/Logo_Seloger_2017_1.webp'
export const bieniciLogo = 'https://res.cloudinary.com/vesta-home/image/upload/v1687435556/assets/images/logos/2581_346_logo_bienici_1.webp'
export const jeReveDuneMaisonLogo = 'https://res.cloudinary.com/vesta-home/image/upload/v1687853139/assets/images/logos/jerevedunemaison.webp'
export const monChasseurImmoLogo = 'https://res.cloudinary.com/vesta-home/image/upload/v1687853139/assets/images/logos/monchasseurimmo.webp'
export const perleRareLogo = 'https://res.cloudinary.com/vesta-home/image/upload/v1687853139/assets/images/logos/perlerare.webp'
export const homeselectLogo = 'https://res.cloudinary.com/vesta-home/image/upload/v1687853139/assets/images/logos/homeselect.webp'
export const beanstockLogo = 'https://res.cloudinary.com/vesta-home/image/upload/v1687853139/assets/images/logos/beanstock.webp'
export const masteosLogo = 'https://res.cloudinary.com/vesta-home/image/upload/v1687853138/assets/images/logos/masteos.webp'
export const bellesDemeures = 'https://res.cloudinary.com/vesta-home/image/upload/v1695720404/assets/images/logos/belles_demeures.webp'
export const luxResidence = 'https://res.cloudinary.com/vesta-home/image/upload/v1695720404/assets/images/logos/lux_residence.webp'
export const proprietesFigaro = 'https://res.cloudinary.com/vesta-home/image/upload/v1695720405/assets/images/logos/proprietes_figaro.webp'
