import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { LoadingLayer, FadeIn, BulletPoint } from 'hosman-material';

import Preview from '../../components/Preview';
import { Calendar } from './components/desktop/Calendar';
import { MobileEventsFeed } from './components/mobile/MobileEventsFeed';
import { NewEventModal } from './components/modals/NewEventModal';
import { VisitModal } from './components/modals/VisitModal';
import { DemoModal } from './components/modals/DemoModal';
import { RemoveEventModal } from './components/modals/RemoveEventModal';
import { SufficientAbailabilityState } from './components/SufficientAbailabilityState';
import { ButtonInfo } from './components/desktop/ButtonInfo';

import { getUnavailabilities, updateUnavailabilities } from '../../../../../reducers/SellerApp/unavailabilitiesSlice'

import { ga4Event } from '../../../../../../shared/utils/ga4Event';

const Planning = () => {
  const [referenceDate, setReferenceDate] = useState()
  const { visits, requestState } = useSelector((state) => state.sellerVisits);
  const { unavailabilities, unavailabilitiesRequestState } = useSelector((state) => state.sellerUnavailabilities);
  const { sale } = useSelector((state) => state.sellerSales);
  const { user } = useSelector((state) => state.user);
  const [events, setEvents] = useState([]);
  const [modalVisitId, setModalVisitId] = useState();
  const [newEventModalOpen, setNewEventModalOpen] = useState(false)
  const [newEventData, setNewEventData] = useState({})
  const [removeEventSlot, setRemoveEventSlot] = useState(null)
  const [demoModal, setDemoModal] = useState(false)
  const propertyId = sale.property.id
  const dispatch = useDispatch();

  if (!sale.mandateSigned) return <Preview page='planning' sale={sale} />

  useEffect(() => {
    const visitEvents = visits.map((visit) => {
      return {
        id: visit.id,
        typeSlug: 'visit',
        canceled: visit.canceled,
        name: `${visit.user.first_name} ${visit.user.last_name?.[0]}.`,
        start: new Date(visit.start_time),
        end: new Date(visit.end_time),
        clickable: true
      }
    })

    const unavailabilityEvents = unavailabilities.map((unavailability) => {
      return {
        id: unavailability.id,
        typeSlug: 'indispo',
        name: 'Indisponible',
        start: new Date(unavailability.start),
        end: new Date(unavailability.end),
        clickable: true
      }
    })

    setEvents(visitEvents.concat(unavailabilityEvents))
  }, [visits, unavailabilities]);

  useEffect(() => {
    if (!sale.availiableForVisits) return;

    dispatch(getUnavailabilities(propertyId))
  }, [sale]);

  useEffect(() => {
    setNewEventModalOpen(false)
    setRemoveEventSlot(false)
  }, [unavailabilities]);

  const eventModal = () => {
    if (newEventModalOpen) {
      return (
        <NewEventModal
          handleNewOrUpdateEvent={handleNewOrUpdateEvent}
          newEventData={newEventData}
          setNewEventModalOpen={setNewEventModalOpen}
          existingEvents={events}
        />
      )
    }
  }

  const handleNewOrUpdateEvent = (slots) => {
    let newUnavailabilities = [...unavailabilities];

    if (slots[0].id) {
      const index = newUnavailabilities.findIndex(
        (el) => el.id === slots[0].id
      );
      newUnavailabilities[index] = slots[0];
    } else {
      slots.map(slot => {
        slot.id = uuidv4()
        newUnavailabilities.push(slot)
      })
    }

    dispatch(updateUnavailabilities({
      id: propertyId,
      slot: slots[0],
      slots: newUnavailabilities,
      type: 'edit'
    }));
    ga4Event('add_unavailability', { start_time: slots[0].start, end_time: slots[0].end, user_id: user.id, unique_hash: sale.unique_hash, recurring: slots[0].isWeeklyRecurring })
  }

  const handleRemoveEvent = (slot) => {
    const newUnavailabilities = [...unavailabilities];
    const index = newUnavailabilities.findIndex(
      (el) => el.id === slot.id
    );
    newUnavailabilities.splice(index, 1);
    dispatch(updateUnavailabilities({
      id: propertyId,
      slot: slot,
      slots: newUnavailabilities,
      type: 'delete'
    }));
    ga4Event('remove_unavailability', { start_time: new Date(slot.start).toISOString(), end_time: new Date(slot.end).toISOString(), user_id: user.id, unique_hash: sale.unique_hash })
  }

  const openUpdateModal = (slot) => {
    setNewEventData({ id: slot.id, start: slot.start, end: slot.end })
    setNewEventModalOpen(true)
  }

  return (
    <div data-testid='Planning'>
      <LoadingLayer isReady={requestState == 'done'}>
        <FadeIn>
          {/* Desktop */}
          <div className='hidden md:block'>
            <Calendar
              height='h-[calc(100vh-177px)]'
              events={events}
              header={sale.availiableForVisits &&
                <div className='w-full flex justify-between text-ih-indigo'>
                  <div className='flex gap-3 font-medium text-xs'>
                    <div className='flex items-center'>
                      <BulletPoint color='bg-ih-red' /><p>Créneaux indisponibles</p>
                    </div>
                    <div className='flex items-center'>
                      <BulletPoint color='bg-ih-green-30' /><p>Créneaux disponibles</p>
                    </div>
                  </div>


                  <SufficientAbailabilityState
                    referenceDate={referenceDate}
                    unavailabilities={unavailabilities}
                  />

                  {sale.availiableForVisits &&  <ButtonInfo handleClick={() => setDemoModal(true)} />}
                </div>
              }
              setParentReferenceDate={setReferenceDate}
              handleEventOpen={(e) => setModalVisitId(e.id)}
              handleEventUpdate={(e) => openUpdateModal(e)}
              handleEventDelete={(e) => setRemoveEventSlot(e)}
              setNewEventModalOpen={setNewEventModalOpen}
              newEventModal={eventModal()}
              setNewEventData={setNewEventData}
              editable={unavailabilitiesRequestState == 'done'}
            />
          </div>

          {/* Mobile */}
          <MobileEventsFeed
            events={events}
            handleEventOpen={(e) => setModalVisitId(e.id)}
            handleEventUpdate={(e) => openUpdateModal(e)}
            handleEventDelete={(e) => setRemoveEventSlot(e)}
          />

          {modalVisitId && <VisitModal visitId={modalVisitId} closeModal={() => setModalVisitId()} />}

          {removeEventSlot &&
            <RemoveEventModal
              event={removeEventSlot}
              setRemoveEventSlot={() => setRemoveEventSlot(null)}
              handleRemoveEvent={(e) => handleRemoveEvent(e)}
            />
          }

          {<DemoModal isOpen={demoModal} closeModal={() => setDemoModal(false)} />}
        </FadeIn>
      </LoadingLayer>
    </div>
  );
}

export default Planning;
