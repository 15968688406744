import React from 'react';
import useVisibility from '../../../../../../shared/utils/hooks/useVisibility';

import BottomBanner from './components/BottomBanner';
import LoadingBlock from './components/LoadingBlock';
import ExpertBlock from './components/ExpertBlock';

const ExpertMeeting = ({ sale }) => {
  const [expertContactIsVisible, expertContactRef] = useVisibility({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  return (
    <div className='h-fit flex flex-col justify-center items-center border border-ih-blue px-3 py-8 md:px-8 rounded-3xl bg-hosman-gradient-1 text-center relative' data-testid='ExpertMeeting'>
      <div className={`transition-opacity duration-700 ${!sale.expert ? 'opacity-100 relative' : 'opacity-0 absolute'}`} data-testid='LoadingBlockContainer'>
        <LoadingBlock />
      </div>

      <div className={`transition-opacity duration-700 ${sale.expert ? 'opacity-100 relative' : 'opacity-0 absolute pointer-events-none'}`} data-testid='ExpertBlockContainer'>
        <ExpertBlock expert={sale.expert} expertContactRef={expertContactRef} />
      </div>

      {sale.expert &&
        <BottomBanner open={!expertContactIsVisible} expert={sale.expert} />
      }
    </div>
  );
}

export default ExpertMeeting;
