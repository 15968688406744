import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { longDateTime } from '../../../../../../../shared/utils/formattedDates';

import OfferState from '../../../../../../components/Offer/OfferState';
import { Box, Label, Button } from 'hosman-material';

const OfferCard = ({ offer, offerIdInAcceptance }) => {
  return (
    <div data-testid='OfferCard'>
      <Link to={`/traitement-offre/${offer.id}`}>
        <div className='md:w-5/6 mx-auto flex flex-col md:flex-row gap-6 items-center mb-6 p-5 rounded-2xl bg-ih-purple-5 md:p-0 md:bg-white border border-ih-purple-20  md:border-0 shadow-ih-3 md:shadow-none'>
          <div className='flex flex-col md:flex-row gap-4 justify-between items-center md:p-8 w-full rounded-2xl bg-ih-purple-5 md:border md:border-ih-purple-20 md:hover:border-ih-purple-40 md:shadow-ih-3'>
            <div className='flex gap-4'>
              <Box>
                <p className='h4 text-ih-indigo'>{offer.owner.first_name[0].toUpperCase()}{offer.owner.last_name[0].toUpperCase()}</p>
              </Box>
              <div>
                <p className='h4 text-ih-neutral'>{offer.owner.first_name} {offer.owner.last_name}</p>
                <p className='text-ih-indigo-60 text-xs'>Remise le {longDateTime({ date: offer.timeline.review_ongoing_at })}</p>
              </div>
            </div>
            <div className='flex items-center'>
             {offerIdInAcceptance === offer.id &&
               <p className='text-ih-green'>Offre en cours d'acceptation...</p>
             }
             {offerIdInAcceptance !== offer.id &&
               <OfferState status={offer.review_status} />
             }
            </div>
            <Label color='bg-ih-indigo'><p className='h4'>{offer.amount.toLocaleString()} €</p></Label>
          </div>
          <div className='w-full md:w-auto md:min-w-max'>
            <Button label="Visualiser l'offre" small />
          </div>
        </div>
      </Link>
    </div>
  )
}

export default OfferCard;

// PropTypes
OfferCard.propTypes = {
  offer: PropTypes.object.isRequired,
  offerIdInAcceptance: PropTypes.number
};
