import React from 'react';
import PropTypes from 'prop-types';

export const FileInput = ({file, categorySlug = '', handleDrag, handleDrop, handleFileInput, lastDocumentUpload, isLoading}) => {
  return(
    <>
      {
        isLoading && (lastDocumentUpload === Number(file.id)) ?
        <div className='flex justify-center items-center'>
          <div className='w-6 h-6 border-4 border-t-4 border-gray-200 border-t-ih-blue rounded-full animate-spin'></div>
        </div>
        :
        <label
          key={file.slug}
          onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={(e) => handleDrop(e, file, categorySlug)}
          className={`flex justify-center w-full p-4 transition bg-white border border-ih-blue hover:bg-ih-purple-10 border-dashed rounded-2xl appearance-none cursor-pointer hover:border-solid focus:outline-none`}
          data-testid='fileInputLabel'
        >
          <span className='text-ih-blue font-semibold text-xs'>Cliquez-glissez le fichier ou ajoutez-le manuellement</span>
          <input type='file'
                 className='hidden'
                 onChange={(e) => handleFileInput(e, file, categorySlug)}
          />
        </label>
      }
    </>
  )
};

FileInput.propTypes = {
  file: PropTypes.object.isRequired,
  categorySlug: PropTypes.string,
  handleDrag: PropTypes.func,
  handleDrop: PropTypes.func,
  handleFileInput: PropTypes.func,
}
