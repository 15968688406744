import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { D_FileLines } from 'hosman-material';

import { Card } from 'hosman-material';

const NoOffersCard = () => {
  return (
    <div className='md:w-96 md:mt-20 mx-auto' data-testid='NoOffersCard'>
      <Card>
        <div className='flex flex-col gap-5 px-5 py-8 text-center'>
          <FontAwesomeIcon icon={D_FileLines} size='4x' className='text-ih-blue' />
          <p className='h3 text-ih-indigo'>Pas encore d'offre</p>
          <p className='text-ih-indigo'>Il n'y a pas encore d'offre remise.</p>
        </div>
      </Card>
    </div>
  );
};

export default NoOffersCard;
