export const scrollToSection = (targetElement) => {
  const isMobile = window.screen.width < 1024
  const targetOffset = targetElement.offsetTop + (isMobile ? 60 : -20);

  const scrollOffset = window.pageYOffset;

  const distance = targetOffset - scrollOffset;
  const duration = 200;
  let startTime = null;


  if (Math.abs(distance) < (isMobile ? 1 : 10)) {
    return;
  }

  function scrollAnimation(timestamp) {
    if (!startTime) startTime = timestamp;

    const progress = timestamp - startTime;
    const increment = distance * (progress / duration);

    const newPosition = scrollOffset + increment;

    if ((increment > 0 && newPosition >= targetOffset) || (increment < 0 && newPosition <= targetOffset)) {
      window.scrollTo(0, targetOffset);
      return;
    }

    window.scrollTo(0, newPosition);

    requestAnimationFrame(scrollAnimation);
  }
  requestAnimationFrame(scrollAnimation);
}

export const scrollToElement = (id, marginTop = '0') => {
  const element = document.getElementById(id)

  if (!element) return;

  element.style.marginTop = marginTop;
  element.scrollIntoView({ behavior: 'smooth' });

  setTimeout(function() {
    element.style.marginTop = '0';
  }, 500);
}
