import React, { useState, useEffect } from 'react';

import PropertyCard from '../../../../../../shared/components/Property/PropertyCard';
import { LoadingLayer, FadeIn, Slider, Label } from 'hosman-material';

import PROPERTIES from '../../../../../../shared/apiPublic/ressources/properties';

const SoldProperties = ({ sale }) => {
  const [properties, setProperties] = useState([])

  const fetchProperties = async () => {
    const states = sale.state === 'dead' ? ['awaiting_offer'] : ['sold']
    const response = await PROPERTIES.getProperties({sale_state_eq_any: states, relevant_id: sale.unique_hash })
    setProperties(response.data.results)
  }

  useEffect(() => {
    fetchProperties()
  }, []);

  const displayZipCode = (property) => {
    if (!['Paris', 'Marseille', 'Lyon'].includes(property.city)) return null;

    return property.zip_code
  }

  const setTitle = () => {
    if (sale.state === 'dead') {
      return <p className='h2'>Découvrez nos <span className='text-ih-blue'>derniers biens à vendre</span></p>
    }

    return <p className='h2'>Nos experts ont déjà vendu <span className='text-ih-blue'>près de chez vous</span></p>
  }

  if (properties.length < 1) return null;

  return (
    <div className='bg-hosman-gradient-1 py-20 px-6 md:px-20' data-testid='SoldProperties'>
      <LoadingLayer isReady={properties.length > 0}>
        <FadeIn>
          <Slider
            items={properties.map(property =>
              <div key={property.id} className='w-[270px]'>
                <PropertyCard
                  property={property}
                  stateLabel={
                    <div className='flex gap-2'>
                      {property.distance_from_relevant &&
                        <Label color='bg-ih-blue'>À {property.distance_from_relevant}m</Label>
                      }
                      {sale.state == 'sold' && <Label color='bg-ih-green'>Vendu</Label>}
                    </div>
                  }
                  centerLabel={<Label color='bg-ih-indigo'>{property.city} {displayZipCode(property)}</Label>}
                  price={property.sale.price}
                />
              </div>
            )}
            title={setTitle()}
          />
        </FadeIn>
      </LoadingLayer>
    </div>
  );
}

export default SoldProperties;
